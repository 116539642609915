import type { RootDispatch, RootState } from 'state/store';
import { fetchApi } from 'std/api/fetchApi';
import { endReduxFnError, endReduxFnOk, startReduxFn } from 'std/redux';
import { URL_ATUAL } from 'std/url';
import type { ConsultaCliente, ConsultaLayoutSistema } from './types';

export const effects = (dispatch: RootDispatch) => ({
    async getLayoutSistema(payload: { slug: string }, state: RootState): Promise<void> {
        dispatch.consultaPublica.setState({
            getLayoutSistema: startReduxFn(state.consultaPublica.getLayoutSistema.data),
        });

        try {
            const params = {
                url: URL_ATUAL,
                slug: payload.slug,
            };

            const data = await fetchApi({
                token: false,
                empresaIdpk: false,
                endpoint: 'EmpresaSlug/Consultar',
                method: 'get',
                params,
                schema: null,
            });

            dispatch.consultaPublica.setState({
                getLayoutSistema: endReduxFnOk(data as unknown as ConsultaLayoutSistema),
            });
        } catch (error) {
            dispatch.consultaPublica.setState({
                getLayoutSistema: endReduxFnError(error),
            });
        }
    },

    async getCobrancasPorCliente(
        payload: {
            clienteCpfCnpj: string | null;
            slug: string;
        },
        state: RootState,
    ): Promise<void> {
        dispatch.consultaPublica.setState({
            cobrancasPorCliente: startReduxFn(state.consultaPublica.cobrancasPorCliente.data),
        });

        try {
            const params = {
                url: URL_ATUAL,
                slug: payload.slug,
                documento: payload.clienteCpfCnpj,
            };

            const data = (await fetchApi({
                token: false,
                empresaIdpk: false,
                endpoint: 'EmpresaSlug/Cliente',
                method: 'get',
                params,
                schema: null,
            })) as {
                registros?: { cliente: ConsultaCliente }[];
            };

            const consultaCliente = data.registros ? data.registros[0]?.cliente : {};

            dispatch.consultaPublica.setState({
                cobrancasPorCliente: endReduxFnOk(consultaCliente as unknown as ConsultaCliente),
            });
        } catch (error) {
            dispatch.consultaPublica.setState({
                cobrancasPorCliente: endReduxFnError(error),
            });
        }
    },
});
