import axios from 'axios';
import { useLoginAdminStore } from 'features/login-admin/store';
import { useLoginStore } from 'features/login/store';
import { router } from 'router/Router';
import { API_URL } from 'std/api/url';
import { isAdmin } from './util';

/** @deprecated Instância do axios sem o bearer token */
export const axiosWithoutToken = axios.create({
    baseURL: API_URL,
    headers: { 'Content-Type': 'application/json' },
});

/** @deprecated Instância do axios com o bearer token */
export const axiosWithToken = axios.create({
    baseURL: API_URL,
    headers: { 'Content-Type': 'application/json' },
});

// o código abaixo serve para lidar quando o usuário está sem token ou com token expirado

axiosWithToken.interceptors.request.use((request) => {
    if (!request.headers) {
        return request;
    }

    let token: string | undefined;

    if (isAdmin()) {
        token = useLoginAdminStore.getState()?.loginAdmin?.token;
    } else {
        token = useLoginStore.getState()?.login?.token;
    }

    request.headers.Authorization = `Bearer ${token}`;
    return request;
});

axiosWithToken.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (error.response?.status !== 401) {
            return error;
        }

        if (error.response?.status === 401) {
            if (error.config.url === 'Login/RenovarToken') {
                return;
            }

            try {
                const response = await axiosWithToken.post('Login/RenovarToken');

                if (response.status === 401) {
                    throw new Error();
                }

                axiosWithToken.defaults.headers.common.Authorization = `Bearer ${response.data.token}`;
                error.hasRefreshedToken = true;
            } catch {
                if (isAdmin()) {
                    useLoginAdminStore.getState().clearLogin();
                    router.navigate({ to: '/admin/auth/login' });
                } else {
                    useLoginStore.getState().clearLogin();
                    router.navigate({ to: '/auth/login' });
                }

                return error;
            }
        }
    },
);
