import { router } from 'router/Router';
import type { RelatoriosPayload } from 'types/interfaces/RelatoriosPayload';

export function openPDF({
    type,
    access,
    link,
    relatorios,
    autoPrint = false,
}: {
    type: 'file' | 'link';
    access: 'publico' | 'autenticado';
    link?: string;
    relatorios?: RelatoriosPayload;
    autoPrint?: boolean;
}): void {
    window.sessionStorage.clear();
    window.sessionStorage.setItem('pdfType', type);
    window.sessionStorage.setItem('pdfAccess', access);

    if (type === 'file' && relatorios) {
        window.sessionStorage.setItem('relatorios', JSON.stringify(relatorios));
    }

    if (type === 'link' && link) {
        window.sessionStorage.setItem('pdfLink', link);
    }

    if (autoPrint) {
        window.sessionStorage.setItem('autoPrint', 'true');
    }

    if (access === 'publico') {
        router.navigate({
            to: '/p/impressao/$filetype',
            params: { filetype: 'pdf' },
        });
    } else {
        router.navigate({
            to: '/a/impressao/$filetype',
            params: { filetype: 'pdf' },
        });
    }
}
