import { type } from 'arktype';
import { TypeStringOrNullToString } from 'std/schema/defaultTypes';

enum RepresentanteTipo {
    Principal = 1,
    Secundario = 2,
}

export const RepresentanteSchema = type({
    rep_tipo: type.valueOf(RepresentanteTipo),
    rep_nome: TypeStringOrNullToString,
    rep_suporte_email: TypeStringOrNullToString,
    rep_suporte_telefone: TypeStringOrNullToString,
    rep_suporte_whatsapp: TypeStringOrNullToString,
    rep_suporte_link_acesso_remoto: TypeStringOrNullToString,
});
