import { HomeOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { useMemo } from 'react';
import { CustomLink } from './CustomLink';
import { useMenuItemsCadastro } from './useMenuItemsCadastro';
import { useMenuItemsCompra } from './useMenuItemsCompra';
import { useMenuItemsEstoque } from './useMenuItemsEstoque';
import { useMenuItemsFinanceiro } from './useMenuItemsFinanceiro';
import { useMenuItemsFiscal } from './useMenuItemsFiscal';
import { useMenuItemsNotificacoes } from './useMenuItemsNotificacoes';
import { useMenuItemsRelatorios } from './useMenuItemsRelatorios';
import { useMenuItemsVenda } from './useMenuItemsVenda';

const painelGerencial = {
    key: '/a/painel-gerencial',
    icon: <HomeOutlined />,
    label: <CustomLink to='/a/painel-gerencial'>Painel Gerencial</CustomLink>,
};

export function useMenuItems(): MenuProps['items'] {
    const itemsVenda = useMenuItemsVenda();
    const itemsFiscal = useMenuItemsFiscal();
    const itemsFinanceiro = useMenuItemsFinanceiro();
    const itemsCompra = useMenuItemsCompra();
    const itemsEstoque = useMenuItemsEstoque();
    const itemsCadastro = useMenuItemsCadastro();
    const itemsNotificacao = useMenuItemsNotificacoes();
    const itemsRelatorios = useMenuItemsRelatorios();

    const items = useMemo(
        () => [
            painelGerencial,
            itemsVenda,
            itemsFiscal,
            itemsFinanceiro,
            itemsCompra,
            itemsEstoque,
            itemsCadastro,
            itemsNotificacao,
            itemsRelatorios,
        ],
        [
            itemsVenda,
            itemsFiscal,
            itemsFinanceiro,
            itemsCompra,
            itemsEstoque,
            itemsCadastro,
            itemsNotificacao,
            itemsRelatorios,
        ],
    );

    return items;
}
