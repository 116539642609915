import { createFileRoute } from '@tanstack/react-router';
import { type } from 'arktype';
import { Compra } from 'pages/compras';

export const Route = createFileRoute('/a/compra/')({
    validateSearch: type({
        'tab?': 'string',
    }),
    component: Compra,
});
