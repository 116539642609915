import { type } from 'arktype';
import type { ProdutoRegras } from 'features/produto/util';

export const VendaItemSchema = type({
    'vei_idpk?': 'number|null',
    'vei_venda_idpk?': 'number|null',
    'vei_quantidade?': 'number|null',
    'vei_custo_medio?': 'number|null',
    'vei_valor_custo?': 'number|null',
    'vei_valor_unitario?': 'number|null',
    'vei_valor_total?': 'number|null',
    'vei_peso_bruto?': 'number|null',
    'vei_peso_liquido?': 'number|null',
    'vei_produto_idpk?': 'number|null',
    'vei_produto_nome?': 'string|null',
    'vei_produto_unidade?': 'string|null',
    'vei_produto_codigo_interno?': 'number|null',
    'vei_produto_sku?': 'string|null',
    'vei_produto_pacote_barra_idpk?': 'number|null',
    'vei_produto_cod_barra?': 'string|null',
    'vei_regra_fiscal_idpk?': 'number|null',
    'vei_desconto_valor?': 'number|null',
    'vei_desconto_tipo?': "'%' | '$' | '' | null",
    'vei_frete?': 'number|null',
    'vei_seguro?': 'number|null',
    'vei_outras_despesas?': 'number|null',
    'vei_volume_qtde?': 'number|null',
    'vei_detalhes?': 'string|null',
    'vei_icmsst_valor?': 'number|null',
    'vei_icmsst_fcp_valor?': 'number|null',
    'vei_ipi_valor?': 'number|null',
    'vei_ii_valor?': 'number|null',
    'vei_ultima_alteracao?': 'string|null',
});

export type VendaItem = typeof VendaItemSchema.infer;

export type VendaItemDetalhado = VendaItem & {
    produto: ProdutoRegras;
};
