import { Switch } from 'antd';
import { useLayoutStore } from 'features/layout/store';
import { memo } from 'react';
import { isSandbox } from 'std/url';

function BaseSwitchTheme() {
    const { theme, switchTheme } = useLayoutStore();

    if (!isSandbox()) {
        return null;
    }

    return (
        <div className='flex flex-col items-center'>
            <span className='text-sm italic text-red-500'>alpha</span>
            <Switch
                checked={theme === 'dark'}
                checkedChildren='🌒'
                unCheckedChildren='☀️'
                onChange={switchTheme}
            />
        </div>
    );
}

export const SwitchTheme = memo(BaseSwitchTheme);
