import { AppstoreOutlined } from '@ant-design/icons';
import type { ItemType, SubMenuType } from 'antd/es/menu/interface';
import { useUserPermission } from 'features/login/hooks/useUserPermission';
import { useLoginStore } from 'features/login/store';
import { TipoPermissao } from 'features/usuario/types/UsuarioTipoPermissao';
import { useMemo } from 'react';
import { CustomLink } from './CustomLink';

export function useMenuItemsEstoque(): ItemType {
    const isFintech = useLoginStore((s) => s.isFintech);
    const permissions = useUserPermission({ upp_estoque_estoque: TipoPermissao.Completo });

    const group: SubMenuType = useMemo(() => {
        return {
            key: 'categoria-estoque',
            label: 'Estoque',
            icon: <AppstoreOutlined />,
            children: [
                {
                    key: '/a/estoque/movimentacao',
                    label: <CustomLink to='/a/estoque/movimentacao'>Movimentações</CustomLink>,
                },
                {
                    key: '/a/estoque/conferencia',
                    label: <CustomLink to='/a/estoque/conferencia'>Conferência</CustomLink>,
                },
                {
                    key: '/a/estoque/inventario',
                    label: <CustomLink to='/a/estoque/inventario'>Inventário</CustomLink>,
                },
            ],
        };
    }, []);

    return !isFintech && permissions.upp_estoque_estoque ? group : null;
}
