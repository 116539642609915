import { ContainerOutlined } from '@ant-design/icons';
import type { SubMenuType } from 'antd/es/menu/interface';
import { useUserPermission } from 'features/login/hooks/useUserPermission';
import { useLoginStore } from 'features/login/store';
import { TipoPermissao } from 'features/usuario/types/UsuarioTipoPermissao';
import { useMemo } from 'react';
import { CustomLink } from './CustomLink';

export function useMenuItemsCadastro() {
    const isFintech = useLoginStore((s) => s.isFintech);

    const permissions = useUserPermission({
        upp_cadastro_cliente: TipoPermissao.Parcial,
        upp_cadastro_fornecedor: TipoPermissao.Parcial,
        upp_cadastro_transportadora: TipoPermissao.Completo,
        upp_cadastro_produto: TipoPermissao.Completo,
    });

    const group = useMemo(() => {
        const g: SubMenuType = {
            key: 'categoria-cadastro',
            icon: <ContainerOutlined />,
            label: 'Cadastros',
            children: [],
        };

        if (permissions.upp_cadastro_cliente) {
            g.children.push({
                key: '/a/cliente',
                label: <CustomLink to='/a/cliente'>Clientes</CustomLink>,
            });
        }

        if (permissions.upp_cadastro_fornecedor) {
            g.children.push({
                key: '/a/fornecedor',
                label: <CustomLink to='/a/fornecedor'>Fornecedores</CustomLink>,
            });
        }

        if (!isFintech && permissions.upp_cadastro_transportadora) {
            g.children.push({
                key: '/a/transportador',
                label: <CustomLink to='/a/transportador'>Transportadores</CustomLink>,
            });
        }

        if (permissions.upp_cadastro_produto) {
            g.children.push({
                key: '/a/produto',
                label: <CustomLink to='/a/produto'>Produtos</CustomLink>,
            });
        }

        return g;
    }, [isFintech, permissions]);

    return group.children.length === 0 ? null : group;
}
