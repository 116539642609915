import { type } from 'arktype';

export enum AcaoTipo {
    NfeEmitida = 'N',
    NfeGerada = 'NG',
    NfceEmitida = 'C',
    NfceGerada = 'CG',

    FinanceiroLancadoVenda = 'FV',
    FinanceiroPrevistoVenda = 'FP',
    FinanceiroLancadoNfe = 'FN',
    FinanceiroLancadoNfce = 'FC',

    EstoqueMovimentadoVenda = 'EV',
    EstoqueMovimentadoBonificacao = 'EB',
    EstoqueMovimentadoNfe = 'EN',
    EstoqueMovimentadoNfce = 'EC',

    LinkCompartilhado = 'LC',
    LinkVisualizado = 'LV',

    RomaneioExpedicao = 'EX',
    ManifestoCarga = 'MC',
    VendaExterna = 'VE',
    Contrato = 'CT',
}

export const AcaoSchema = type({
    vea_idpk: 'number',
    vea_empresa_idpk: 'number',
    vea_ultima_alteracao: 'string|null',
    vea_venda_idpk: 'number|null',
    vea_nota_fiscal_idpk: 'number|null',
    vea_nfce_idpk: 'number|null',
    vea_data: 'string|null',
    vea_tipo: type.valueOf(AcaoTipo),
    vea_tipo_descricao: 'string',
});

export type Acao = typeof AcaoSchema.infer;
