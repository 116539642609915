import { ArrowLeftOutlined } from '@ant-design/icons';
import { Link, useParams } from '@tanstack/react-router';
import { Spin, Steps, type UploadProps } from 'antd';
import { Helmet } from 'components/revisar/Helmet';
import { useLoginStore } from 'features/login/store';
import type { UploadRequestOption } from 'rc-upload/lib/interface';
import { useEffect, useState } from 'react';
import { type BuildUrlParams, buildUrl } from 'std/api/buildUrl';
import { comTokenPost } from 'std/api/comToken';
import type { ApiResponseStatus } from 'std/api/types';
import { fileToBase64 } from 'std/base64';
import { formatSecondsToMinutes } from 'std/datetime';
import { Case, Match } from 'std/util/match';
import { Endpoint } from 'types/enum/endpoint';
import { StepOne, StepThree, StepTwo, UploadedFileStatus } from './components';
import type { FormImportacaoDiValues } from './components/StepOne';
import type { UploadedFile } from './components/types';

const { Step } = Steps;

export const tipoImportacaoXml = ['nfe', 'di'] as const;
export type TipoImportacaoXml = (typeof tipoImportacaoXml)[number];

export function ImportacaoXML() {
    const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
    const [currentStep, setCurrentStep] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [loadingFiles, setLoadingFiles] = useState(false);
    const [isUploading, setIsUploading] = useState(false);

    const { tipo } = useParams({ from: '/a/importacao/xml/$tipo' }) as {
        tipo: TipoImportacaoXml;
    };

    const empresa_idpk = useLoginStore((s) => s.empresaIdpk);

    const removeFileFromList = (fileName: string) => {
        setUploadedFiles((prev) => prev.filter((file) => file.file.name !== fileName));
    };

    const postXmlFile = (
        uploadedFile: UploadedFile,
        formValue?: FormImportacaoDiValues,
    ): Promise<void> =>
        new Promise((resolve, reject) => {
            fileToBase64(uploadedFile.file).then((base64) => {
                if (typeof base64 !== 'string') {
                    reject(new Error(''));
                }

                const params: BuildUrlParams = {
                    empresa_idpk,
                    extensao: 'xml',
                };

                if (tipo === 'di') {
                    params.DI = 'S';
                    params.DI_numero = formValue?.DI_numero;
                    params.DI_data_desembaraco = formValue?.DI_data_desembaraco;
                }

                const url = buildUrl(Endpoint.ImportacaoNFEEmitida, params);

                const body = {
                    file_name: uploadedFile.file.name,
                    file_base64: base64,
                };

                comTokenPost(url, body)
                    .then((res) => {
                        if (!res.data.registros[0]) {
                            return;
                        }

                        const xmlStatusUpload = res.data.registros[0] as {
                            status: ApiResponseStatus;
                            mensagem: string;
                        };

                        setUploadedFiles((prev) =>
                            prev.map((prevUpFile) => {
                                if (prevUpFile.file.name === uploadedFile.file.name) {
                                    return {
                                        status: xmlStatusUpload.status,
                                        file: prevUpFile.file,
                                        msg: xmlStatusUpload.mensagem,
                                    };
                                }

                                if (prevUpFile.status === 'sucesso') {
                                    prevUpFile.msg = 'Pronto!';
                                }

                                return prevUpFile;
                            }),
                        );
                    })
                    .then(resolve);
            });
        });

    const proccessFiles = async (formValue?: FormImportacaoDiValues) => {
        setIsUploading(true);
        setCurrentStep(1);

        for (const file of uploadedFiles) {
            await postXmlFile(file, formValue);
        }

        setIsUploading(false);
    };

    const draggerProps: UploadProps = {
        name: 'file',
        listType: 'text',
        multiple: true,
        accept: 'text/xml, .xml, xml/*',
        showUploadList: false,
        disabled: isUploading,
        beforeUpload: async (file, fileList) => {
            // verificar se é o primeiro arquivo para não ficar chamando toda hora
            if (file?.name === fileList[0]?.name) {
                setLoadingFiles(true);

                // precisa de um tempo para atualizar o status e mostrar o loading
                await new Promise((resolve) => setTimeout(resolve, 500));
            }

            return new Promise((resolve) => {
                setSeconds(0);
                resolve();
            });
        },
        customRequest: (options: UploadRequestOption) => {
            const { onSuccess } = options;
            const file = options.file as File;

            setUploadedFiles((prev) => [
                ...prev,
                {
                    file,
                    status: 'aguardando',
                    msg: 'Aguardando processamento',
                },
            ]);

            // mudado o status para "done" para saber quando terminou de fazer o uploading
            if (onSuccess) {
                onSuccess({ status: 'done' });
            }
        },
        onChange: (info) => {
            const { fileList } = info;
            const lastFile = fileList[fileList.length - 1];

            // verifica se é o último arquivo já completo para tirar o loading
            if (lastFile?.status === 'done') {
                setLoadingFiles(false);
            }
        },
    };

    const finalizar = () => {
        setUploadedFiles([]);
        setSeconds(0);
        setIsUploading(false);
        history.go(-1);
        setCurrentStep(0);
    };

    useEffect(() => {
        if (isUploading) {
            const timer = setTimeout(() => {
                setSeconds(seconds + 1);
            }, 1000);
            return () => clearTimeout(timer);
        }

        return () => null;
    }, [isUploading, seconds]);

    useEffect(() => {
        const everyXmlWasPosted = !uploadedFiles.some((uf) => uf.status === 'aguardando');

        if (everyXmlWasPosted && uploadedFiles.length) {
            setCurrentStep(2);
        }
    }, [uploadedFiles]);

    return (
        <>
            <Helmet title='Importação de Notas Fiscais' />

            {/* cabeçalho */}
            <div>
                <Link to='/a/nota-fiscal'>
                    <ArrowLeftOutlined /> Voltar
                </Link>

                <div className='mb-3 text-base text-black uppercase'>
                    <strong>Importação de Notas Fiscais</strong>
                </div>
            </div>

            {/* estágios */}
            <div className='card-body'>
                <Steps current={currentStep}>
                    <Step title='Preparação' description='Informar XML' />
                    <Step
                        title='Processamento'
                        subTitle={`Progresso: ${formatSecondsToMinutes(seconds)} `}
                        description={
                            tipo === 'nfe' ? 'Lendo notas e importando' : 'Lendo e importando DI'
                        }
                    />
                    <Step title='Conclusão' description='Pronto! Processo executado' />
                </Steps>
                <br />
            </div>

            {/* instruções e ações */}
            <div className='card-body'>
                <Match>
                    <Case when={currentStep === 0}>
                        <Spin size='large' spinning={loadingFiles}>
                            <StepOne
                                draggerProps={draggerProps}
                                proccessFiles={proccessFiles}
                                disableProcessarBtn={!uploadedFiles.length}
                                isUploading={isUploading}
                                tipo={tipo}
                            />
                        </Spin>
                    </Case>
                    <Case when={currentStep === 1}>
                        <StepTwo />
                    </Case>
                    <Case when={currentStep === 2}>
                        <StepThree finalizar={finalizar} uploadedFiles={uploadedFiles} />
                    </Case>
                </Match>
            </div>

            {/* Status dos arquivos em processamento */}
            <UploadedFileStatus
                uploadedFiles={uploadedFiles}
                removeFilefromList={removeFileFromList}
                currentStep={currentStep}
            />
        </>
    );
}
