import { ShoppingCartOutlined } from '@ant-design/icons';
import type { ItemType, SubMenuType } from 'antd/es/menu/interface';
import { useUserPermission } from 'features/login/hooks/useUserPermission';
import { useLoginStore } from 'features/login/store';
import { TipoPermissao } from 'features/usuario/types/UsuarioTipoPermissao';
import { useMemo } from 'react';
import { CustomLink } from './CustomLink';

export function useMenuItemsVenda(): ItemType {
    const isFintech = useLoginStore((s) => s.isFintech);

    const permissions = useUserPermission({
        upp_venda_venda: TipoPermissao.Parcial,
        upp_venda_contrato: TipoPermissao.Parcial,
        upp_venda_venda_externa: TipoPermissao.Parcial,
        upp_fiscal_nfce: TipoPermissao.Parcial,
        upp_venda_romaneio: TipoPermissao.Parcial,
        upp_venda_comissao: TipoPermissao.Parcial,
    });

    const group = useMemo(() => {
        const g: SubMenuType = {
            key: 'categoria-venda',
            icon: <ShoppingCartOutlined />,
            label: 'Vendas',
            children: [],
        };

        if (permissions.upp_venda_venda) {
            g.children.push({
                key: '/a/venda/bonificação',
                label: (
                    <CustomLink to='/a/venda/$tipo' params={{ tipo: 'bonificação' }}>
                        Bonificações
                    </CustomLink>
                ),
            });

            g.children.push({
                key: '/a/venda/orçamento',
                label: (
                    <CustomLink to='/a/venda/$tipo' params={{ tipo: 'orçamento' }}>
                        Orçamentos
                    </CustomLink>
                ),
            });

            g.children.push({
                key: '/a/venda/pedido',
                label: (
                    <CustomLink to='/a/venda/$tipo' params={{ tipo: 'pedido' }}>
                        Pedidos
                    </CustomLink>
                ),
            });

            g.children.push({
                key: '/a/venda/venda',
                label: (
                    <CustomLink to='/a/venda/$tipo' params={{ tipo: 'venda' }}>
                        Vendas
                    </CustomLink>
                ),
            });
        }

        if (permissions.upp_venda_contrato) {
            g.children.push({
                key: '/a/contrato',
                label: <CustomLink to='/a/contrato'>Contratos</CustomLink>,
            });
        }

        if (!isFintech && permissions.upp_venda_venda_externa) {
            g.children.push({
                key: '/a/venda-externa',
                label: <CustomLink to='/a/venda-externa'>Vendas externas</CustomLink>,
            });
        }

        if (!isFintech && permissions.upp_fiscal_nfce) {
            g.children.push({
                key: '/a/pdv',
                label: <CustomLink to='/a/pdv'>PDV - Frente de caixa</CustomLink>,
            });
        }

        if (!isFintech && permissions.upp_venda_romaneio) {
            g.children.push({
                key: '/a/romaneio',
                label: <CustomLink to='/a/romaneio'>Expedição - Romaneio</CustomLink>,
            });
        }

        if (!isFintech && permissions.upp_venda_comissao) {
            g.children.push({
                key: '/a/comissao',
                label: <CustomLink to='/a/comissao'>Comissões</CustomLink>,
            });
        }

        return g;
    }, [isFintech, permissions]);

    return group.children.length > 0 ? group : null;
}
