import { createFileRoute } from '@tanstack/react-router';
import { type } from 'arktype';
import { TipoPermissao } from 'features/usuario/types/UsuarioTipoPermissao';
import { validateRoute } from 'router/util';

export const Route = createFileRoute('/a/cobranca-cartao')({
    validateSearch: type({ 'idpk?': 'string' }),
    beforeLoad: () =>
        validateRoute({
            podeExibirFintech: true,
            permissao: 'upp_financeiro_cartao',
            nivel: TipoPermissao.Completo,
        }),
});
