import { AppstoreAddOutlined } from '@ant-design/icons';
import type { SubMenuType } from 'antd/es/menu/interface';
import { useUserPermission } from 'features/login/hooks/useUserPermission';
import { useLoginStore } from 'features/login/store';
import { TipoPermissao } from 'features/usuario/types/UsuarioTipoPermissao';
import { useMemo } from 'react';
import { CustomLink } from './CustomLink';

export function useMenuItemsCompra() {
    const isFintech = useLoginStore((s) => s.isFintech);
    const permissions = useUserPermission({ upp_compra_compra: TipoPermissao.Completo });

    const group = useMemo(() => {
        const g: SubMenuType = {
            key: 'categoria-compra',
            icon: <AppstoreAddOutlined />,
            label: 'Compras',
            children: [],
        };

        if (permissions.upp_compra_compra) {
            g.children.push({
                key: '/a/compra',
                label: <CustomLink to='/a/compra'>Compras</CustomLink>,
            });
        }

        return g;
    }, [permissions]);

    return isFintech || group.children.length === 0 ? null : group;
}
