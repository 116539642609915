import type { ApiFinanceiroConta } from 'features/financeiro-conta/types/ApiFinanceiroConta';
import { newRequest } from 'std/api/newRequest';
import type { ApiPagination } from 'std/pagination/types';

export type ApiFinanceiroContaGetParams = Partial<
    ApiPagination & {
        tipo_conta: 'C' | 'B' | 'P';
        conta_padrao: 'S';
    }
>;

// Para consultar a conta do pixapay utilizar o endpoint Fintech/ContaConsultar
export function apiFinanceiroContaGet(
    params?: ApiFinanceiroContaGetParams,
): Promise<ApiFinanceiroConta[]> {
    return newRequest({
        url: 'FinanceiroConta/FinanceiroConta',
        type: 'get',
        params,
    });
}
