import { blue, gray, green, orange, purple, red, volcano } from '@ant-design/colors';

export type CobrancaBoletoStatus =
    | 'Incorreto'
    | 'Aguardando'
    | 'Protestado'
    | 'Negativado'
    | 'Devolvido Pix'
    | 'Liquidado'
    | 'Liquidado Pix'
    | 'Baixado'
    | 'Total';

export const CobrancaBoletoStatusCores: Record<CobrancaBoletoStatus, string> = {
    Incorreto: orange[5] ?? '',
    Aguardando: blue[5] ?? '',
    Protestado: volcano[5] ?? '',
    Negativado: volcano[7] ?? '',
    Liquidado: green[5] ?? '',
    Total: purple[5] ?? '',
    'Liquidado Pix': green[6] ?? '',
    'Devolvido Pix': red[5] ?? '',
    Baixado: gray[5] ?? '',
} as const;
