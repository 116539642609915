import { createFileRoute } from '@tanstack/react-router';
import { Pdv } from 'features/pdv/page/Pdv';
import { TipoPermissao } from 'features/usuario/types/UsuarioTipoPermissao';
import { validateRoute } from 'router/util';

export const Route = createFileRoute('/a/pdv')({
    beforeLoad: () =>
        validateRoute({
            permissao: 'upp_fiscal_nfce',
            nivel: TipoPermissao.Parcial,
            podeExibirFintech: false,
        }),
    component: Pdv,
});
