import { MessageOutlined } from '@ant-design/icons';
import type { SubMenuType } from 'antd/es/menu/interface';
import { useUserPermission } from 'features/login/hooks/useUserPermission';
import { TipoPermissao } from 'features/usuario/types/UsuarioTipoPermissao';
import { useMemo } from 'react';
import { CustomLink } from './CustomLink';

export function useMenuItemsNotificacoes() {
    const permissions = useUserPermission({
        upp_notificacao_notificacao: TipoPermissao.Completo,
    });

    const group: SubMenuType = useMemo(() => {
        return {
            key: 'categoria-notificacao',
            icon: <MessageOutlined />,
            label: 'Notificações',
            children: [
                {
                    key: '/a/notificacao/listagem',
                    label: <CustomLink to='/a/notificacao/listagem'>Listagem</CustomLink>,
                },
            ],
        };
    }, []);

    return permissions.upp_notificacao_notificacao ? group : null;
}
