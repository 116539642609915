import { Input, type InputRef, Modal } from 'antd';
import { type ChangeEvent, memo, useEffect, useRef, useState } from 'react';
import { twJoin } from 'tailwind-merge';
import useKeyboardShortcut from 'use-keyboard-shortcut';
import { type GlobalSearchMapItem, globalSearchMap } from './globalSearchMap';

function BaseGlobalSearch() {
    const ref = useRef<InputRef>(null);

    const [open, setOpen] = useState(false);

    const [match, setMatch] = useState<{
        item: GlobalSearchMapItem;
        matchArray: RegExpMatchArray;
    }>();

    const [showWarning, setShowWarning] = useState(false);

    const closeModal = (): void => {
        setOpen(false);
    };

    const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setShowWarning(false);

        for (const item of globalSearchMap) {
            for (const pattern of item.pattern) {
                const matchArray = e.target.value.match(pattern);
                if (matchArray) {
                    setMatch({ item, matchArray });
                    return;
                }
            }
        }
    };

    const onSearch = (): void => {
        if (match) {
            match.item.action(match.matchArray);
            setOpen(false);
        } else {
            setShowWarning(true);
        }
    };

    useKeyboardShortcut(
        ['Control', 'K'],
        () => {
            setTimeout(() => {
                //@ts-ignore
                ref.current.input.value = '';
            }, 100);

            setOpen(true);
            setMatch(undefined);
            setShowWarning(false);
        },
        { overrideSystem: true },
    );

    useEffect(() => {
        if (open && ref.current) {
            setTimeout(() => ref.current?.focus(), 0);
        }
    }, [open]);

    return (
        <Modal
            open={open}
            onClose={closeModal}
            onCancel={closeModal}
            animation={false}
            footer={null}
            closeIcon={null}
        >
            <Input.Search
                ref={ref}
                placeholder='Pesquisar'
                className={twJoin('w-full', showWarning && ' border border-red-500')}
                enterButton={true}
                onChange={onChange}
                onSearch={onSearch}
            />

            {match && (
                <div className='mt-4 text-xl font-bold text-center text-green-500'>
                    {match.item.description(match.matchArray)}
                </div>
            )}

            {showWarning && (
                <div className='mt-4 text-xl font-bold text-center text-red-500'>
                    Nenhuma correspondência encontrada
                </div>
            )}
        </Modal>
    );
}

export const GlobalSearch = memo(BaseGlobalSearch);
