import { Link } from '@tanstack/react-router';
import { useLayoutStore } from 'features/layout/store';
import { useLoginStore } from 'features/login/store';
import { useSistemaStore } from 'features/sistema/store';
import { memo } from 'react';
import { twJoin } from 'tailwind-merge';

function BaseMenuLogo({ admin }: { admin: boolean }) {
    const { theme, menuCollapsed } = useLayoutStore();
    const sistema = useSistemaStore((s) => s.sistema);
    const isFintech = useLoginStore((s) => s.isFintech);

    return (
        <Link
            className='flex items-center justify-center h-16 px-6'
            to={admin ? '/admin/empresa' : '/a/painel-gerencial'}
        >
            <img
                src={sistema?.sis_logotipo ? sistema.sis_logotipo : 'resources/images/logo.svg'}
                alt={sistema?.sis_nome_sistema}
            />

            {menuCollapsed ? null : (
                <>
                    <div
                        className={twJoin(
                            'pl-1 text-xl font-extrabold',
                            theme === 'light' ? 'text-black' : 'text-white',
                        )}
                    >
                        {sistema?.sis_nome_sistema ? sistema.sis_nome_sistema : 'Nome do sistema'}
                    </div>

                    {!admin && (
                        <div className='p-3 ml-3 border-l border-l-gray-300'>
                            {isFintech ? 'FINTECH' : 'ERP'}
                        </div>
                    )}
                </>
            )}
        </Link>
    );
}

export const MenuLogo = memo(BaseMenuLogo);
