import { type } from 'arktype';

export const VendaFormaPagamentoParcelaSchema = type({
    'vpp_idpk?': 'number|null',
    'vpp_venda_forma_pagamento_idpk?': 'number|null',
    'vpp_vencimento?': 'string|null',
    'vpp_dias?': 'number|null',
    'vpp_parcela?': 'number|null',
    'vpp_valor?': 'number|null',
    'vpp_ultima_alteracao?': 'string|null',
    'vpp_financeiro_movimento_idpk?': 'number|null',
});

export type VendaFormaPagamentoParcela = typeof VendaFormaPagamentoParcelaSchema.infer;
