import { type } from 'arktype';
import { AcaoSchema } from 'features/acao/types';
import type { DeepUndefinedOrNull } from 'types/partial';
import { VendaFormaPagamentoSchema } from '../../venda-forma-pagamento/types/VendaFormaPagamento';
import { VendaItemSchema } from '../../venda-item/types/VendaItem';

export const VENDA_TIPO = ['pedido', 'orçamento', 'venda', 'bonificação'] as const;
export type VendaTipo = (typeof VENDA_TIPO)[number];

const VENDA_STATUS = [
    'Finalizada',
    'Cancelada',
    'Pendente',
    'Recusado',
    'Cancelado',
    'Prevista',
] as const;

enum VendaFreteResponsavel {
    ContratacaoFreteRemetenteCIF = 0,
    ContratacaoFreteDestinatarioFOB = 1,
    ContratacaoFreteTerceiros = 2,
    TransporteProprioRemetente = 3,
    TransporteProprioDestinatario = 4,
    SemOcorrenciaTransporte = 9,
}

export enum VendaOrigem {
    Venda = 1,
    NFe = 2,
    NFCe = 3,
    Compra = 4,
    ContaReceber = 5,
    ContaPagar = 6,
    Boleto = 7,
    Pix = 8,
}

const VendaNotaFiscalSchema = type({
    vnf_idpk: 'number|null',
    vnf_venda_idpk: 'number|null',
    vnf_nfce_idpk: 'number|null',
    vnf_nota_fiscal_idpk: 'number|null',
});

const VendaSistemaIntegradoSchema = type({
    ven_dispositivo_idpk: 'number|null',
    ven_sistema_integrado_referencia: 'string|null',
    sii_idpk: 'number|null',
    sii_nome: 'string|null',
});

export const VendaSchema = type({
    acoes: AcaoSchema.array().or('null'),
    formas_de_pagamento: VendaFormaPagamentoSchema.array().or('null'),
    itens: VendaItemSchema.array().or('null'),
    sistema_integrado: VendaSistemaIntegradoSchema.or('null'),
    venda_nota_fiscal: VendaNotaFiscalSchema.array().or('null'),

    ven_idpk: 'number',
    ven_empresa_idpk: 'number',
    ven_tipo: type.enumerated(...VENDA_TIPO),
    ven_status: type.enumerated(...VENDA_STATUS),
    ven_data: 'string',
    ven_valor_total: 'number',
    ven_peso_bruto: 'number',
    ven_peso_liquido: 'number',

    ven_numero: 'string|null',
    ven_financeiro_categoria_idpk: 'number|null',
    ven_tabela_preco_idpk: 'number|null',
    ven_hora: 'string|null',
    ven_venda_externa_idpk: 'number|null',
    ven_contrato_idpk: 'number|null',
    ven_marcadores: 'string|null',
    ven_ultima_alteracao: 'string|null',
    ven_volume_qtde: 'number|null',
    ven_frete_responsavel: type.valueOf(VendaFreteResponsavel).or('null'),
    ven_validade_ate: 'string|null',
    ven_link_compartilhamento: 'string|null',
    ven_nota_gerada: "'S'|'N'|null",
    ven_romaneio: '0|1|null',
    ven_cliente_idpk: 'number|null',
    ven_cliente_nome: 'string|null',
    ven_cliente_apelido: 'string|null',
    ven_cliente_documento: 'string|null',
    ven_cliente_cep: 'string|null',
    ven_cliente_logradouro: 'string|null',
    ven_cliente_logradouro_numero: 'string|null',
    ven_cliente_complemento: 'string|null',
    ven_cliente_bairro: 'string|null',
    ven_cliente_cidade_idpk: 'number|null',
    ven_cliente_cidade: 'string|null',
    ven_cliente_uf: 'string|null',
    ven_cliente_pais_codigo: 'number|null',
    ven_cliente_responsavel: 'string|null',
    ven_cliente_fone: 'string|null',
    ven_cliente_email: 'string|null',
    ven_vendedor_idpk: 'number|null',
    ven_vendedor_nome: 'string|null',
    ven_vendedor_sequencial: 'number|null',
    ven_entregador_idpk: 'number|null',
    ven_produtos_valor: 'number|null',
    ven_desconto_valor: 'number|null',
    'ven_desconto_tipo?': "'%' | '$' | '' | null",
    ven_transportador_idpk: 'number|null',
    ven_transportador_nome: 'string|null',
    ven_transportador_documento: 'string|null',
    ven_observacao_interna: 'string|null',
    ven_observacao_cliente: 'string|null',
    ven_origem: type.valueOf(VendaOrigem).or('null'),
    ven_origem_id: 'number|null',
    ven_previsao_faturamento: 'string|null',
    ven_previsao_entrega_de: 'string|null',
    ven_previsao_entrega_ate: 'string|null',
    ven_usuario_idpk: 'number|null',
    ven_usuario: 'string|null',
    ven_data_orcamento: 'string|null',
    ven_data_pedido: 'string|null',
    ven_data_venda: 'string|null',
    ven_total_seguro: 'number|null',
    ven_total_despesas_acessorias: 'number|null',
    ven_total_frete: 'number|null',
    ven_total_imp_icmsst_valor: 'number|null',
    ven_total_imp_fcpst_valor: 'number|null',
    ven_total_imp_ipi_valor: 'number|null',
    ven_total_imp_ii_valor: 'number|null',
});

export type Venda = typeof VendaSchema.infer;
export type VendaBody = DeepUndefinedOrNull<Venda>;
