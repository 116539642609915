import { type } from 'arktype';
import { FormaPagamentoClassificacao } from 'features/forma-pagamento/types';
import { vendaFormaPagamentoAntecipadoSchema } from './VendaFormaPagamentoAntecipado';
import { VendaFormaPagamentoParcelaSchema } from './VendaFormaPagamentoParcela';

enum VendaFormaPagamentoTipo {
    PosPago = 1,
    PrePago = 2,
}

enum VendaFormaPagamentoFixarParcelas {
    Sim = 1,
    Nao = 2,
}

export const VendaFormaPagamentoSchema = type({
    'parcelas?': VendaFormaPagamentoParcelaSchema.array().or('null'),
    'antecipado?': vendaFormaPagamentoAntecipadoSchema.or('null'),

    'vfp_idpk?': 'number|null',
    'vfp_venda_idpk?': 'number|null',
    'vfp_forma_pagamento_valor?': 'number|null',
    'vfp_forma_pagamento_troco?': 'number|null',
    'vfp_forma_pagamento_valor_recebido?': 'number|null',
    'vfp_tipo?': type.valueOf(VendaFormaPagamentoTipo).or('null'),
    'vfp_classificacao?': type.valueOf(FormaPagamentoClassificacao).or('null'),
    'vfp_fixar_parcelas?': type.valueOf(VendaFormaPagamentoFixarParcelas).or('null'),
    'vfp_forma_pagamento_idpk?': 'number|null',
    'vfp_forma_pagamento_descricao?': 'string|null',
    'vfp_forma_pagamento_parcelas?': 'number|null',
    'vfp_financeiro_banco_pos_idpk?': 'number|null',
    'vfp_financeiro_conta_idpk?': 'number|null',
    'vfp_financeiro_conta_lancamento_idpk?': 'number|null',
    'vfp_condicao_pagamento_idpk?': 'number|null',
    'vfp_condicao_pagamento?': 'string|null',
    'vfp_ultima_alteracao?': 'string|null',
});

export type VendaFormaPagamento = typeof VendaFormaPagamentoSchema.infer;
