import { useLoginAdminStore } from 'features/login-admin/store';
import { newRequest } from 'std/api/newRequest';

export async function apiLoginLogofAdmin(): Promise<unknown> {
    const response = await newRequest({
        url: 'Login/Logof',
        type: 'post',
    });

    useLoginAdminStore.getState().clearLogin();

    return response;
}
