import { Link, type LinkProps } from '@tanstack/react-router';
import { $layoutStore } from 'features/layout/store';
import { memo } from 'react';

function BaseCustomLink(props: LinkProps) {
    return (
        <Link
            {...props}
            onClick={() => {
                if ($layoutStore.menuMobile) {
                    setTimeout(() => {
                        $layoutStore.menuCollapsed = true;
                    }, 200);
                }
            }}
        />
    );
}

export const CustomLink = memo(BaseCustomLink);
