import { useSistemaStore } from 'features/sistema/store';
import { memo } from 'react';

function BaseFooter() {
    const sistema = useSistemaStore((s) => s.sistema);

    return (
        <div className='flex flex-col items-end p-4'>
            <div>
                <a
                    href={sistema?.sis_landing_page}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='text-lg font-bold text-black uppercase'
                >
                    {sistema?.sis_nome_sistema}
                </a>

                <span style={{ color: 'rgb(89, 92, 151)', paddingLeft: 8 }}>
                    {`${APP_VERSION} - ${CURRENT_COMMIT}`}
                </span>
            </div>

            <p style={{ marginBottom: 0 }}>
                <span className='text-gray-500 '>Copyright © {sistema?.sis_copyright}</span>
            </p>
        </div>
    );
}

export const Footer = memo(BaseFooter);
