import { createFileRoute, redirect } from '@tanstack/react-router';
import { ImportacaoXML, type TipoImportacaoXml, tipoImportacaoXml } from 'pages/importacao-xml';
import { validateRoute } from 'router/util';

export const Route = createFileRoute('/a/importacao/xml/$tipo')({
    beforeLoad({ params }) {
        if (!tipoImportacaoXml.includes(params.tipo as TipoImportacaoXml)) {
            throw redirect({ to: '/' });
        }

        validateRoute({
            podeExibirFintech: true,
        });
    },
    component: ImportacaoXML,
});
