import type { EmpresaFintechExtratoTemporario } from 'features/empresa/types/Empresa';
import { useLoginStore } from '../store';

export function useCanShowExtratoTemporario(keys: EmpresaFintechExtratoTemporario[]): boolean {
    const empresa = useLoginStore((s) => s.getEmpresa());
    if (!empresa) {
        return false;
    }

    return keys.includes(empresa.emp_fintech_extrato_temporario);
}
