import type { RcFile } from 'antd/lib/upload';
import { type Result, err, ok } from 'types/result';
import { uuid } from 'std/util';

/** Codifica uma string para base64 */
export function strToBase64(value: string): string {
    return window.btoa(value);
}

/** Decodifica um valor em base64 para string */
// https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_.22unicode_problem.22
export function base64ToStr(value: string): string {
    const bytes = window.atob(value);
    const arr = Uint8Array.from(bytes, (m) => m.codePointAt(0) || 0);
    return new TextDecoder().decode(arr);
}

// https://stackoverflow.com/questions/36280818/how-to-convert-file-to-base64-in-javascript
/**
 * Converte um arquivo em base64
 * @param file - O arquivo a ser convertido
 * @param hideData - Esconde o inicio do base64 que começa com "data"
 * */
export async function fileToBase64(file: Blob | RcFile, hideData = true): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
            if (reader.result) {
                let encoded = reader.result.toString();

                if (hideData) {
                    encoded = encoded.replace(/^data:(.*,)?/, '');

                    if (encoded.length % 4 > 0) {
                        encoded += '='.repeat(4 - (encoded.length % 4));
                    }
                }

                resolve(encoded);
            }
        };

        reader.onerror = (error) => reject(error);
    });
}

/** Decodifica um valor em base64 para um PDF no formato Blob */
export function base64ToPDF(base64: string): Blob {
    const byteCharacters = window.atob(base64);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i += 1) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: 'application/pdf;base64' });
}

export function base64ToFile(
    base64: string,
    fileName: string,
    mimeType: string,
): Result<File, string> {
    const msg = 'Erro ao converter para arquivo';

    try {
        let src = '';

        if (base64.startsWith('data:')) {
            src = base64.split(',')[1] || '';
        } else {
            src = base64;
        }

        const byteCharacters = atob(src);
        const byteNumbers = new Array(byteCharacters.length)
            .fill(0)
            .map((_, i) => byteCharacters.charCodeAt(i));
        const byteArray = new Uint8Array(byteNumbers);
        const file = new File([byteArray], fileName, { type: mimeType });
        return ok(file);
    } catch {
        return err(msg);
    }
}

/** Codifica um arquivo vindo de uma url para base64 */
export async function urlToBase64(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();

        xhr.onload = () => {
            const reader = new FileReader();

            reader.onloadend = () => {
                const str = reader.result?.toString() || '';
                const result = str.replace('data:binary/octet-stream;base64,', '');
                resolve(result);
            };

            reader.readAsDataURL(xhr.response);
        };

        xhr.onerror = (error) => reject(error);

        xhr.open('GET', `${url}?${uuid()}`);
        xhr.responseType = 'blob';
        xhr.send();
    });
}

export function downloadBase64File(base64: string, fileName: string, mimeType: string): void {
    // Decode the Base64 string
    const byteCharacters = atob(base64);

    // Create an array for the byte data
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    // Create a Blob from the byteArray
    const blob = new Blob([byteArray], { type: mimeType });

    // Create a temporary anchor element
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;

    // Trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
}
