import { MenuOutlined } from '@ant-design/icons';
import { useLocation } from '@tanstack/react-router';
import { Button, Grid } from 'antd';
import { useLayoutStore } from 'features/layout/store';
import { memo } from 'react';
import { twJoin } from 'tailwind-merge';
import { HeaderRepresentante } from './HeaderRepresentante';
import { LayoutHeaderDropdownLinks } from './LayoutHeaderDropdownLinks';
import { SelectEmpresas } from './SelectEmpresas';
import { SwitchTheme } from './SwitchTheme';
import { UserMenu } from './UserMenu/UserMenu';
import { UserMenuAdmin } from './UserMenu/UserMenuAdmin';

function BaseLayoutHeader({ admin }: { admin: boolean }) {
    const location = useLocation();
    const grid = Grid.useBreakpoint();

    const { theme, toggleMenuCollapsed } = useLayoutStore();

    if (location.pathname === '/a/pdv') {
        return null;
    }

    return (
        <div
            className={twJoin(
                'sticky h-16 top-0 z-[998] shadow-sm justify-between flex py-1 items-center',
                theme === 'light' ? 'bg-white' : 'bg-[#001727]',
                grid.sm ? 'px-4' : 'px-1',
            )}
        >
            {!grid.md && <Button icon={<MenuOutlined />} onClick={toggleMenuCollapsed} />}

            <div className='w-[16rem] sm:w-96 lg:w-[32rem]'>
                {admin ? <HeaderRepresentante /> : <SelectEmpresas />}
            </div>

            <div className={twJoin('flex items-center gap-2', grid.md && 'pr-4')}>
                {grid.md && <SwitchTheme />}
                <LayoutHeaderDropdownLinks admin={admin} />
                {admin ? <UserMenuAdmin /> : <UserMenu />}
            </div>
        </div>
    );
}

export const LayoutHeader = memo(BaseLayoutHeader);
