import { type } from 'arktype';
import { EmpresaUsuarioSchema } from 'features/painel-administrativo/empresa/page/forms/Usuarios/cadastro-usuario/types';
import { TipoPessoa } from 'features/pessoa/enum';
import type { SimNao } from 'types';
import { AmbienteFiscal } from 'types/enum';
import { RepresentanteSchema } from './EmpresaRepresentante';
import { EmpresaSlugSchema } from './EmpresaSlug';
import {
    EmpresaCrtCrt,
    EmpresaIndicadorIE,
    EmpresaMicro,
    EmpresaPermiteCredito,
    EmpresaSistemaTipoUso,
    EmpresaSituacao,
    EmpresaTipoEmissao,
} from './enum';

const EmpresaCrtSchema = type({
    crt_idpk: 'number',
    crt_crt: type.valueOf(EmpresaCrtCrt),
    crt_descricao: 'string',
});

const EmpresaCidadeSchema = type({
    pais: 'string',
    cid_uf: 'string',
    cid_ibge: 'string',
    cid_cidade: 'string',
    cid_timezone: 'string',
    cid_pais_idpk: 'number',
    'cid_idpk?': 'number',
    'cid_ultima_alteracao?': 'string|null',
});

const EmpresaSuporteSchema = type({
    esu_empresa_idpk: 'number',
    esu_suporte_ativo: "'S'|'N'",
    esu_expiracao: 'string|null',
});

export type EmpresaSuporte = typeof EmpresaSuporteSchema.infer;

export enum EmpresaFintechExtratoTemporario {
    PadraoAntigo = 1,
    PadraoAtual = 2,
    Manutencao = 3,
}

export const EmpresaSchema = type({
    crt: EmpresaCrtSchema.or('null'),
    cidade: EmpresaCidadeSchema.or('null'),
    fintech: type('Record<string,unknown>').or('null'),
    suporte: EmpresaSuporteSchema.or('null'),
    contador: type('Record<string,unknown>').or('null'),
    empresa_slug: EmpresaSlugSchema.or('null'),
    representante: RepresentanteSchema.or('null'),
    ramo_atividade: type('Record<string,unknown>').or('null'),
    'usuario?': EmpresaUsuarioSchema.array().or('null'),

    emp_idpk: 'number',
    emp_sistema_idpk: 'number',
    emp_sistema_tipo_uso: type.valueOf(EmpresaSistemaTipoUso),
    emp_situacao: type.valueOf(EmpresaSituacao),
    emp_tipo_pessoa: type.valueOf(TipoPessoa),
    emp_financeiro_juros_mes: 'number',
    emp_financeiro_multa: 'number',
    // caso o vencimento + carência cair no sábado, se estiver marcado nao gerar multa para pagamento ate Segunda
    emp_financeiro_sabado: 'number',
    // caso o vencimento + carência cair no domingo se estiver marcado nao gerar multa para pagamento ate Segunda
    emp_financeiro_domingo: 'number',
    emp_fiscal_tipo_emissao: type.valueOf(EmpresaTipoEmissao),
    emp_notificacao: '0|1',
    emp_fintech_extrato_temporario: type.valueOf(EmpresaFintechExtratoTemporario),
    emp_representante_idpk: 'number|null',
    emp_empresa_representante_idpk: 'number|null',
    emp_financeiro_categoria_venda_idpk: 'number|null',
    emp_cidade_idpk: 'number|null',
    emp_crt_idpk: 'number|null',
    emp_ramoatividade_idpk: 'number|null',
    emp_regra_fiscal_idpk: 'number|null',
    emp_data_bloqueio: 'string|null',
    emp_microempresa: type.valueOf(EmpresaMicro).or('null'),
    emp_responsavel: 'string|null',
    emp_data_insercao: 'string|null',
    emp_data_nascimento_abertura: 'string|null',
    emp_nome_razaosocial: 'string|null',
    emp_nome_fantasia: 'string|null',
    emp_cnpj_cpf: 'string|null',
    emp_ie_rg: 'string|null',
    emp_ie_rg_indicador: type.valueOf(EmpresaIndicadorIE).or('null'),
    emp_im: 'string|null',
    emp_rg_orgao: 'string|null',
    emp_rg_estado: 'string|null',
    emp_rg_emissao: 'string|null',
    emp_cnae: 'string|null',
    emp_logotipo_dataenvio: 'string|null',
    emp_logotipo_foto: 'string|null',
    emp_doc_estrangeiro: 'string|null',
    emp_logradouro: 'string|null',
    emp_logradouro_numero: 'string|null',
    emp_bairro: 'string|null',
    emp_complemento: 'string|null',
    emp_telefone_principal: 'string|null',
    emp_telefone_secundario: 'string|null',
    emp_email: 'string|null',
    emp_certificado_dataenvio: 'string|null',
    emp_certificado: 'string|null',
    emp_certificado_senha: 'string|null',
    emp_certificado_validade: 'string|null',
    emp_certificado_CSC: 'string|null',
    emp_certificado_CSC_id: 'string|null',
    emp_cep: 'string|null',
    emp_financeiro_carencia_dias: 'number|null',
    // caso o vencimento + carência cair no feriado nacional se estiver marcado nao gerar multa para pagamento no próximo dia util
    emp_financeiro_feriado: 'number|null',
    emp_fiscal_ambiente: type.valueOf(AmbienteFiscal).or('null'),
    emp_chave_cadastro: 'string|null',
    emp_webhook_padrao: 'string|null',
    emp_webhook_token: 'string|null',
    emp_imposto_permitecredito: type.valueOf(EmpresaPermiteCredito).or('null'),
    emp_imposto_icmscredito: 'number|null',
    emp_imposto_funrural_aliquota: 'number|null',
    emp_imposto_funrural_descontar: 'number|null',
    emp_ultima_alteracao: 'string|null',
});

export type Empresa = typeof EmpresaSchema.infer;

export type ApiEmpresaContador = {
    emc_empresa_idpk: number;
    emc_relacao_envio_automatico: SimNao;
    emc_relacao_envio_dia: number;
    emc_relacao_envio_xml: SimNao;
    emc_relacao_envio_relacao_fiscal: SimNao;
    emc_relacao_envio_relacao_financeira: SimNao;
    emc_autorizar_autxml: SimNao;
} & Partial<{
    emc_documento: string;
    emc_razaosocial: string;
    emc_logradouro: string;
    emc_logradouro_numero: string;
    emc_logradouro_complemento: string;
    emc_bairro: string;
    emc_cep: string;
    emc_cidade_idpk: number;
    emc_cidade: string;
    emc_uf: string;
    emc_ibge: string;
    emc_fone: string;
    emc_email: string;
    emc_contabilista_nome: string;
    emc_contabilista_documento: string;
    emc_contabilista_crc: string;
}>;

export enum EmpresaSlugCatalogoExibirPreco {
    NaoExibir = 1,
    PrecoProduto = 2,
    PrecoTabela = 3,
}

export type ApiEmpresaRamoAtividade = {
    era_idpk: number;
    era_tipo: string;
    era_ramointerno: number;
};
