import { type } from 'arktype';

export enum VendaFormaPagamentoAntecipadoStatus {
    Desconhecido = 0,
    Aguardando = 1,
    Pago = 2,
    Estornado = 3,
}

export const vendaFormaPagamentoAntecipadoSchema = type({
    status: type.valueOf(VendaFormaPagamentoAntecipadoStatus),
    cartao: type({
        fmc_identificador: 'string',
        pagamento: type({
            fmc_transacao_valor_liquido: 'number',
            fmc_transacao_valor_recebido: 'number',
            'fmc_transacao_nsu?': 'string|null',
            'fmc_transacao_data?': 'string|null',
            'fmc_transacao_rede?': 'string|null',
            'fmc_transacao_codigo?': 'string|null',
            'fmc_transacao_cliente?': 'string|null',
            'fmc_transacao_bandeira?': 'string|null',
            'fmc_transacao_cnpj_credenciador?': 'string|null',
            'fmc_transacao_cartao?': 'string|null',
        })
            .or('null')
            .optional(),
    })
        .or('null')
        .optional(),
    pix: type({
        fmp_idpk: 'number',
    })
        .or('null')
        .optional(),
});
