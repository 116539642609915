import { DollarOutlined } from '@ant-design/icons';
import type { SubMenuType } from 'antd/es/menu/interface';
import { EmpresaFintechExtratoTemporario } from 'features/empresa/types/Empresa';
import { useCanShowExtratoTemporario } from 'features/login/hooks/useCanShowExtratoTemporario';
import { useUserPermission } from 'features/login/hooks/useUserPermission';
import { TipoPermissao } from 'features/usuario/types/UsuarioTipoPermissao';
import { useMemo } from 'react';
import { CustomLink } from './CustomLink';

export function useMenuItemsFinanceiro() {
    const showExtratoTemporarioAtual = useCanShowExtratoTemporario([
        EmpresaFintechExtratoTemporario.PadraoAtual,
        EmpresaFintechExtratoTemporario.Manutencao,
    ]);

    const showExtratoTemporarioAntigo = useCanShowExtratoTemporario([
        EmpresaFintechExtratoTemporario.PadraoAntigo,
        EmpresaFintechExtratoTemporario.Manutencao,
    ]);

    const permissions = useUserPermission({
        upp_financeiro_movimentacao: TipoPermissao.Completo,
        upp_financeiro_conta_receber: TipoPermissao.Completo,
        upp_financeiro_conta_pagar: TipoPermissao.Completo,
        upp_financeiro_cartao: TipoPermissao.Completo,
        upp_financeiro_pix: TipoPermissao.Completo,
        upp_fintech_administrativo: TipoPermissao.Completo,
        upp_financeiro_boleto: TipoPermissao.Completo,
    });

    const group = useMemo(() => {
        const g: SubMenuType = {
            key: 'categoria-financeiro',
            icon: <DollarOutlined />,
            label: 'Financeiro',
            children: [],
        };

        if (showExtratoTemporarioAtual && permissions.upp_financeiro_movimentacao) {
            g.children.push({
                key: '/a/movimentacoes',
                label: <CustomLink to='/a/movimentacoes'>Movimentações</CustomLink>,
            });
        }

        if (permissions.upp_financeiro_conta_receber) {
            g.children.push({
                key: '/a/contas-receber',
                label: <CustomLink to='/a/contas-receber'>Contas a receber</CustomLink>,
            });
        }

        if (permissions.upp_financeiro_conta_pagar) {
            g.children.push({
                key: '/a/contas-pagar',
                label: <CustomLink to='/a/contas-pagar'>Contas a pagar</CustomLink>,
            });
        }

        if (permissions.upp_financeiro_cartao) {
            g.children.push({
                key: '/a/cobranca-cartao',
                label: <CustomLink to='/a/cobranca-cartao'>Cobranças cartão</CustomLink>,
            });
        }

        if (permissions.upp_financeiro_pix) {
            g.children.push({
                key: '/a/cobranca-pix',
                label: (
                    <CustomLink to='/a/cobranca-pix' search={{ tipo: 'instantaneo' }}>
                        Cobranças pix
                    </CustomLink>
                ),
            });
        }

        if (
            showExtratoTemporarioAntigo &&
            permissions.upp_fintech_administrativo &&
            permissions.upp_financeiro_movimentacao
        ) {
            g.children.push({
                key: '/a/extrato-pix',
                label: <CustomLink to='/a/extrato-pix'>Extrato pix</CustomLink>,
            });
        }

        if (permissions.upp_financeiro_boleto) {
            g.children.push({
                key: '/a/cobranca-boleto',
                label: <CustomLink to='/a/cobranca-boleto'>Cobranças boleto</CustomLink>,
            });
        }

        if (
            showExtratoTemporarioAntigo &&
            permissions.upp_fintech_administrativo &&
            permissions.upp_financeiro_movimentacao
        ) {
            g.children.push({
                key: '/a/extrato-boleto',
                label: <CustomLink to='/a/extrato-boleto'>Extrato boleto</CustomLink>,
            });
        }

        return g;
    }, [showExtratoTemporarioAtual, showExtratoTemporarioAntigo, permissions]);

    return group.children.length > 0 ? group : null;
}
