import dayjs from 'dayjs';
import type { Cidade } from 'features/cidade-uf-pais/types';
import type { Cliente } from 'features/cliente/types/Cliente';
import type { ApiContrato } from 'features/contrato/types/ApiContrato';
import type { ApiTransportador } from 'features/transportador/types';
import type { UsuarioEmpresa } from 'features/usuario/types/UsuarioEmpresa';
import { VendaGetParamsDataTipo } from 'features/venda/api/apiVendaGet';
import type { Venda, VendaTipo } from 'features/venda/types/Venda';
import { DATE_FORMAT } from 'std/const/date';
import { createProxyPagination } from 'std/pagination';

export type VendaFilterTipo = VendaTipo | 'cancelado' | 'total';

export type VendaFilter = {
    tipo: VendaFilterTipo;
    pesquisar: string;
    data_tipo: VendaGetParamsDataTipo;
    data_inicio: string;
    data_fim: string;
    cliente: Cliente;
    cidade: Cidade;
    transportador: ApiTransportador;
    vendedor: UsuarioEmpresa;
    marcadores: string;
    contrato: ApiContrato;
};

export const $vendaStorePagination = createProxyPagination<Venda, VendaFilter>({
    name: 'venda-pagination',
    filter: {
        data_tipo: VendaGetParamsDataTipo.DataGeracao,
        data_inicio: dayjs().subtract(1, 'month').format(DATE_FORMAT),
        data_fim: dayjs().format(DATE_FORMAT),
    },
    apiPagination: {
        orderby: 'cast(ven_numero as float) desc',
    },
    fieldMap: new Map([['ven_numero', 'cast(ven_numero as float)']]),
});
