import { createFileRoute } from '@tanstack/react-router';
import { type } from 'arktype';
import { Setting } from 'pages/setting';

export const Route = createFileRoute('/a/configuracao/empresa/')({
    validateSearch: type({
        'tab?': 'string',
        'menu?': 'string',
    }),
    component: Setting,
});
