import { notification } from 'antd';
import { useLoginStore } from 'features/login/store';
import { userHasPermission } from 'features/login/util';
import { TipoPermissao } from 'features/usuario/types/UsuarioTipoPermissao';
import type { RootDispatch, RootState } from 'state/store';
import { type BuildUrlParams, buildUrl } from 'std/api/buildUrl';
import { comTokenGet, comTokenPost, comTokenPut, comTokenRemove } from 'std/api/comToken';
import { isResponseErr, throwIfResponseIsErr } from 'std/api/util';
import { endReduxFnError, endReduxFnOk, startReduxFn } from 'std/redux';
import { Endpoint } from 'types/enum/endpoint';
import type { SortParams } from 'types/interfaces';
import type { TAtendimento } from './types';

export const effects = (dispatch: RootDispatch) => ({
    async get(
        payload: {
            cliente_idpk?: number;
            data_de?: string;
            data_ate?: string;
            total_registros?: string;
            registro_inicial?: number;
            qtde_registros?: number;
            sort?: SortParams;
            resetPagination?: boolean;
        },
        state: RootState,
    ): Promise<void> {
        const { atendimento } = state;
        const { getTable, get } = atendimento;

        dispatch.atendimento.setState({
            get: startReduxFn(get.data),
            getTable: {
                ...getTable,
                updateTable: false,
            },
        });

        const {
            filterCliente,
            filterDateRange,
            registroInitial,
            qtdRegistros,
            sortParams,
            pagination,
        } = getTable;

        const {
            cliente_idpk,
            data_de,
            data_ate,
            total_registros,
            registro_inicial,
            qtde_registros,
            sort,
            resetPagination,
            ...restPayload
        } = payload;

        // MONTANDO OS PARAMETROS OBRIGATÓRIOS
        const params: BuildUrlParams = {
            cliente_idpk: cliente_idpk || filterCliente,
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            registro_inicial:
                registro_inicial !== null && registro_inicial !== undefined
                    ? registro_inicial
                    : registroInitial,
            qtde_registros: qtde_registros || qtdRegistros,
            orderby: 'ate_data desc, ate_idpk desc',
            ...restPayload,
        };

        if (!userHasPermission('upp_venda_atendimento', TipoPermissao.Completo)) {
            params.vendedor_idpk = useLoginStore.getState().login?.usu_idpk;
        }

        // CASO TIVER O FILTRO DE NOME/TEXTO (*OPCIONAL)
        if (!!data_de || !!filterDateRange?.start) {
            params.data_de = data_de || filterDateRange?.start.format('DD-MM-YYYY');
        }

        // CASO TIVER O FILTRO DE NOME/TEXTO (*OPCIONAL)
        if (!!data_ate || !!filterDateRange?.end) {
            params.data_ate = data_ate || filterDateRange?.end.format('DD-MM-YYYY');
        }

        // CASO TER QUE FILTRAR (SORTEAR) OS DADOS (*OPCIONAL)
        if (sort?.shouldSort || sortParams?.shouldSort) {
            params.orderby = `${sort?.fieldName || sortParams?.fieldName}${
                sort?.orderDirection || sortParams?.orderDirection
            }`;
        }

        // CASO TIVER ENVIADO PARA MOSTRAR TODOS REGISTROS
        if (total_registros) {
            params.total_registros = total_registros;
        }

        // SE MUDAR O FILTRO OU PRECISAR RESETAR A PAGINAÇÃO
        if (resetPagination) {
            params.registro_inicial = 0;
            params.total_registros = 'S';
        }

        const url = buildUrl(Endpoint.AtendimentoConsultar, params);

        try {
            const response = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const {
                data: { registros = [], total_registros: totalRegistroResponse = null } = {},
            } = response;

            const { atendimento: newAtendimento } = state;
            const { getTable: newGetTable } = newAtendimento;

            dispatch.atendimento.setState({
                get: endReduxFnOk(registros),
                getTable: {
                    ...newGetTable,
                    updateTable: false,
                    filterCliente: cliente_idpk || filterCliente,
                    registroInitial:
                        registro_inicial !== null && registro_inicial !== undefined
                            ? registro_inicial
                            : registroInitial,
                    // SE MUDAR O FILTRO OU PRECISAR RESETAR A PAGINAÇÃO
                    ...(resetPagination && {
                        registro_inicial: 0,
                    }),
                    qtdRegistros: qtde_registros || qtdRegistros,
                    pagination: {
                        ...pagination,
                        // SE MUDAR O FILTRO OU PRECISAR RESETAR A PAGINAÇÃO
                        ...(resetPagination && {
                            current: 1,
                        }),
                        // SE PRECISAR ATUALIZAR A PÁGINA É FEITO AQUI
                        ...(registro_inicial !== null &&
                            registro_inicial !== undefined && {
                                current: registro_inicial / (qtde_registros || qtdRegistros) + 1,
                            }),
                        // SE PRECISAR ATUALIZAR OS TOTAIS É FEITO AQUI
                        ...((totalRegistroResponse || totalRegistroResponse === 0) && {
                            total: totalRegistroResponse,
                            showTotal: () => `Total de Registros: ${totalRegistroResponse}`,
                            showSizeChanger: totalRegistroResponse > 10,
                        }),
                    },
                },
            });
        } catch (error) {
            dispatch.atendimento.setState({
                get: endReduxFnError(error),
            });
        }
    },

    async getOne(payload: { ate_idpk: number }, state: RootState): Promise<void> {
        const { atendimento } = state;
        const { getOne } = atendimento;

        dispatch.atendimento.setState({
            getOne: startReduxFn(getOne?.data),
        });

        const { ate_idpk } = payload;
        const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };

        const url = buildUrl(Endpoint.AtendimentoConsultar, params, ate_idpk);

        try {
            const response = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const {
                data: { registros = [] } = {},
            } = response;

            dispatch.atendimento.setState({
                getOne: endReduxFnOk(registros && registros.length > 0 ? registros[0] : null),
            });
        } catch (error) {
            dispatch.atendimento.setState({
                getOne: endReduxFnError(error),
            });
        }
    },

    async post(payload: { body: any; updateTable?: boolean }, state: RootState): Promise<void> {
        const { atendimento } = state;
        const { post } = atendimento;

        dispatch.atendimento.setState({
            post: startReduxFn(post?.data),
        });

        const { body, updateTable } = payload;
        const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };

        const url = buildUrl(Endpoint.AtendimentoInserir, params);

        try {
            const response = await comTokenPost(url, body);

            if (isResponseErr(response)) {
                // Colocado aqui para inserir o ERROR para pegar os dados personalizados
                dispatch.atendimento.setState({
                    post: endReduxFnError(response.data),
                });

                throw new Error(response.data?.mensagem || '');
            }

            const ate_idpk: number = response?.data?.registros
                ? response.data.registros[0].ate_idpk
                : 0;

            dispatch.atendimento.setState({
                post: endReduxFnOk({ ate_idpk }),
            });

            if (updateTable) {
                dispatch.atendimento.get({ total_registros: 'S' });
            }

            notification.success({
                message: 'Feito!',
                description: 'Atendimento cadastrado',
            });
        } catch (error) {
            dispatch.atendimento.setState({
                post: endReduxFnError(error),
            });

            notification.error({
                message: 'Não foi possível cadastrar o Atendimento',
                description: error.message,
            });
        }
    },

    async put(
        payload: { ate_idpk: number; body: TAtendimento; updateTable?: boolean },
        state: RootState,
    ): Promise<void> {
        const { atendimento } = state;
        const { put } = atendimento;

        dispatch.atendimento.setState({
            put: startReduxFn(put?.data),
        });

        const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };
        const { ate_idpk = 0, body, updateTable } = payload;

        const url = buildUrl(Endpoint.AtendimentoAlterar, params, ate_idpk);

        try {
            body.ate_idpk = undefined;
            const response = await comTokenPut(url, body);

            if (isResponseErr(response)) {
                // Colocado aqui para inserir o ERROR para pegar os dados personalizados
                dispatch.atendimento.setState({
                    put: endReduxFnError(response.data),
                });

                throw new Error(response.data?.mensagem || '');
            }

            dispatch.atendimento.setState({
                put: endReduxFnOk('Success'),
            });

            if (updateTable) {
                dispatch.atendimento.get({ total_registros: 'S' });
            }

            notification.success({
                message: 'Feito!',
                description: 'Atendimento atualizado',
            });
        } catch (error) {
            notification.error({
                message: 'Não foi possível atualizar o atendimento!',
                description: error.message,
            });
        }
    },

    async remove(
        payload: { ate_idpk: number; updateTable?: boolean },
        state: RootState,
    ): Promise<void> {
        const { atendimento } = state;
        const { getTable, remove } = atendimento;

        dispatch.atendimento.setState({
            remove: startReduxFn(remove?.data),
            getTable: {
                ...getTable,
                loadingTable: true,
            },
        });

        const { ate_idpk = 0, updateTable } = payload;
        const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };

        const url = buildUrl(Endpoint.AtendimentoRemover, params, ate_idpk);

        try {
            const response = await comTokenRemove(url);
            throwIfResponseIsErr(response);

            dispatch.atendimento.setState({
                remove: endReduxFnOk('Success'),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });

            if (updateTable) {
                const {
                    pagination,
                    pagination: { total = 0 },
                    registroInitial = 0,
                } = getTable;

                let isLastPageOnlyOneRegister = false;

                // VERIFICA SE É A ÚLTIMA PÁGINA E TEM APENAS UM ITEM PARA PODER MUDAR DE PÁGINA APÓS DELETAR
                if (total && registroInitial && total - 1 === registroInitial) {
                    isLastPageOnlyOneRegister = true;
                    dispatch.atendimento.get({
                        total_registros: 'S',
                        registro_inicial: registroInitial - (pagination?.pageSize || 0),
                    });
                }

                if (!isLastPageOnlyOneRegister) {
                    dispatch.atendimento.get({ total_registros: 'S' });
                }
            }

            notification.success({
                message: 'Feito!',
                description: 'Atendimento excluído!',
            });
        } catch (error) {
            dispatch.atendimento.setState({
                remove: endReduxFnError(error),
                getTable: {
                    ...getTable,
                    loadingTable: false,
                },
            });

            notification.error({
                message: 'Falhou!',
                description: String(error),
            });
        }
    },
});
