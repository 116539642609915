/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router';

// Import Routes

import { Route as rootRoute } from './routes/__root';
import { Route as AuthRouteImport } from './routes/auth/route';
import { Route as ARouteImport } from './routes/a/route';
import { Route as IndexImport } from './routes/index';
import { Route as PErroImport } from './routes/p/erro';
import { Route as AuthSolicitarValidacaoImport } from './routes/auth/solicitar-validacao';
import { Route as AuthRedefinirSenhaImport } from './routes/auth/redefinir-senha';
import { Route as AuthRecuperarSenhaImport } from './routes/auth/recuperar-senha';
import { Route as AuthLoginImport } from './routes/auth/login';
import { Route as AuthContaBloqueadaImport } from './routes/auth/conta-bloqueada';
import { Route as AuthCadastroFimImport } from './routes/auth/cadastro-fim';
import { Route as AuthCadastroImport } from './routes/auth/cadastro';
import { Route as AuthAcessoNegadoImport } from './routes/auth/acesso-negado';
import { Route as AdminAuthImport } from './routes/admin/auth';
import { Route as APdvImport } from './routes/a/pdv';
import { Route as AdminARouteImport } from './routes/admin/_a/route';
import { Route as AVendaExternaRouteImport } from './routes/a/venda-externa/route';
import { Route as AVendaRouteImport } from './routes/a/venda/route';
import { Route as ATransportadorRouteImport } from './routes/a/transportador/route';
import { Route as ARomaneioRouteImport } from './routes/a/romaneio/route';
import { Route as ARegraFiscalRouteImport } from './routes/a/regra-fiscal/route';
import { Route as AProdutoRouteImport } from './routes/a/produto/route';
import { Route as APagforRouteImport } from './routes/a/pagfor/route';
import { Route as ANotificacaoRouteImport } from './routes/a/notificacao/route';
import { Route as ANotaFiscalRouteImport } from './routes/a/nota-fiscal/route';
import { Route as AMovimentacoesRouteImport } from './routes/a/movimentacoes/route';
import { Route as AManifestoCargaRouteImport } from './routes/a/manifesto-carga/route';
import { Route as AFornecedorRouteImport } from './routes/a/fornecedor/route';
import { Route as AExtratoPixRouteImport } from './routes/a/extrato-pix/route';
import { Route as AExtratoBoletoRouteImport } from './routes/a/extrato-boleto/route';
import { Route as AEstoqueRouteImport } from './routes/a/estoque/route';
import { Route as ADebugRouteImport } from './routes/a/debug/route';
import { Route as ACupomFiscalRouteImport } from './routes/a/cupom-fiscal/route';
import { Route as AContasReceberRouteImport } from './routes/a/contas-receber/route';
import { Route as AContasPagarRouteImport } from './routes/a/contas-pagar/route';
import { Route as ACompraRouteImport } from './routes/a/compra/route';
import { Route as AComissaoRouteImport } from './routes/a/comissao/route';
import { Route as ACobrancaPixRouteImport } from './routes/a/cobranca-pix/route';
import { Route as ACobrancaCartaoRouteImport } from './routes/a/cobranca-cartao/route';
import { Route as ACobrancaBoletoRouteImport } from './routes/a/cobranca-boleto/route';
import { Route as AClienteRouteImport } from './routes/a/cliente/route';
import { Route as AVendaExternaIndexImport } from './routes/a/venda-externa/index';
import { Route as ATransportadorIndexImport } from './routes/a/transportador/index';
import { Route as ASuporteIndexImport } from './routes/a/suporte/index';
import { Route as ARomaneioIndexImport } from './routes/a/romaneio/index';
import { Route as ARegraFiscalIndexImport } from './routes/a/regra-fiscal/index';
import { Route as AProdutoIndexImport } from './routes/a/produto/index';
import { Route as APainelGerencialIndexImport } from './routes/a/painel-gerencial/index';
import { Route as ANotaFiscalIndexImport } from './routes/a/nota-fiscal/index';
import { Route as AMovimentacoesIndexImport } from './routes/a/movimentacoes/index';
import { Route as AManifestoCargaIndexImport } from './routes/a/manifesto-carga/index';
import { Route as AFornecedorIndexImport } from './routes/a/fornecedor/index';
import { Route as AExtratoPixIndexImport } from './routes/a/extrato-pix/index';
import { Route as AExtratoBoletoIndexImport } from './routes/a/extrato-boleto/index';
import { Route as ADebugIndexImport } from './routes/a/debug/index';
import { Route as ACupomFiscalIndexImport } from './routes/a/cupom-fiscal/index';
import { Route as AContratoIndexImport } from './routes/a/contrato/index';
import { Route as AContasReceberIndexImport } from './routes/a/contas-receber/index';
import { Route as AContasPagarIndexImport } from './routes/a/contas-pagar/index';
import { Route as ACompraIndexImport } from './routes/a/compra/index';
import { Route as AComissaoIndexImport } from './routes/a/comissao/index';
import { Route as ACobrancaPixIndexImport } from './routes/a/cobranca-pix/index';
import { Route as ACobrancaCartaoIndexImport } from './routes/a/cobranca-cartao/index';
import { Route as ACobrancaBoletoIndexImport } from './routes/a/cobranca-boleto/index';
import { Route as AClienteIndexImport } from './routes/a/cliente/index';
import { Route as PImpressaoFiletypeImport } from './routes/p/impressao.$filetype';
import { Route as PContratoIdImport } from './routes/p/contrato.$id';
import { Route as PCompartilhamentoIdImport } from './routes/p/compartilhamento/$id';
import { Route as PClienteSlugImport } from './routes/p/cliente/$slug';
import { Route as AdminAuthLoginImport } from './routes/admin/auth/login';
import { Route as AdminASqlImport } from './routes/admin/_a/sql';
import { Route as AdminARepresentanteImport } from './routes/admin/_a/representante';
import { Route as AdminAErroImport } from './routes/admin/_a/erro';
import { Route as AdminAEmpresaImport } from './routes/admin/_a/empresa';
import { Route as ASuporteInstrucoesDeUsoImport } from './routes/a/suporte/instrucoes-de-uso';
import { Route as APagforListagemImport } from './routes/a/pagfor/listagem';
import { Route as APagforImportarImport } from './routes/a/pagfor/importar';
import { Route as ANotificacaoListagemImport } from './routes/a/notificacao/listagem';
import { Route as AImpressaoFiletypeImport } from './routes/a/impressao/$filetype';
import { Route as AEstoqueMovimentacaoImport } from './routes/a/estoque/movimentacao';
import { Route as AEstoqueConferenciaImport } from './routes/a/estoque/conferencia';
import { Route as AConfiguracaoUsuarioImport } from './routes/a/configuracao/usuario';
import { Route as ACobrancaBoletoRotinasBancariasImport } from './routes/a/cobranca-boleto/rotinas-bancarias';
import { Route as PCatalogoSlugRouteImport } from './routes/p/catalogo.$slug/route';
import { Route as AConfiguracaoEmpresaRouteImport } from './routes/a/configuracao/empresa/route';
import { Route as PPagamentoIdIndexImport } from './routes/p/pagamento.$id/index';
import { Route as PCompartilhamentoIdIndexImport } from './routes/p/compartilhamento/$id.index';
import { Route as PCatalogoSlugIndexImport } from './routes/p/catalogo.$slug/index';
import { Route as AVendaTipoIndexImport } from './routes/a/venda/$tipo/index';
import { Route as ARelatorioTipoIndexImport } from './routes/a/relatorio/$tipo/index';
import { Route as AEstoqueInventarioIndexImport } from './routes/a/estoque/inventario/index';
import { Route as AConfiguracaoEmpresaIndexImport } from './routes/a/configuracao/empresa/index';
import { Route as PContaDigitalTermosDeUsoBase64Import } from './routes/p/conta-digital/termos-de-uso.$base64';
import { Route as PCatalogoSlugListagemImport } from './routes/p/catalogo.$slug/listagem';
import { Route as PCatalogoSlugFinalizarImport } from './routes/p/catalogo.$slug/finalizar';
import { Route as PCatalogoSlugCarrinhoImport } from './routes/p/catalogo.$slug/carrinho';
import { Route as AdminAConfiguracaoRepresentanteImport } from './routes/admin/_a/configuracao.representante';
import { Route as AVendaExternaModeIdpkImport } from './routes/a/venda-externa/$mode.$idpk';
import { Route as ARomaneioModeIdpkImport } from './routes/a/romaneio/$mode.$idpk';
import { Route as APagforModeIdpkImport } from './routes/a/pagfor/$mode.$idpk';
import { Route as ANotaFiscalModeIdpkImport } from './routes/a/nota-fiscal/$mode.$idpk';
import { Route as AMovimentacoesExtratoIdpkImport } from './routes/a/movimentacoes/extrato.$idpk';
import { Route as AManifestoCargaModeIdpkImport } from './routes/a/manifesto-carga/$mode.$idpk';
import { Route as AImportacaoXmlTipoImport } from './routes/a/importacao/xml.$tipo';
import { Route as AImportacaoCadastroTipoImport } from './routes/a/importacao/cadastro.$tipo';
import { Route as AEstoqueInventarioNovoImport } from './routes/a/estoque/inventario/novo';
import { Route as AContratoModeIdpkImport } from './routes/a/contrato/$mode.$idpk';
import { Route as AContasReceberModeIdpkImport } from './routes/a/contas-receber/$mode.$idpk';
import { Route as AContasPagarModeIdpkImport } from './routes/a/contas-pagar/$mode.$idpk';
import { Route as ACompraModeIdpkImport } from './routes/a/compra/$mode.$idpk';
import { Route as ACobrancaCartaoModeIdpkImport } from './routes/a/cobranca-cartao/$mode.$idpk';
import { Route as ACobrancaBoletoModeIdpkImport } from './routes/a/cobranca-boleto/$mode.$idpk';
import { Route as AClienteModeIdpkImport } from './routes/a/cliente/$mode.$idpk';
import { Route as PCatalogoSlugProdutoIdpkImport } from './routes/p/catalogo.$slug/produto.$idpk';
import { Route as AVendaTipoModeIdpkImport } from './routes/a/venda/$tipo/$mode.$idpk';
import { Route as AMovimentacoesModeContaIdpkIdpkImport } from './routes/a/movimentacoes/$mode.$contaIdpk.$idpk';
import { Route as AEstoqueInventarioEditarIdpkImport } from './routes/a/estoque/inventario/editar.$idpk';
import { Route as ANotaFiscalVendaExternaTipoVendaExternaModeVeeIdpkImport } from './routes/a/nota-fiscal/venda-externa/$tipoVendaExterna.$mode.$veeIdpk';

// Create Virtual Routes

const AdminImport = createFileRoute('/admin')();

// Create/Update Routes

const AdminRoute = AdminImport.update({
  id: '/admin',
  path: '/admin',
  getParentRoute: () => rootRoute,
} as any);

const AuthRouteRoute = AuthRouteImport.update({
  id: '/auth',
  path: '/auth',
  getParentRoute: () => rootRoute,
} as any);

const ARouteRoute = ARouteImport.update({
  id: '/a',
  path: '/a',
  getParentRoute: () => rootRoute,
} as any);

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any);

const PErroRoute = PErroImport.update({
  id: '/p/erro',
  path: '/p/erro',
  getParentRoute: () => rootRoute,
} as any);

const AuthSolicitarValidacaoRoute = AuthSolicitarValidacaoImport.update({
  id: '/solicitar-validacao',
  path: '/solicitar-validacao',
  getParentRoute: () => AuthRouteRoute,
} as any);

const AuthRedefinirSenhaRoute = AuthRedefinirSenhaImport.update({
  id: '/redefinir-senha',
  path: '/redefinir-senha',
  getParentRoute: () => AuthRouteRoute,
} as any);

const AuthRecuperarSenhaRoute = AuthRecuperarSenhaImport.update({
  id: '/recuperar-senha',
  path: '/recuperar-senha',
  getParentRoute: () => AuthRouteRoute,
} as any);

const AuthLoginRoute = AuthLoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => AuthRouteRoute,
} as any);

const AuthContaBloqueadaRoute = AuthContaBloqueadaImport.update({
  id: '/conta-bloqueada',
  path: '/conta-bloqueada',
  getParentRoute: () => AuthRouteRoute,
} as any);

const AuthCadastroFimRoute = AuthCadastroFimImport.update({
  id: '/cadastro-fim',
  path: '/cadastro-fim',
  getParentRoute: () => AuthRouteRoute,
} as any);

const AuthCadastroRoute = AuthCadastroImport.update({
  id: '/cadastro',
  path: '/cadastro',
  getParentRoute: () => AuthRouteRoute,
} as any);

const AuthAcessoNegadoRoute = AuthAcessoNegadoImport.update({
  id: '/acesso-negado',
  path: '/acesso-negado',
  getParentRoute: () => AuthRouteRoute,
} as any);

const AdminAuthRoute = AdminAuthImport.update({
  id: '/auth',
  path: '/auth',
  getParentRoute: () => AdminRoute,
} as any);

const APdvRoute = APdvImport.update({
  id: '/pdv',
  path: '/pdv',
  getParentRoute: () => ARouteRoute,
} as any);

const AdminARouteRoute = AdminARouteImport.update({
  id: '/_a',
  getParentRoute: () => AdminRoute,
} as any);

const AVendaExternaRouteRoute = AVendaExternaRouteImport.update({
  id: '/venda-externa',
  path: '/venda-externa',
  getParentRoute: () => ARouteRoute,
} as any);

const AVendaRouteRoute = AVendaRouteImport.update({
  id: '/venda',
  path: '/venda',
  getParentRoute: () => ARouteRoute,
} as any);

const ATransportadorRouteRoute = ATransportadorRouteImport.update({
  id: '/transportador',
  path: '/transportador',
  getParentRoute: () => ARouteRoute,
} as any);

const ARomaneioRouteRoute = ARomaneioRouteImport.update({
  id: '/romaneio',
  path: '/romaneio',
  getParentRoute: () => ARouteRoute,
} as any);

const ARegraFiscalRouteRoute = ARegraFiscalRouteImport.update({
  id: '/regra-fiscal',
  path: '/regra-fiscal',
  getParentRoute: () => ARouteRoute,
} as any);

const AProdutoRouteRoute = AProdutoRouteImport.update({
  id: '/produto',
  path: '/produto',
  getParentRoute: () => ARouteRoute,
} as any);

const APagforRouteRoute = APagforRouteImport.update({
  id: '/pagfor',
  path: '/pagfor',
  getParentRoute: () => ARouteRoute,
} as any);

const ANotificacaoRouteRoute = ANotificacaoRouteImport.update({
  id: '/notificacao',
  path: '/notificacao',
  getParentRoute: () => ARouteRoute,
} as any);

const ANotaFiscalRouteRoute = ANotaFiscalRouteImport.update({
  id: '/nota-fiscal',
  path: '/nota-fiscal',
  getParentRoute: () => ARouteRoute,
} as any);

const AMovimentacoesRouteRoute = AMovimentacoesRouteImport.update({
  id: '/movimentacoes',
  path: '/movimentacoes',
  getParentRoute: () => ARouteRoute,
} as any);

const AManifestoCargaRouteRoute = AManifestoCargaRouteImport.update({
  id: '/manifesto-carga',
  path: '/manifesto-carga',
  getParentRoute: () => ARouteRoute,
} as any);

const AFornecedorRouteRoute = AFornecedorRouteImport.update({
  id: '/fornecedor',
  path: '/fornecedor',
  getParentRoute: () => ARouteRoute,
} as any);

const AExtratoPixRouteRoute = AExtratoPixRouteImport.update({
  id: '/extrato-pix',
  path: '/extrato-pix',
  getParentRoute: () => ARouteRoute,
} as any);

const AExtratoBoletoRouteRoute = AExtratoBoletoRouteImport.update({
  id: '/extrato-boleto',
  path: '/extrato-boleto',
  getParentRoute: () => ARouteRoute,
} as any);

const AEstoqueRouteRoute = AEstoqueRouteImport.update({
  id: '/estoque',
  path: '/estoque',
  getParentRoute: () => ARouteRoute,
} as any);

const ADebugRouteRoute = ADebugRouteImport.update({
  id: '/debug',
  path: '/debug',
  getParentRoute: () => ARouteRoute,
} as any);

const ACupomFiscalRouteRoute = ACupomFiscalRouteImport.update({
  id: '/cupom-fiscal',
  path: '/cupom-fiscal',
  getParentRoute: () => ARouteRoute,
} as any);

const AContasReceberRouteRoute = AContasReceberRouteImport.update({
  id: '/contas-receber',
  path: '/contas-receber',
  getParentRoute: () => ARouteRoute,
} as any);

const AContasPagarRouteRoute = AContasPagarRouteImport.update({
  id: '/contas-pagar',
  path: '/contas-pagar',
  getParentRoute: () => ARouteRoute,
} as any);

const ACompraRouteRoute = ACompraRouteImport.update({
  id: '/compra',
  path: '/compra',
  getParentRoute: () => ARouteRoute,
} as any);

const AComissaoRouteRoute = AComissaoRouteImport.update({
  id: '/comissao',
  path: '/comissao',
  getParentRoute: () => ARouteRoute,
} as any);

const ACobrancaPixRouteRoute = ACobrancaPixRouteImport.update({
  id: '/cobranca-pix',
  path: '/cobranca-pix',
  getParentRoute: () => ARouteRoute,
} as any);

const ACobrancaCartaoRouteRoute = ACobrancaCartaoRouteImport.update({
  id: '/cobranca-cartao',
  path: '/cobranca-cartao',
  getParentRoute: () => ARouteRoute,
} as any);

const ACobrancaBoletoRouteRoute = ACobrancaBoletoRouteImport.update({
  id: '/cobranca-boleto',
  path: '/cobranca-boleto',
  getParentRoute: () => ARouteRoute,
} as any);

const AClienteRouteRoute = AClienteRouteImport.update({
  id: '/cliente',
  path: '/cliente',
  getParentRoute: () => ARouteRoute,
} as any);

const AVendaExternaIndexRoute = AVendaExternaIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AVendaExternaRouteRoute,
} as any);

const ATransportadorIndexRoute = ATransportadorIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => ATransportadorRouteRoute,
} as any);

const ASuporteIndexRoute = ASuporteIndexImport.update({
  id: '/suporte/',
  path: '/suporte/',
  getParentRoute: () => ARouteRoute,
} as any);

const ARomaneioIndexRoute = ARomaneioIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => ARomaneioRouteRoute,
} as any);

const ARegraFiscalIndexRoute = ARegraFiscalIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => ARegraFiscalRouteRoute,
} as any);

const AProdutoIndexRoute = AProdutoIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AProdutoRouteRoute,
} as any);

const APainelGerencialIndexRoute = APainelGerencialIndexImport.update({
  id: '/painel-gerencial/',
  path: '/painel-gerencial/',
  getParentRoute: () => ARouteRoute,
} as any);

const ANotaFiscalIndexRoute = ANotaFiscalIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => ANotaFiscalRouteRoute,
} as any);

const AMovimentacoesIndexRoute = AMovimentacoesIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AMovimentacoesRouteRoute,
} as any);

const AManifestoCargaIndexRoute = AManifestoCargaIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AManifestoCargaRouteRoute,
} as any);

const AFornecedorIndexRoute = AFornecedorIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AFornecedorRouteRoute,
} as any);

const AExtratoPixIndexRoute = AExtratoPixIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AExtratoPixRouteRoute,
} as any);

const AExtratoBoletoIndexRoute = AExtratoBoletoIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AExtratoBoletoRouteRoute,
} as any);

const ADebugIndexRoute = ADebugIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => ADebugRouteRoute,
} as any);

const ACupomFiscalIndexRoute = ACupomFiscalIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => ACupomFiscalRouteRoute,
} as any);

const AContratoIndexRoute = AContratoIndexImport.update({
  id: '/contrato/',
  path: '/contrato/',
  getParentRoute: () => ARouteRoute,
} as any);

const AContasReceberIndexRoute = AContasReceberIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AContasReceberRouteRoute,
} as any);

const AContasPagarIndexRoute = AContasPagarIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AContasPagarRouteRoute,
} as any);

const ACompraIndexRoute = ACompraIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => ACompraRouteRoute,
} as any);

const AComissaoIndexRoute = AComissaoIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AComissaoRouteRoute,
} as any);

const ACobrancaPixIndexRoute = ACobrancaPixIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => ACobrancaPixRouteRoute,
} as any);

const ACobrancaCartaoIndexRoute = ACobrancaCartaoIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => ACobrancaCartaoRouteRoute,
} as any);

const ACobrancaBoletoIndexRoute = ACobrancaBoletoIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => ACobrancaBoletoRouteRoute,
} as any);

const AClienteIndexRoute = AClienteIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AClienteRouteRoute,
} as any);

const PImpressaoFiletypeRoute = PImpressaoFiletypeImport.update({
  id: '/p/impressao/$filetype',
  path: '/p/impressao/$filetype',
  getParentRoute: () => rootRoute,
} as any);

const PContratoIdRoute = PContratoIdImport.update({
  id: '/p/contrato/$id',
  path: '/p/contrato/$id',
  getParentRoute: () => rootRoute,
} as any);

const PCompartilhamentoIdRoute = PCompartilhamentoIdImport.update({
  id: '/p/compartilhamento/$id',
  path: '/p/compartilhamento/$id',
  getParentRoute: () => rootRoute,
} as any);

const PClienteSlugRoute = PClienteSlugImport.update({
  id: '/p/cliente/$slug',
  path: '/p/cliente/$slug',
  getParentRoute: () => rootRoute,
} as any);

const AdminAuthLoginRoute = AdminAuthLoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => AdminAuthRoute,
} as any);

const AdminASqlRoute = AdminASqlImport.update({
  id: '/sql',
  path: '/sql',
  getParentRoute: () => AdminARouteRoute,
} as any);

const AdminARepresentanteRoute = AdminARepresentanteImport.update({
  id: '/representante',
  path: '/representante',
  getParentRoute: () => AdminARouteRoute,
} as any);

const AdminAErroRoute = AdminAErroImport.update({
  id: '/erro',
  path: '/erro',
  getParentRoute: () => AdminARouteRoute,
} as any);

const AdminAEmpresaRoute = AdminAEmpresaImport.update({
  id: '/empresa',
  path: '/empresa',
  getParentRoute: () => AdminARouteRoute,
} as any);

const ASuporteInstrucoesDeUsoRoute = ASuporteInstrucoesDeUsoImport.update({
  id: '/suporte/instrucoes-de-uso',
  path: '/suporte/instrucoes-de-uso',
  getParentRoute: () => ARouteRoute,
} as any);

const APagforListagemRoute = APagforListagemImport.update({
  id: '/listagem',
  path: '/listagem',
  getParentRoute: () => APagforRouteRoute,
} as any);

const APagforImportarRoute = APagforImportarImport.update({
  id: '/importar',
  path: '/importar',
  getParentRoute: () => APagforRouteRoute,
} as any);

const ANotificacaoListagemRoute = ANotificacaoListagemImport.update({
  id: '/listagem',
  path: '/listagem',
  getParentRoute: () => ANotificacaoRouteRoute,
} as any);

const AImpressaoFiletypeRoute = AImpressaoFiletypeImport.update({
  id: '/impressao/$filetype',
  path: '/impressao/$filetype',
  getParentRoute: () => ARouteRoute,
} as any);

const AEstoqueMovimentacaoRoute = AEstoqueMovimentacaoImport.update({
  id: '/movimentacao',
  path: '/movimentacao',
  getParentRoute: () => AEstoqueRouteRoute,
} as any);

const AEstoqueConferenciaRoute = AEstoqueConferenciaImport.update({
  id: '/conferencia',
  path: '/conferencia',
  getParentRoute: () => AEstoqueRouteRoute,
} as any);

const AConfiguracaoUsuarioRoute = AConfiguracaoUsuarioImport.update({
  id: '/configuracao/usuario',
  path: '/configuracao/usuario',
  getParentRoute: () => ARouteRoute,
} as any);

const ACobrancaBoletoRotinasBancariasRoute =
  ACobrancaBoletoRotinasBancariasImport.update({
    id: '/rotinas-bancarias',
    path: '/rotinas-bancarias',
    getParentRoute: () => ACobrancaBoletoRouteRoute,
  } as any);

const PCatalogoSlugRouteRoute = PCatalogoSlugRouteImport.update({
  id: '/p/catalogo/$slug',
  path: '/p/catalogo/$slug',
  getParentRoute: () => rootRoute,
} as any);

const AConfiguracaoEmpresaRouteRoute = AConfiguracaoEmpresaRouteImport.update({
  id: '/configuracao/empresa',
  path: '/configuracao/empresa',
  getParentRoute: () => ARouteRoute,
} as any);

const PPagamentoIdIndexRoute = PPagamentoIdIndexImport.update({
  id: '/p/pagamento/$id/',
  path: '/p/pagamento/$id/',
  getParentRoute: () => rootRoute,
} as any);

const PCompartilhamentoIdIndexRoute = PCompartilhamentoIdIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => PCompartilhamentoIdRoute,
} as any);

const PCatalogoSlugIndexRoute = PCatalogoSlugIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => PCatalogoSlugRouteRoute,
} as any);

const AVendaTipoIndexRoute = AVendaTipoIndexImport.update({
  id: '/$tipo/',
  path: '/$tipo/',
  getParentRoute: () => AVendaRouteRoute,
} as any);

const ARelatorioTipoIndexRoute = ARelatorioTipoIndexImport.update({
  id: '/relatorio/$tipo/',
  path: '/relatorio/$tipo/',
  getParentRoute: () => ARouteRoute,
} as any);

const AEstoqueInventarioIndexRoute = AEstoqueInventarioIndexImport.update({
  id: '/inventario/',
  path: '/inventario/',
  getParentRoute: () => AEstoqueRouteRoute,
} as any);

const AConfiguracaoEmpresaIndexRoute = AConfiguracaoEmpresaIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AConfiguracaoEmpresaRouteRoute,
} as any);

const PContaDigitalTermosDeUsoBase64Route =
  PContaDigitalTermosDeUsoBase64Import.update({
    id: '/p/conta-digital/termos-de-uso/$base64',
    path: '/p/conta-digital/termos-de-uso/$base64',
    getParentRoute: () => rootRoute,
  } as any);

const PCatalogoSlugListagemRoute = PCatalogoSlugListagemImport.update({
  id: '/listagem',
  path: '/listagem',
  getParentRoute: () => PCatalogoSlugRouteRoute,
} as any);

const PCatalogoSlugFinalizarRoute = PCatalogoSlugFinalizarImport.update({
  id: '/finalizar',
  path: '/finalizar',
  getParentRoute: () => PCatalogoSlugRouteRoute,
} as any);

const PCatalogoSlugCarrinhoRoute = PCatalogoSlugCarrinhoImport.update({
  id: '/carrinho',
  path: '/carrinho',
  getParentRoute: () => PCatalogoSlugRouteRoute,
} as any);

const AdminAConfiguracaoRepresentanteRoute =
  AdminAConfiguracaoRepresentanteImport.update({
    id: '/configuracao/representante',
    path: '/configuracao/representante',
    getParentRoute: () => AdminARouteRoute,
  } as any);

const AVendaExternaModeIdpkRoute = AVendaExternaModeIdpkImport.update({
  id: '/$mode/$idpk',
  path: '/$mode/$idpk',
  getParentRoute: () => AVendaExternaRouteRoute,
} as any);

const ARomaneioModeIdpkRoute = ARomaneioModeIdpkImport.update({
  id: '/$mode/$idpk',
  path: '/$mode/$idpk',
  getParentRoute: () => ARomaneioRouteRoute,
} as any);

const APagforModeIdpkRoute = APagforModeIdpkImport.update({
  id: '/$mode/$idpk',
  path: '/$mode/$idpk',
  getParentRoute: () => APagforRouteRoute,
} as any);

const ANotaFiscalModeIdpkRoute = ANotaFiscalModeIdpkImport.update({
  id: '/$mode/$idpk',
  path: '/$mode/$idpk',
  getParentRoute: () => ANotaFiscalRouteRoute,
} as any);

const AMovimentacoesExtratoIdpkRoute = AMovimentacoesExtratoIdpkImport.update({
  id: '/extrato/$idpk',
  path: '/extrato/$idpk',
  getParentRoute: () => AMovimentacoesRouteRoute,
} as any);

const AManifestoCargaModeIdpkRoute = AManifestoCargaModeIdpkImport.update({
  id: '/$mode/$idpk',
  path: '/$mode/$idpk',
  getParentRoute: () => AManifestoCargaRouteRoute,
} as any);

const AImportacaoXmlTipoRoute = AImportacaoXmlTipoImport.update({
  id: '/importacao/xml/$tipo',
  path: '/importacao/xml/$tipo',
  getParentRoute: () => ARouteRoute,
} as any);

const AImportacaoCadastroTipoRoute = AImportacaoCadastroTipoImport.update({
  id: '/importacao/cadastro/$tipo',
  path: '/importacao/cadastro/$tipo',
  getParentRoute: () => ARouteRoute,
} as any);

const AEstoqueInventarioNovoRoute = AEstoqueInventarioNovoImport.update({
  id: '/inventario/novo',
  path: '/inventario/novo',
  getParentRoute: () => AEstoqueRouteRoute,
} as any);

const AContratoModeIdpkRoute = AContratoModeIdpkImport.update({
  id: '/contrato/$mode/$idpk',
  path: '/contrato/$mode/$idpk',
  getParentRoute: () => ARouteRoute,
} as any);

const AContasReceberModeIdpkRoute = AContasReceberModeIdpkImport.update({
  id: '/$mode/$idpk',
  path: '/$mode/$idpk',
  getParentRoute: () => AContasReceberRouteRoute,
} as any);

const AContasPagarModeIdpkRoute = AContasPagarModeIdpkImport.update({
  id: '/$mode/$idpk',
  path: '/$mode/$idpk',
  getParentRoute: () => AContasPagarRouteRoute,
} as any);

const ACompraModeIdpkRoute = ACompraModeIdpkImport.update({
  id: '/$mode/$idpk',
  path: '/$mode/$idpk',
  getParentRoute: () => ACompraRouteRoute,
} as any);

const ACobrancaCartaoModeIdpkRoute = ACobrancaCartaoModeIdpkImport.update({
  id: '/$mode/$idpk',
  path: '/$mode/$idpk',
  getParentRoute: () => ACobrancaCartaoRouteRoute,
} as any);

const ACobrancaBoletoModeIdpkRoute = ACobrancaBoletoModeIdpkImport.update({
  id: '/$mode/$idpk',
  path: '/$mode/$idpk',
  getParentRoute: () => ACobrancaBoletoRouteRoute,
} as any);

const AClienteModeIdpkRoute = AClienteModeIdpkImport.update({
  id: '/$mode/$idpk',
  path: '/$mode/$idpk',
  getParentRoute: () => AClienteRouteRoute,
} as any);

const PCatalogoSlugProdutoIdpkRoute = PCatalogoSlugProdutoIdpkImport.update({
  id: '/produto/$idpk',
  path: '/produto/$idpk',
  getParentRoute: () => PCatalogoSlugRouteRoute,
} as any);

const AVendaTipoModeIdpkRoute = AVendaTipoModeIdpkImport.update({
  id: '/$tipo/$mode/$idpk',
  path: '/$tipo/$mode/$idpk',
  getParentRoute: () => AVendaRouteRoute,
} as any);

const AMovimentacoesModeContaIdpkIdpkRoute =
  AMovimentacoesModeContaIdpkIdpkImport.update({
    id: '/$mode/$contaIdpk/$idpk',
    path: '/$mode/$contaIdpk/$idpk',
    getParentRoute: () => AMovimentacoesRouteRoute,
  } as any);

const AEstoqueInventarioEditarIdpkRoute =
  AEstoqueInventarioEditarIdpkImport.update({
    id: '/inventario/editar/$idpk',
    path: '/inventario/editar/$idpk',
    getParentRoute: () => AEstoqueRouteRoute,
  } as any);

const ANotaFiscalVendaExternaTipoVendaExternaModeVeeIdpkRoute =
  ANotaFiscalVendaExternaTipoVendaExternaModeVeeIdpkImport.update({
    id: '/venda-externa/$tipoVendaExterna/$mode/$veeIdpk',
    path: '/venda-externa/$tipoVendaExterna/$mode/$veeIdpk',
    getParentRoute: () => ANotaFiscalRouteRoute,
  } as any);

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/';
      path: '/';
      fullPath: '/';
      preLoaderRoute: typeof IndexImport;
      parentRoute: typeof rootRoute;
    };
    '/a': {
      id: '/a';
      path: '/a';
      fullPath: '/a';
      preLoaderRoute: typeof ARouteImport;
      parentRoute: typeof rootRoute;
    };
    '/auth': {
      id: '/auth';
      path: '/auth';
      fullPath: '/auth';
      preLoaderRoute: typeof AuthRouteImport;
      parentRoute: typeof rootRoute;
    };
    '/a/cliente': {
      id: '/a/cliente';
      path: '/cliente';
      fullPath: '/a/cliente';
      preLoaderRoute: typeof AClienteRouteImport;
      parentRoute: typeof ARouteImport;
    };
    '/a/cobranca-boleto': {
      id: '/a/cobranca-boleto';
      path: '/cobranca-boleto';
      fullPath: '/a/cobranca-boleto';
      preLoaderRoute: typeof ACobrancaBoletoRouteImport;
      parentRoute: typeof ARouteImport;
    };
    '/a/cobranca-cartao': {
      id: '/a/cobranca-cartao';
      path: '/cobranca-cartao';
      fullPath: '/a/cobranca-cartao';
      preLoaderRoute: typeof ACobrancaCartaoRouteImport;
      parentRoute: typeof ARouteImport;
    };
    '/a/cobranca-pix': {
      id: '/a/cobranca-pix';
      path: '/cobranca-pix';
      fullPath: '/a/cobranca-pix';
      preLoaderRoute: typeof ACobrancaPixRouteImport;
      parentRoute: typeof ARouteImport;
    };
    '/a/comissao': {
      id: '/a/comissao';
      path: '/comissao';
      fullPath: '/a/comissao';
      preLoaderRoute: typeof AComissaoRouteImport;
      parentRoute: typeof ARouteImport;
    };
    '/a/compra': {
      id: '/a/compra';
      path: '/compra';
      fullPath: '/a/compra';
      preLoaderRoute: typeof ACompraRouteImport;
      parentRoute: typeof ARouteImport;
    };
    '/a/contas-pagar': {
      id: '/a/contas-pagar';
      path: '/contas-pagar';
      fullPath: '/a/contas-pagar';
      preLoaderRoute: typeof AContasPagarRouteImport;
      parentRoute: typeof ARouteImport;
    };
    '/a/contas-receber': {
      id: '/a/contas-receber';
      path: '/contas-receber';
      fullPath: '/a/contas-receber';
      preLoaderRoute: typeof AContasReceberRouteImport;
      parentRoute: typeof ARouteImport;
    };
    '/a/cupom-fiscal': {
      id: '/a/cupom-fiscal';
      path: '/cupom-fiscal';
      fullPath: '/a/cupom-fiscal';
      preLoaderRoute: typeof ACupomFiscalRouteImport;
      parentRoute: typeof ARouteImport;
    };
    '/a/debug': {
      id: '/a/debug';
      path: '/debug';
      fullPath: '/a/debug';
      preLoaderRoute: typeof ADebugRouteImport;
      parentRoute: typeof ARouteImport;
    };
    '/a/estoque': {
      id: '/a/estoque';
      path: '/estoque';
      fullPath: '/a/estoque';
      preLoaderRoute: typeof AEstoqueRouteImport;
      parentRoute: typeof ARouteImport;
    };
    '/a/extrato-boleto': {
      id: '/a/extrato-boleto';
      path: '/extrato-boleto';
      fullPath: '/a/extrato-boleto';
      preLoaderRoute: typeof AExtratoBoletoRouteImport;
      parentRoute: typeof ARouteImport;
    };
    '/a/extrato-pix': {
      id: '/a/extrato-pix';
      path: '/extrato-pix';
      fullPath: '/a/extrato-pix';
      preLoaderRoute: typeof AExtratoPixRouteImport;
      parentRoute: typeof ARouteImport;
    };
    '/a/fornecedor': {
      id: '/a/fornecedor';
      path: '/fornecedor';
      fullPath: '/a/fornecedor';
      preLoaderRoute: typeof AFornecedorRouteImport;
      parentRoute: typeof ARouteImport;
    };
    '/a/manifesto-carga': {
      id: '/a/manifesto-carga';
      path: '/manifesto-carga';
      fullPath: '/a/manifesto-carga';
      preLoaderRoute: typeof AManifestoCargaRouteImport;
      parentRoute: typeof ARouteImport;
    };
    '/a/movimentacoes': {
      id: '/a/movimentacoes';
      path: '/movimentacoes';
      fullPath: '/a/movimentacoes';
      preLoaderRoute: typeof AMovimentacoesRouteImport;
      parentRoute: typeof ARouteImport;
    };
    '/a/nota-fiscal': {
      id: '/a/nota-fiscal';
      path: '/nota-fiscal';
      fullPath: '/a/nota-fiscal';
      preLoaderRoute: typeof ANotaFiscalRouteImport;
      parentRoute: typeof ARouteImport;
    };
    '/a/notificacao': {
      id: '/a/notificacao';
      path: '/notificacao';
      fullPath: '/a/notificacao';
      preLoaderRoute: typeof ANotificacaoRouteImport;
      parentRoute: typeof ARouteImport;
    };
    '/a/pagfor': {
      id: '/a/pagfor';
      path: '/pagfor';
      fullPath: '/a/pagfor';
      preLoaderRoute: typeof APagforRouteImport;
      parentRoute: typeof ARouteImport;
    };
    '/a/produto': {
      id: '/a/produto';
      path: '/produto';
      fullPath: '/a/produto';
      preLoaderRoute: typeof AProdutoRouteImport;
      parentRoute: typeof ARouteImport;
    };
    '/a/regra-fiscal': {
      id: '/a/regra-fiscal';
      path: '/regra-fiscal';
      fullPath: '/a/regra-fiscal';
      preLoaderRoute: typeof ARegraFiscalRouteImport;
      parentRoute: typeof ARouteImport;
    };
    '/a/romaneio': {
      id: '/a/romaneio';
      path: '/romaneio';
      fullPath: '/a/romaneio';
      preLoaderRoute: typeof ARomaneioRouteImport;
      parentRoute: typeof ARouteImport;
    };
    '/a/transportador': {
      id: '/a/transportador';
      path: '/transportador';
      fullPath: '/a/transportador';
      preLoaderRoute: typeof ATransportadorRouteImport;
      parentRoute: typeof ARouteImport;
    };
    '/a/venda': {
      id: '/a/venda';
      path: '/venda';
      fullPath: '/a/venda';
      preLoaderRoute: typeof AVendaRouteImport;
      parentRoute: typeof ARouteImport;
    };
    '/a/venda-externa': {
      id: '/a/venda-externa';
      path: '/venda-externa';
      fullPath: '/a/venda-externa';
      preLoaderRoute: typeof AVendaExternaRouteImport;
      parentRoute: typeof ARouteImport;
    };
    '/admin': {
      id: '/admin';
      path: '/admin';
      fullPath: '/admin';
      preLoaderRoute: typeof AdminImport;
      parentRoute: typeof rootRoute;
    };
    '/admin/_a': {
      id: '/admin/_a';
      path: '/admin';
      fullPath: '/admin';
      preLoaderRoute: typeof AdminARouteImport;
      parentRoute: typeof AdminRoute;
    };
    '/a/pdv': {
      id: '/a/pdv';
      path: '/pdv';
      fullPath: '/a/pdv';
      preLoaderRoute: typeof APdvImport;
      parentRoute: typeof ARouteImport;
    };
    '/admin/auth': {
      id: '/admin/auth';
      path: '/auth';
      fullPath: '/admin/auth';
      preLoaderRoute: typeof AdminAuthImport;
      parentRoute: typeof AdminImport;
    };
    '/auth/acesso-negado': {
      id: '/auth/acesso-negado';
      path: '/acesso-negado';
      fullPath: '/auth/acesso-negado';
      preLoaderRoute: typeof AuthAcessoNegadoImport;
      parentRoute: typeof AuthRouteImport;
    };
    '/auth/cadastro': {
      id: '/auth/cadastro';
      path: '/cadastro';
      fullPath: '/auth/cadastro';
      preLoaderRoute: typeof AuthCadastroImport;
      parentRoute: typeof AuthRouteImport;
    };
    '/auth/cadastro-fim': {
      id: '/auth/cadastro-fim';
      path: '/cadastro-fim';
      fullPath: '/auth/cadastro-fim';
      preLoaderRoute: typeof AuthCadastroFimImport;
      parentRoute: typeof AuthRouteImport;
    };
    '/auth/conta-bloqueada': {
      id: '/auth/conta-bloqueada';
      path: '/conta-bloqueada';
      fullPath: '/auth/conta-bloqueada';
      preLoaderRoute: typeof AuthContaBloqueadaImport;
      parentRoute: typeof AuthRouteImport;
    };
    '/auth/login': {
      id: '/auth/login';
      path: '/login';
      fullPath: '/auth/login';
      preLoaderRoute: typeof AuthLoginImport;
      parentRoute: typeof AuthRouteImport;
    };
    '/auth/recuperar-senha': {
      id: '/auth/recuperar-senha';
      path: '/recuperar-senha';
      fullPath: '/auth/recuperar-senha';
      preLoaderRoute: typeof AuthRecuperarSenhaImport;
      parentRoute: typeof AuthRouteImport;
    };
    '/auth/redefinir-senha': {
      id: '/auth/redefinir-senha';
      path: '/redefinir-senha';
      fullPath: '/auth/redefinir-senha';
      preLoaderRoute: typeof AuthRedefinirSenhaImport;
      parentRoute: typeof AuthRouteImport;
    };
    '/auth/solicitar-validacao': {
      id: '/auth/solicitar-validacao';
      path: '/solicitar-validacao';
      fullPath: '/auth/solicitar-validacao';
      preLoaderRoute: typeof AuthSolicitarValidacaoImport;
      parentRoute: typeof AuthRouteImport;
    };
    '/p/erro': {
      id: '/p/erro';
      path: '/p/erro';
      fullPath: '/p/erro';
      preLoaderRoute: typeof PErroImport;
      parentRoute: typeof rootRoute;
    };
    '/a/configuracao/empresa': {
      id: '/a/configuracao/empresa';
      path: '/configuracao/empresa';
      fullPath: '/a/configuracao/empresa';
      preLoaderRoute: typeof AConfiguracaoEmpresaRouteImport;
      parentRoute: typeof ARouteImport;
    };
    '/p/catalogo/$slug': {
      id: '/p/catalogo/$slug';
      path: '/p/catalogo/$slug';
      fullPath: '/p/catalogo/$slug';
      preLoaderRoute: typeof PCatalogoSlugRouteImport;
      parentRoute: typeof rootRoute;
    };
    '/a/cobranca-boleto/rotinas-bancarias': {
      id: '/a/cobranca-boleto/rotinas-bancarias';
      path: '/rotinas-bancarias';
      fullPath: '/a/cobranca-boleto/rotinas-bancarias';
      preLoaderRoute: typeof ACobrancaBoletoRotinasBancariasImport;
      parentRoute: typeof ACobrancaBoletoRouteImport;
    };
    '/a/configuracao/usuario': {
      id: '/a/configuracao/usuario';
      path: '/configuracao/usuario';
      fullPath: '/a/configuracao/usuario';
      preLoaderRoute: typeof AConfiguracaoUsuarioImport;
      parentRoute: typeof ARouteImport;
    };
    '/a/estoque/conferencia': {
      id: '/a/estoque/conferencia';
      path: '/conferencia';
      fullPath: '/a/estoque/conferencia';
      preLoaderRoute: typeof AEstoqueConferenciaImport;
      parentRoute: typeof AEstoqueRouteImport;
    };
    '/a/estoque/movimentacao': {
      id: '/a/estoque/movimentacao';
      path: '/movimentacao';
      fullPath: '/a/estoque/movimentacao';
      preLoaderRoute: typeof AEstoqueMovimentacaoImport;
      parentRoute: typeof AEstoqueRouteImport;
    };
    '/a/impressao/$filetype': {
      id: '/a/impressao/$filetype';
      path: '/impressao/$filetype';
      fullPath: '/a/impressao/$filetype';
      preLoaderRoute: typeof AImpressaoFiletypeImport;
      parentRoute: typeof ARouteImport;
    };
    '/a/notificacao/listagem': {
      id: '/a/notificacao/listagem';
      path: '/listagem';
      fullPath: '/a/notificacao/listagem';
      preLoaderRoute: typeof ANotificacaoListagemImport;
      parentRoute: typeof ANotificacaoRouteImport;
    };
    '/a/pagfor/importar': {
      id: '/a/pagfor/importar';
      path: '/importar';
      fullPath: '/a/pagfor/importar';
      preLoaderRoute: typeof APagforImportarImport;
      parentRoute: typeof APagforRouteImport;
    };
    '/a/pagfor/listagem': {
      id: '/a/pagfor/listagem';
      path: '/listagem';
      fullPath: '/a/pagfor/listagem';
      preLoaderRoute: typeof APagforListagemImport;
      parentRoute: typeof APagforRouteImport;
    };
    '/a/suporte/instrucoes-de-uso': {
      id: '/a/suporte/instrucoes-de-uso';
      path: '/suporte/instrucoes-de-uso';
      fullPath: '/a/suporte/instrucoes-de-uso';
      preLoaderRoute: typeof ASuporteInstrucoesDeUsoImport;
      parentRoute: typeof ARouteImport;
    };
    '/admin/_a/empresa': {
      id: '/admin/_a/empresa';
      path: '/empresa';
      fullPath: '/admin/empresa';
      preLoaderRoute: typeof AdminAEmpresaImport;
      parentRoute: typeof AdminARouteImport;
    };
    '/admin/_a/erro': {
      id: '/admin/_a/erro';
      path: '/erro';
      fullPath: '/admin/erro';
      preLoaderRoute: typeof AdminAErroImport;
      parentRoute: typeof AdminARouteImport;
    };
    '/admin/_a/representante': {
      id: '/admin/_a/representante';
      path: '/representante';
      fullPath: '/admin/representante';
      preLoaderRoute: typeof AdminARepresentanteImport;
      parentRoute: typeof AdminARouteImport;
    };
    '/admin/_a/sql': {
      id: '/admin/_a/sql';
      path: '/sql';
      fullPath: '/admin/sql';
      preLoaderRoute: typeof AdminASqlImport;
      parentRoute: typeof AdminARouteImport;
    };
    '/admin/auth/login': {
      id: '/admin/auth/login';
      path: '/login';
      fullPath: '/admin/auth/login';
      preLoaderRoute: typeof AdminAuthLoginImport;
      parentRoute: typeof AdminAuthImport;
    };
    '/p/cliente/$slug': {
      id: '/p/cliente/$slug';
      path: '/p/cliente/$slug';
      fullPath: '/p/cliente/$slug';
      preLoaderRoute: typeof PClienteSlugImport;
      parentRoute: typeof rootRoute;
    };
    '/p/compartilhamento/$id': {
      id: '/p/compartilhamento/$id';
      path: '/p/compartilhamento/$id';
      fullPath: '/p/compartilhamento/$id';
      preLoaderRoute: typeof PCompartilhamentoIdImport;
      parentRoute: typeof rootRoute;
    };
    '/p/contrato/$id': {
      id: '/p/contrato/$id';
      path: '/p/contrato/$id';
      fullPath: '/p/contrato/$id';
      preLoaderRoute: typeof PContratoIdImport;
      parentRoute: typeof rootRoute;
    };
    '/p/impressao/$filetype': {
      id: '/p/impressao/$filetype';
      path: '/p/impressao/$filetype';
      fullPath: '/p/impressao/$filetype';
      preLoaderRoute: typeof PImpressaoFiletypeImport;
      parentRoute: typeof rootRoute;
    };
    '/a/cliente/': {
      id: '/a/cliente/';
      path: '/';
      fullPath: '/a/cliente/';
      preLoaderRoute: typeof AClienteIndexImport;
      parentRoute: typeof AClienteRouteImport;
    };
    '/a/cobranca-boleto/': {
      id: '/a/cobranca-boleto/';
      path: '/';
      fullPath: '/a/cobranca-boleto/';
      preLoaderRoute: typeof ACobrancaBoletoIndexImport;
      parentRoute: typeof ACobrancaBoletoRouteImport;
    };
    '/a/cobranca-cartao/': {
      id: '/a/cobranca-cartao/';
      path: '/';
      fullPath: '/a/cobranca-cartao/';
      preLoaderRoute: typeof ACobrancaCartaoIndexImport;
      parentRoute: typeof ACobrancaCartaoRouteImport;
    };
    '/a/cobranca-pix/': {
      id: '/a/cobranca-pix/';
      path: '/';
      fullPath: '/a/cobranca-pix/';
      preLoaderRoute: typeof ACobrancaPixIndexImport;
      parentRoute: typeof ACobrancaPixRouteImport;
    };
    '/a/comissao/': {
      id: '/a/comissao/';
      path: '/';
      fullPath: '/a/comissao/';
      preLoaderRoute: typeof AComissaoIndexImport;
      parentRoute: typeof AComissaoRouteImport;
    };
    '/a/compra/': {
      id: '/a/compra/';
      path: '/';
      fullPath: '/a/compra/';
      preLoaderRoute: typeof ACompraIndexImport;
      parentRoute: typeof ACompraRouteImport;
    };
    '/a/contas-pagar/': {
      id: '/a/contas-pagar/';
      path: '/';
      fullPath: '/a/contas-pagar/';
      preLoaderRoute: typeof AContasPagarIndexImport;
      parentRoute: typeof AContasPagarRouteImport;
    };
    '/a/contas-receber/': {
      id: '/a/contas-receber/';
      path: '/';
      fullPath: '/a/contas-receber/';
      preLoaderRoute: typeof AContasReceberIndexImport;
      parentRoute: typeof AContasReceberRouteImport;
    };
    '/a/contrato/': {
      id: '/a/contrato/';
      path: '/contrato';
      fullPath: '/a/contrato';
      preLoaderRoute: typeof AContratoIndexImport;
      parentRoute: typeof ARouteImport;
    };
    '/a/cupom-fiscal/': {
      id: '/a/cupom-fiscal/';
      path: '/';
      fullPath: '/a/cupom-fiscal/';
      preLoaderRoute: typeof ACupomFiscalIndexImport;
      parentRoute: typeof ACupomFiscalRouteImport;
    };
    '/a/debug/': {
      id: '/a/debug/';
      path: '/';
      fullPath: '/a/debug/';
      preLoaderRoute: typeof ADebugIndexImport;
      parentRoute: typeof ADebugRouteImport;
    };
    '/a/extrato-boleto/': {
      id: '/a/extrato-boleto/';
      path: '/';
      fullPath: '/a/extrato-boleto/';
      preLoaderRoute: typeof AExtratoBoletoIndexImport;
      parentRoute: typeof AExtratoBoletoRouteImport;
    };
    '/a/extrato-pix/': {
      id: '/a/extrato-pix/';
      path: '/';
      fullPath: '/a/extrato-pix/';
      preLoaderRoute: typeof AExtratoPixIndexImport;
      parentRoute: typeof AExtratoPixRouteImport;
    };
    '/a/fornecedor/': {
      id: '/a/fornecedor/';
      path: '/';
      fullPath: '/a/fornecedor/';
      preLoaderRoute: typeof AFornecedorIndexImport;
      parentRoute: typeof AFornecedorRouteImport;
    };
    '/a/manifesto-carga/': {
      id: '/a/manifesto-carga/';
      path: '/';
      fullPath: '/a/manifesto-carga/';
      preLoaderRoute: typeof AManifestoCargaIndexImport;
      parentRoute: typeof AManifestoCargaRouteImport;
    };
    '/a/movimentacoes/': {
      id: '/a/movimentacoes/';
      path: '/';
      fullPath: '/a/movimentacoes/';
      preLoaderRoute: typeof AMovimentacoesIndexImport;
      parentRoute: typeof AMovimentacoesRouteImport;
    };
    '/a/nota-fiscal/': {
      id: '/a/nota-fiscal/';
      path: '/';
      fullPath: '/a/nota-fiscal/';
      preLoaderRoute: typeof ANotaFiscalIndexImport;
      parentRoute: typeof ANotaFiscalRouteImport;
    };
    '/a/painel-gerencial/': {
      id: '/a/painel-gerencial/';
      path: '/painel-gerencial';
      fullPath: '/a/painel-gerencial';
      preLoaderRoute: typeof APainelGerencialIndexImport;
      parentRoute: typeof ARouteImport;
    };
    '/a/produto/': {
      id: '/a/produto/';
      path: '/';
      fullPath: '/a/produto/';
      preLoaderRoute: typeof AProdutoIndexImport;
      parentRoute: typeof AProdutoRouteImport;
    };
    '/a/regra-fiscal/': {
      id: '/a/regra-fiscal/';
      path: '/';
      fullPath: '/a/regra-fiscal/';
      preLoaderRoute: typeof ARegraFiscalIndexImport;
      parentRoute: typeof ARegraFiscalRouteImport;
    };
    '/a/romaneio/': {
      id: '/a/romaneio/';
      path: '/';
      fullPath: '/a/romaneio/';
      preLoaderRoute: typeof ARomaneioIndexImport;
      parentRoute: typeof ARomaneioRouteImport;
    };
    '/a/suporte/': {
      id: '/a/suporte/';
      path: '/suporte';
      fullPath: '/a/suporte';
      preLoaderRoute: typeof ASuporteIndexImport;
      parentRoute: typeof ARouteImport;
    };
    '/a/transportador/': {
      id: '/a/transportador/';
      path: '/';
      fullPath: '/a/transportador/';
      preLoaderRoute: typeof ATransportadorIndexImport;
      parentRoute: typeof ATransportadorRouteImport;
    };
    '/a/venda-externa/': {
      id: '/a/venda-externa/';
      path: '/';
      fullPath: '/a/venda-externa/';
      preLoaderRoute: typeof AVendaExternaIndexImport;
      parentRoute: typeof AVendaExternaRouteImport;
    };
    '/a/cliente/$mode/$idpk': {
      id: '/a/cliente/$mode/$idpk';
      path: '/$mode/$idpk';
      fullPath: '/a/cliente/$mode/$idpk';
      preLoaderRoute: typeof AClienteModeIdpkImport;
      parentRoute: typeof AClienteRouteImport;
    };
    '/a/cobranca-boleto/$mode/$idpk': {
      id: '/a/cobranca-boleto/$mode/$idpk';
      path: '/$mode/$idpk';
      fullPath: '/a/cobranca-boleto/$mode/$idpk';
      preLoaderRoute: typeof ACobrancaBoletoModeIdpkImport;
      parentRoute: typeof ACobrancaBoletoRouteImport;
    };
    '/a/cobranca-cartao/$mode/$idpk': {
      id: '/a/cobranca-cartao/$mode/$idpk';
      path: '/$mode/$idpk';
      fullPath: '/a/cobranca-cartao/$mode/$idpk';
      preLoaderRoute: typeof ACobrancaCartaoModeIdpkImport;
      parentRoute: typeof ACobrancaCartaoRouteImport;
    };
    '/a/compra/$mode/$idpk': {
      id: '/a/compra/$mode/$idpk';
      path: '/$mode/$idpk';
      fullPath: '/a/compra/$mode/$idpk';
      preLoaderRoute: typeof ACompraModeIdpkImport;
      parentRoute: typeof ACompraRouteImport;
    };
    '/a/contas-pagar/$mode/$idpk': {
      id: '/a/contas-pagar/$mode/$idpk';
      path: '/$mode/$idpk';
      fullPath: '/a/contas-pagar/$mode/$idpk';
      preLoaderRoute: typeof AContasPagarModeIdpkImport;
      parentRoute: typeof AContasPagarRouteImport;
    };
    '/a/contas-receber/$mode/$idpk': {
      id: '/a/contas-receber/$mode/$idpk';
      path: '/$mode/$idpk';
      fullPath: '/a/contas-receber/$mode/$idpk';
      preLoaderRoute: typeof AContasReceberModeIdpkImport;
      parentRoute: typeof AContasReceberRouteImport;
    };
    '/a/contrato/$mode/$idpk': {
      id: '/a/contrato/$mode/$idpk';
      path: '/contrato/$mode/$idpk';
      fullPath: '/a/contrato/$mode/$idpk';
      preLoaderRoute: typeof AContratoModeIdpkImport;
      parentRoute: typeof ARouteImport;
    };
    '/a/estoque/inventario/novo': {
      id: '/a/estoque/inventario/novo';
      path: '/inventario/novo';
      fullPath: '/a/estoque/inventario/novo';
      preLoaderRoute: typeof AEstoqueInventarioNovoImport;
      parentRoute: typeof AEstoqueRouteImport;
    };
    '/a/importacao/cadastro/$tipo': {
      id: '/a/importacao/cadastro/$tipo';
      path: '/importacao/cadastro/$tipo';
      fullPath: '/a/importacao/cadastro/$tipo';
      preLoaderRoute: typeof AImportacaoCadastroTipoImport;
      parentRoute: typeof ARouteImport;
    };
    '/a/importacao/xml/$tipo': {
      id: '/a/importacao/xml/$tipo';
      path: '/importacao/xml/$tipo';
      fullPath: '/a/importacao/xml/$tipo';
      preLoaderRoute: typeof AImportacaoXmlTipoImport;
      parentRoute: typeof ARouteImport;
    };
    '/a/manifesto-carga/$mode/$idpk': {
      id: '/a/manifesto-carga/$mode/$idpk';
      path: '/$mode/$idpk';
      fullPath: '/a/manifesto-carga/$mode/$idpk';
      preLoaderRoute: typeof AManifestoCargaModeIdpkImport;
      parentRoute: typeof AManifestoCargaRouteImport;
    };
    '/a/movimentacoes/extrato/$idpk': {
      id: '/a/movimentacoes/extrato/$idpk';
      path: '/extrato/$idpk';
      fullPath: '/a/movimentacoes/extrato/$idpk';
      preLoaderRoute: typeof AMovimentacoesExtratoIdpkImport;
      parentRoute: typeof AMovimentacoesRouteImport;
    };
    '/a/nota-fiscal/$mode/$idpk': {
      id: '/a/nota-fiscal/$mode/$idpk';
      path: '/$mode/$idpk';
      fullPath: '/a/nota-fiscal/$mode/$idpk';
      preLoaderRoute: typeof ANotaFiscalModeIdpkImport;
      parentRoute: typeof ANotaFiscalRouteImport;
    };
    '/a/pagfor/$mode/$idpk': {
      id: '/a/pagfor/$mode/$idpk';
      path: '/$mode/$idpk';
      fullPath: '/a/pagfor/$mode/$idpk';
      preLoaderRoute: typeof APagforModeIdpkImport;
      parentRoute: typeof APagforRouteImport;
    };
    '/a/romaneio/$mode/$idpk': {
      id: '/a/romaneio/$mode/$idpk';
      path: '/$mode/$idpk';
      fullPath: '/a/romaneio/$mode/$idpk';
      preLoaderRoute: typeof ARomaneioModeIdpkImport;
      parentRoute: typeof ARomaneioRouteImport;
    };
    '/a/venda-externa/$mode/$idpk': {
      id: '/a/venda-externa/$mode/$idpk';
      path: '/$mode/$idpk';
      fullPath: '/a/venda-externa/$mode/$idpk';
      preLoaderRoute: typeof AVendaExternaModeIdpkImport;
      parentRoute: typeof AVendaExternaRouteImport;
    };
    '/admin/_a/configuracao/representante': {
      id: '/admin/_a/configuracao/representante';
      path: '/configuracao/representante';
      fullPath: '/admin/configuracao/representante';
      preLoaderRoute: typeof AdminAConfiguracaoRepresentanteImport;
      parentRoute: typeof AdminARouteImport;
    };
    '/p/catalogo/$slug/carrinho': {
      id: '/p/catalogo/$slug/carrinho';
      path: '/carrinho';
      fullPath: '/p/catalogo/$slug/carrinho';
      preLoaderRoute: typeof PCatalogoSlugCarrinhoImport;
      parentRoute: typeof PCatalogoSlugRouteImport;
    };
    '/p/catalogo/$slug/finalizar': {
      id: '/p/catalogo/$slug/finalizar';
      path: '/finalizar';
      fullPath: '/p/catalogo/$slug/finalizar';
      preLoaderRoute: typeof PCatalogoSlugFinalizarImport;
      parentRoute: typeof PCatalogoSlugRouteImport;
    };
    '/p/catalogo/$slug/listagem': {
      id: '/p/catalogo/$slug/listagem';
      path: '/listagem';
      fullPath: '/p/catalogo/$slug/listagem';
      preLoaderRoute: typeof PCatalogoSlugListagemImport;
      parentRoute: typeof PCatalogoSlugRouteImport;
    };
    '/p/conta-digital/termos-de-uso/$base64': {
      id: '/p/conta-digital/termos-de-uso/$base64';
      path: '/p/conta-digital/termos-de-uso/$base64';
      fullPath: '/p/conta-digital/termos-de-uso/$base64';
      preLoaderRoute: typeof PContaDigitalTermosDeUsoBase64Import;
      parentRoute: typeof rootRoute;
    };
    '/a/configuracao/empresa/': {
      id: '/a/configuracao/empresa/';
      path: '/';
      fullPath: '/a/configuracao/empresa/';
      preLoaderRoute: typeof AConfiguracaoEmpresaIndexImport;
      parentRoute: typeof AConfiguracaoEmpresaRouteImport;
    };
    '/a/estoque/inventario/': {
      id: '/a/estoque/inventario/';
      path: '/inventario';
      fullPath: '/a/estoque/inventario';
      preLoaderRoute: typeof AEstoqueInventarioIndexImport;
      parentRoute: typeof AEstoqueRouteImport;
    };
    '/a/relatorio/$tipo/': {
      id: '/a/relatorio/$tipo/';
      path: '/relatorio/$tipo';
      fullPath: '/a/relatorio/$tipo';
      preLoaderRoute: typeof ARelatorioTipoIndexImport;
      parentRoute: typeof ARouteImport;
    };
    '/a/venda/$tipo/': {
      id: '/a/venda/$tipo/';
      path: '/$tipo';
      fullPath: '/a/venda/$tipo';
      preLoaderRoute: typeof AVendaTipoIndexImport;
      parentRoute: typeof AVendaRouteImport;
    };
    '/p/catalogo/$slug/': {
      id: '/p/catalogo/$slug/';
      path: '/';
      fullPath: '/p/catalogo/$slug/';
      preLoaderRoute: typeof PCatalogoSlugIndexImport;
      parentRoute: typeof PCatalogoSlugRouteImport;
    };
    '/p/compartilhamento/$id/': {
      id: '/p/compartilhamento/$id/';
      path: '/';
      fullPath: '/p/compartilhamento/$id/';
      preLoaderRoute: typeof PCompartilhamentoIdIndexImport;
      parentRoute: typeof PCompartilhamentoIdImport;
    };
    '/p/pagamento/$id/': {
      id: '/p/pagamento/$id/';
      path: '/p/pagamento/$id';
      fullPath: '/p/pagamento/$id';
      preLoaderRoute: typeof PPagamentoIdIndexImport;
      parentRoute: typeof rootRoute;
    };
    '/a/estoque/inventario/editar/$idpk': {
      id: '/a/estoque/inventario/editar/$idpk';
      path: '/inventario/editar/$idpk';
      fullPath: '/a/estoque/inventario/editar/$idpk';
      preLoaderRoute: typeof AEstoqueInventarioEditarIdpkImport;
      parentRoute: typeof AEstoqueRouteImport;
    };
    '/a/movimentacoes/$mode/$contaIdpk/$idpk': {
      id: '/a/movimentacoes/$mode/$contaIdpk/$idpk';
      path: '/$mode/$contaIdpk/$idpk';
      fullPath: '/a/movimentacoes/$mode/$contaIdpk/$idpk';
      preLoaderRoute: typeof AMovimentacoesModeContaIdpkIdpkImport;
      parentRoute: typeof AMovimentacoesRouteImport;
    };
    '/a/venda/$tipo/$mode/$idpk': {
      id: '/a/venda/$tipo/$mode/$idpk';
      path: '/$tipo/$mode/$idpk';
      fullPath: '/a/venda/$tipo/$mode/$idpk';
      preLoaderRoute: typeof AVendaTipoModeIdpkImport;
      parentRoute: typeof AVendaRouteImport;
    };
    '/p/catalogo/$slug/produto/$idpk': {
      id: '/p/catalogo/$slug/produto/$idpk';
      path: '/produto/$idpk';
      fullPath: '/p/catalogo/$slug/produto/$idpk';
      preLoaderRoute: typeof PCatalogoSlugProdutoIdpkImport;
      parentRoute: typeof PCatalogoSlugRouteImport;
    };
    '/a/nota-fiscal/venda-externa/$tipoVendaExterna/$mode/$veeIdpk': {
      id: '/a/nota-fiscal/venda-externa/$tipoVendaExterna/$mode/$veeIdpk';
      path: '/venda-externa/$tipoVendaExterna/$mode/$veeIdpk';
      fullPath: '/a/nota-fiscal/venda-externa/$tipoVendaExterna/$mode/$veeIdpk';
      preLoaderRoute: typeof ANotaFiscalVendaExternaTipoVendaExternaModeVeeIdpkImport;
      parentRoute: typeof ANotaFiscalRouteImport;
    };
  }
}

// Create and export the route tree

interface AClienteRouteRouteChildren {
  AClienteIndexRoute: typeof AClienteIndexRoute;
  AClienteModeIdpkRoute: typeof AClienteModeIdpkRoute;
}

const AClienteRouteRouteChildren: AClienteRouteRouteChildren = {
  AClienteIndexRoute: AClienteIndexRoute,
  AClienteModeIdpkRoute: AClienteModeIdpkRoute,
};

const AClienteRouteRouteWithChildren = AClienteRouteRoute._addFileChildren(
  AClienteRouteRouteChildren,
);

interface ACobrancaBoletoRouteRouteChildren {
  ACobrancaBoletoRotinasBancariasRoute: typeof ACobrancaBoletoRotinasBancariasRoute;
  ACobrancaBoletoIndexRoute: typeof ACobrancaBoletoIndexRoute;
  ACobrancaBoletoModeIdpkRoute: typeof ACobrancaBoletoModeIdpkRoute;
}

const ACobrancaBoletoRouteRouteChildren: ACobrancaBoletoRouteRouteChildren = {
  ACobrancaBoletoRotinasBancariasRoute: ACobrancaBoletoRotinasBancariasRoute,
  ACobrancaBoletoIndexRoute: ACobrancaBoletoIndexRoute,
  ACobrancaBoletoModeIdpkRoute: ACobrancaBoletoModeIdpkRoute,
};

const ACobrancaBoletoRouteRouteWithChildren =
  ACobrancaBoletoRouteRoute._addFileChildren(ACobrancaBoletoRouteRouteChildren);

interface ACobrancaCartaoRouteRouteChildren {
  ACobrancaCartaoIndexRoute: typeof ACobrancaCartaoIndexRoute;
  ACobrancaCartaoModeIdpkRoute: typeof ACobrancaCartaoModeIdpkRoute;
}

const ACobrancaCartaoRouteRouteChildren: ACobrancaCartaoRouteRouteChildren = {
  ACobrancaCartaoIndexRoute: ACobrancaCartaoIndexRoute,
  ACobrancaCartaoModeIdpkRoute: ACobrancaCartaoModeIdpkRoute,
};

const ACobrancaCartaoRouteRouteWithChildren =
  ACobrancaCartaoRouteRoute._addFileChildren(ACobrancaCartaoRouteRouteChildren);

interface ACobrancaPixRouteRouteChildren {
  ACobrancaPixIndexRoute: typeof ACobrancaPixIndexRoute;
}

const ACobrancaPixRouteRouteChildren: ACobrancaPixRouteRouteChildren = {
  ACobrancaPixIndexRoute: ACobrancaPixIndexRoute,
};

const ACobrancaPixRouteRouteWithChildren =
  ACobrancaPixRouteRoute._addFileChildren(ACobrancaPixRouteRouteChildren);

interface AComissaoRouteRouteChildren {
  AComissaoIndexRoute: typeof AComissaoIndexRoute;
}

const AComissaoRouteRouteChildren: AComissaoRouteRouteChildren = {
  AComissaoIndexRoute: AComissaoIndexRoute,
};

const AComissaoRouteRouteWithChildren = AComissaoRouteRoute._addFileChildren(
  AComissaoRouteRouteChildren,
);

interface ACompraRouteRouteChildren {
  ACompraIndexRoute: typeof ACompraIndexRoute;
  ACompraModeIdpkRoute: typeof ACompraModeIdpkRoute;
}

const ACompraRouteRouteChildren: ACompraRouteRouteChildren = {
  ACompraIndexRoute: ACompraIndexRoute,
  ACompraModeIdpkRoute: ACompraModeIdpkRoute,
};

const ACompraRouteRouteWithChildren = ACompraRouteRoute._addFileChildren(
  ACompraRouteRouteChildren,
);

interface AContasPagarRouteRouteChildren {
  AContasPagarIndexRoute: typeof AContasPagarIndexRoute;
  AContasPagarModeIdpkRoute: typeof AContasPagarModeIdpkRoute;
}

const AContasPagarRouteRouteChildren: AContasPagarRouteRouteChildren = {
  AContasPagarIndexRoute: AContasPagarIndexRoute,
  AContasPagarModeIdpkRoute: AContasPagarModeIdpkRoute,
};

const AContasPagarRouteRouteWithChildren =
  AContasPagarRouteRoute._addFileChildren(AContasPagarRouteRouteChildren);

interface AContasReceberRouteRouteChildren {
  AContasReceberIndexRoute: typeof AContasReceberIndexRoute;
  AContasReceberModeIdpkRoute: typeof AContasReceberModeIdpkRoute;
}

const AContasReceberRouteRouteChildren: AContasReceberRouteRouteChildren = {
  AContasReceberIndexRoute: AContasReceberIndexRoute,
  AContasReceberModeIdpkRoute: AContasReceberModeIdpkRoute,
};

const AContasReceberRouteRouteWithChildren =
  AContasReceberRouteRoute._addFileChildren(AContasReceberRouteRouteChildren);

interface ACupomFiscalRouteRouteChildren {
  ACupomFiscalIndexRoute: typeof ACupomFiscalIndexRoute;
}

const ACupomFiscalRouteRouteChildren: ACupomFiscalRouteRouteChildren = {
  ACupomFiscalIndexRoute: ACupomFiscalIndexRoute,
};

const ACupomFiscalRouteRouteWithChildren =
  ACupomFiscalRouteRoute._addFileChildren(ACupomFiscalRouteRouteChildren);

interface ADebugRouteRouteChildren {
  ADebugIndexRoute: typeof ADebugIndexRoute;
}

const ADebugRouteRouteChildren: ADebugRouteRouteChildren = {
  ADebugIndexRoute: ADebugIndexRoute,
};

const ADebugRouteRouteWithChildren = ADebugRouteRoute._addFileChildren(
  ADebugRouteRouteChildren,
);

interface AEstoqueRouteRouteChildren {
  AEstoqueConferenciaRoute: typeof AEstoqueConferenciaRoute;
  AEstoqueMovimentacaoRoute: typeof AEstoqueMovimentacaoRoute;
  AEstoqueInventarioNovoRoute: typeof AEstoqueInventarioNovoRoute;
  AEstoqueInventarioIndexRoute: typeof AEstoqueInventarioIndexRoute;
  AEstoqueInventarioEditarIdpkRoute: typeof AEstoqueInventarioEditarIdpkRoute;
}

const AEstoqueRouteRouteChildren: AEstoqueRouteRouteChildren = {
  AEstoqueConferenciaRoute: AEstoqueConferenciaRoute,
  AEstoqueMovimentacaoRoute: AEstoqueMovimentacaoRoute,
  AEstoqueInventarioNovoRoute: AEstoqueInventarioNovoRoute,
  AEstoqueInventarioIndexRoute: AEstoqueInventarioIndexRoute,
  AEstoqueInventarioEditarIdpkRoute: AEstoqueInventarioEditarIdpkRoute,
};

const AEstoqueRouteRouteWithChildren = AEstoqueRouteRoute._addFileChildren(
  AEstoqueRouteRouteChildren,
);

interface AExtratoBoletoRouteRouteChildren {
  AExtratoBoletoIndexRoute: typeof AExtratoBoletoIndexRoute;
}

const AExtratoBoletoRouteRouteChildren: AExtratoBoletoRouteRouteChildren = {
  AExtratoBoletoIndexRoute: AExtratoBoletoIndexRoute,
};

const AExtratoBoletoRouteRouteWithChildren =
  AExtratoBoletoRouteRoute._addFileChildren(AExtratoBoletoRouteRouteChildren);

interface AExtratoPixRouteRouteChildren {
  AExtratoPixIndexRoute: typeof AExtratoPixIndexRoute;
}

const AExtratoPixRouteRouteChildren: AExtratoPixRouteRouteChildren = {
  AExtratoPixIndexRoute: AExtratoPixIndexRoute,
};

const AExtratoPixRouteRouteWithChildren =
  AExtratoPixRouteRoute._addFileChildren(AExtratoPixRouteRouteChildren);

interface AFornecedorRouteRouteChildren {
  AFornecedorIndexRoute: typeof AFornecedorIndexRoute;
}

const AFornecedorRouteRouteChildren: AFornecedorRouteRouteChildren = {
  AFornecedorIndexRoute: AFornecedorIndexRoute,
};

const AFornecedorRouteRouteWithChildren =
  AFornecedorRouteRoute._addFileChildren(AFornecedorRouteRouteChildren);

interface AManifestoCargaRouteRouteChildren {
  AManifestoCargaIndexRoute: typeof AManifestoCargaIndexRoute;
  AManifestoCargaModeIdpkRoute: typeof AManifestoCargaModeIdpkRoute;
}

const AManifestoCargaRouteRouteChildren: AManifestoCargaRouteRouteChildren = {
  AManifestoCargaIndexRoute: AManifestoCargaIndexRoute,
  AManifestoCargaModeIdpkRoute: AManifestoCargaModeIdpkRoute,
};

const AManifestoCargaRouteRouteWithChildren =
  AManifestoCargaRouteRoute._addFileChildren(AManifestoCargaRouteRouteChildren);

interface AMovimentacoesRouteRouteChildren {
  AMovimentacoesIndexRoute: typeof AMovimentacoesIndexRoute;
  AMovimentacoesExtratoIdpkRoute: typeof AMovimentacoesExtratoIdpkRoute;
  AMovimentacoesModeContaIdpkIdpkRoute: typeof AMovimentacoesModeContaIdpkIdpkRoute;
}

const AMovimentacoesRouteRouteChildren: AMovimentacoesRouteRouteChildren = {
  AMovimentacoesIndexRoute: AMovimentacoesIndexRoute,
  AMovimentacoesExtratoIdpkRoute: AMovimentacoesExtratoIdpkRoute,
  AMovimentacoesModeContaIdpkIdpkRoute: AMovimentacoesModeContaIdpkIdpkRoute,
};

const AMovimentacoesRouteRouteWithChildren =
  AMovimentacoesRouteRoute._addFileChildren(AMovimentacoesRouteRouteChildren);

interface ANotaFiscalRouteRouteChildren {
  ANotaFiscalIndexRoute: typeof ANotaFiscalIndexRoute;
  ANotaFiscalModeIdpkRoute: typeof ANotaFiscalModeIdpkRoute;
  ANotaFiscalVendaExternaTipoVendaExternaModeVeeIdpkRoute: typeof ANotaFiscalVendaExternaTipoVendaExternaModeVeeIdpkRoute;
}

const ANotaFiscalRouteRouteChildren: ANotaFiscalRouteRouteChildren = {
  ANotaFiscalIndexRoute: ANotaFiscalIndexRoute,
  ANotaFiscalModeIdpkRoute: ANotaFiscalModeIdpkRoute,
  ANotaFiscalVendaExternaTipoVendaExternaModeVeeIdpkRoute:
    ANotaFiscalVendaExternaTipoVendaExternaModeVeeIdpkRoute,
};

const ANotaFiscalRouteRouteWithChildren =
  ANotaFiscalRouteRoute._addFileChildren(ANotaFiscalRouteRouteChildren);

interface ANotificacaoRouteRouteChildren {
  ANotificacaoListagemRoute: typeof ANotificacaoListagemRoute;
}

const ANotificacaoRouteRouteChildren: ANotificacaoRouteRouteChildren = {
  ANotificacaoListagemRoute: ANotificacaoListagemRoute,
};

const ANotificacaoRouteRouteWithChildren =
  ANotificacaoRouteRoute._addFileChildren(ANotificacaoRouteRouteChildren);

interface APagforRouteRouteChildren {
  APagforImportarRoute: typeof APagforImportarRoute;
  APagforListagemRoute: typeof APagforListagemRoute;
  APagforModeIdpkRoute: typeof APagforModeIdpkRoute;
}

const APagforRouteRouteChildren: APagforRouteRouteChildren = {
  APagforImportarRoute: APagforImportarRoute,
  APagforListagemRoute: APagforListagemRoute,
  APagforModeIdpkRoute: APagforModeIdpkRoute,
};

const APagforRouteRouteWithChildren = APagforRouteRoute._addFileChildren(
  APagforRouteRouteChildren,
);

interface AProdutoRouteRouteChildren {
  AProdutoIndexRoute: typeof AProdutoIndexRoute;
}

const AProdutoRouteRouteChildren: AProdutoRouteRouteChildren = {
  AProdutoIndexRoute: AProdutoIndexRoute,
};

const AProdutoRouteRouteWithChildren = AProdutoRouteRoute._addFileChildren(
  AProdutoRouteRouteChildren,
);

interface ARegraFiscalRouteRouteChildren {
  ARegraFiscalIndexRoute: typeof ARegraFiscalIndexRoute;
}

const ARegraFiscalRouteRouteChildren: ARegraFiscalRouteRouteChildren = {
  ARegraFiscalIndexRoute: ARegraFiscalIndexRoute,
};

const ARegraFiscalRouteRouteWithChildren =
  ARegraFiscalRouteRoute._addFileChildren(ARegraFiscalRouteRouteChildren);

interface ARomaneioRouteRouteChildren {
  ARomaneioIndexRoute: typeof ARomaneioIndexRoute;
  ARomaneioModeIdpkRoute: typeof ARomaneioModeIdpkRoute;
}

const ARomaneioRouteRouteChildren: ARomaneioRouteRouteChildren = {
  ARomaneioIndexRoute: ARomaneioIndexRoute,
  ARomaneioModeIdpkRoute: ARomaneioModeIdpkRoute,
};

const ARomaneioRouteRouteWithChildren = ARomaneioRouteRoute._addFileChildren(
  ARomaneioRouteRouteChildren,
);

interface ATransportadorRouteRouteChildren {
  ATransportadorIndexRoute: typeof ATransportadorIndexRoute;
}

const ATransportadorRouteRouteChildren: ATransportadorRouteRouteChildren = {
  ATransportadorIndexRoute: ATransportadorIndexRoute,
};

const ATransportadorRouteRouteWithChildren =
  ATransportadorRouteRoute._addFileChildren(ATransportadorRouteRouteChildren);

interface AVendaRouteRouteChildren {
  AVendaTipoIndexRoute: typeof AVendaTipoIndexRoute;
  AVendaTipoModeIdpkRoute: typeof AVendaTipoModeIdpkRoute;
}

const AVendaRouteRouteChildren: AVendaRouteRouteChildren = {
  AVendaTipoIndexRoute: AVendaTipoIndexRoute,
  AVendaTipoModeIdpkRoute: AVendaTipoModeIdpkRoute,
};

const AVendaRouteRouteWithChildren = AVendaRouteRoute._addFileChildren(
  AVendaRouteRouteChildren,
);

interface AVendaExternaRouteRouteChildren {
  AVendaExternaIndexRoute: typeof AVendaExternaIndexRoute;
  AVendaExternaModeIdpkRoute: typeof AVendaExternaModeIdpkRoute;
}

const AVendaExternaRouteRouteChildren: AVendaExternaRouteRouteChildren = {
  AVendaExternaIndexRoute: AVendaExternaIndexRoute,
  AVendaExternaModeIdpkRoute: AVendaExternaModeIdpkRoute,
};

const AVendaExternaRouteRouteWithChildren =
  AVendaExternaRouteRoute._addFileChildren(AVendaExternaRouteRouteChildren);

interface AConfiguracaoEmpresaRouteRouteChildren {
  AConfiguracaoEmpresaIndexRoute: typeof AConfiguracaoEmpresaIndexRoute;
}

const AConfiguracaoEmpresaRouteRouteChildren: AConfiguracaoEmpresaRouteRouteChildren =
  {
    AConfiguracaoEmpresaIndexRoute: AConfiguracaoEmpresaIndexRoute,
  };

const AConfiguracaoEmpresaRouteRouteWithChildren =
  AConfiguracaoEmpresaRouteRoute._addFileChildren(
    AConfiguracaoEmpresaRouteRouteChildren,
  );

interface ARouteRouteChildren {
  AClienteRouteRoute: typeof AClienteRouteRouteWithChildren;
  ACobrancaBoletoRouteRoute: typeof ACobrancaBoletoRouteRouteWithChildren;
  ACobrancaCartaoRouteRoute: typeof ACobrancaCartaoRouteRouteWithChildren;
  ACobrancaPixRouteRoute: typeof ACobrancaPixRouteRouteWithChildren;
  AComissaoRouteRoute: typeof AComissaoRouteRouteWithChildren;
  ACompraRouteRoute: typeof ACompraRouteRouteWithChildren;
  AContasPagarRouteRoute: typeof AContasPagarRouteRouteWithChildren;
  AContasReceberRouteRoute: typeof AContasReceberRouteRouteWithChildren;
  ACupomFiscalRouteRoute: typeof ACupomFiscalRouteRouteWithChildren;
  ADebugRouteRoute: typeof ADebugRouteRouteWithChildren;
  AEstoqueRouteRoute: typeof AEstoqueRouteRouteWithChildren;
  AExtratoBoletoRouteRoute: typeof AExtratoBoletoRouteRouteWithChildren;
  AExtratoPixRouteRoute: typeof AExtratoPixRouteRouteWithChildren;
  AFornecedorRouteRoute: typeof AFornecedorRouteRouteWithChildren;
  AManifestoCargaRouteRoute: typeof AManifestoCargaRouteRouteWithChildren;
  AMovimentacoesRouteRoute: typeof AMovimentacoesRouteRouteWithChildren;
  ANotaFiscalRouteRoute: typeof ANotaFiscalRouteRouteWithChildren;
  ANotificacaoRouteRoute: typeof ANotificacaoRouteRouteWithChildren;
  APagforRouteRoute: typeof APagforRouteRouteWithChildren;
  AProdutoRouteRoute: typeof AProdutoRouteRouteWithChildren;
  ARegraFiscalRouteRoute: typeof ARegraFiscalRouteRouteWithChildren;
  ARomaneioRouteRoute: typeof ARomaneioRouteRouteWithChildren;
  ATransportadorRouteRoute: typeof ATransportadorRouteRouteWithChildren;
  AVendaRouteRoute: typeof AVendaRouteRouteWithChildren;
  AVendaExternaRouteRoute: typeof AVendaExternaRouteRouteWithChildren;
  APdvRoute: typeof APdvRoute;
  AConfiguracaoEmpresaRouteRoute: typeof AConfiguracaoEmpresaRouteRouteWithChildren;
  AConfiguracaoUsuarioRoute: typeof AConfiguracaoUsuarioRoute;
  AImpressaoFiletypeRoute: typeof AImpressaoFiletypeRoute;
  ASuporteInstrucoesDeUsoRoute: typeof ASuporteInstrucoesDeUsoRoute;
  AContratoIndexRoute: typeof AContratoIndexRoute;
  APainelGerencialIndexRoute: typeof APainelGerencialIndexRoute;
  ASuporteIndexRoute: typeof ASuporteIndexRoute;
  AContratoModeIdpkRoute: typeof AContratoModeIdpkRoute;
  AImportacaoCadastroTipoRoute: typeof AImportacaoCadastroTipoRoute;
  AImportacaoXmlTipoRoute: typeof AImportacaoXmlTipoRoute;
  ARelatorioTipoIndexRoute: typeof ARelatorioTipoIndexRoute;
}

const ARouteRouteChildren: ARouteRouteChildren = {
  AClienteRouteRoute: AClienteRouteRouteWithChildren,
  ACobrancaBoletoRouteRoute: ACobrancaBoletoRouteRouteWithChildren,
  ACobrancaCartaoRouteRoute: ACobrancaCartaoRouteRouteWithChildren,
  ACobrancaPixRouteRoute: ACobrancaPixRouteRouteWithChildren,
  AComissaoRouteRoute: AComissaoRouteRouteWithChildren,
  ACompraRouteRoute: ACompraRouteRouteWithChildren,
  AContasPagarRouteRoute: AContasPagarRouteRouteWithChildren,
  AContasReceberRouteRoute: AContasReceberRouteRouteWithChildren,
  ACupomFiscalRouteRoute: ACupomFiscalRouteRouteWithChildren,
  ADebugRouteRoute: ADebugRouteRouteWithChildren,
  AEstoqueRouteRoute: AEstoqueRouteRouteWithChildren,
  AExtratoBoletoRouteRoute: AExtratoBoletoRouteRouteWithChildren,
  AExtratoPixRouteRoute: AExtratoPixRouteRouteWithChildren,
  AFornecedorRouteRoute: AFornecedorRouteRouteWithChildren,
  AManifestoCargaRouteRoute: AManifestoCargaRouteRouteWithChildren,
  AMovimentacoesRouteRoute: AMovimentacoesRouteRouteWithChildren,
  ANotaFiscalRouteRoute: ANotaFiscalRouteRouteWithChildren,
  ANotificacaoRouteRoute: ANotificacaoRouteRouteWithChildren,
  APagforRouteRoute: APagforRouteRouteWithChildren,
  AProdutoRouteRoute: AProdutoRouteRouteWithChildren,
  ARegraFiscalRouteRoute: ARegraFiscalRouteRouteWithChildren,
  ARomaneioRouteRoute: ARomaneioRouteRouteWithChildren,
  ATransportadorRouteRoute: ATransportadorRouteRouteWithChildren,
  AVendaRouteRoute: AVendaRouteRouteWithChildren,
  AVendaExternaRouteRoute: AVendaExternaRouteRouteWithChildren,
  APdvRoute: APdvRoute,
  AConfiguracaoEmpresaRouteRoute: AConfiguracaoEmpresaRouteRouteWithChildren,
  AConfiguracaoUsuarioRoute: AConfiguracaoUsuarioRoute,
  AImpressaoFiletypeRoute: AImpressaoFiletypeRoute,
  ASuporteInstrucoesDeUsoRoute: ASuporteInstrucoesDeUsoRoute,
  AContratoIndexRoute: AContratoIndexRoute,
  APainelGerencialIndexRoute: APainelGerencialIndexRoute,
  ASuporteIndexRoute: ASuporteIndexRoute,
  AContratoModeIdpkRoute: AContratoModeIdpkRoute,
  AImportacaoCadastroTipoRoute: AImportacaoCadastroTipoRoute,
  AImportacaoXmlTipoRoute: AImportacaoXmlTipoRoute,
  ARelatorioTipoIndexRoute: ARelatorioTipoIndexRoute,
};

const ARouteRouteWithChildren =
  ARouteRoute._addFileChildren(ARouteRouteChildren);

interface AuthRouteRouteChildren {
  AuthAcessoNegadoRoute: typeof AuthAcessoNegadoRoute;
  AuthCadastroRoute: typeof AuthCadastroRoute;
  AuthCadastroFimRoute: typeof AuthCadastroFimRoute;
  AuthContaBloqueadaRoute: typeof AuthContaBloqueadaRoute;
  AuthLoginRoute: typeof AuthLoginRoute;
  AuthRecuperarSenhaRoute: typeof AuthRecuperarSenhaRoute;
  AuthRedefinirSenhaRoute: typeof AuthRedefinirSenhaRoute;
  AuthSolicitarValidacaoRoute: typeof AuthSolicitarValidacaoRoute;
}

const AuthRouteRouteChildren: AuthRouteRouteChildren = {
  AuthAcessoNegadoRoute: AuthAcessoNegadoRoute,
  AuthCadastroRoute: AuthCadastroRoute,
  AuthCadastroFimRoute: AuthCadastroFimRoute,
  AuthContaBloqueadaRoute: AuthContaBloqueadaRoute,
  AuthLoginRoute: AuthLoginRoute,
  AuthRecuperarSenhaRoute: AuthRecuperarSenhaRoute,
  AuthRedefinirSenhaRoute: AuthRedefinirSenhaRoute,
  AuthSolicitarValidacaoRoute: AuthSolicitarValidacaoRoute,
};

const AuthRouteRouteWithChildren = AuthRouteRoute._addFileChildren(
  AuthRouteRouteChildren,
);

interface AdminARouteRouteChildren {
  AdminAEmpresaRoute: typeof AdminAEmpresaRoute;
  AdminAErroRoute: typeof AdminAErroRoute;
  AdminARepresentanteRoute: typeof AdminARepresentanteRoute;
  AdminASqlRoute: typeof AdminASqlRoute;
  AdminAConfiguracaoRepresentanteRoute: typeof AdminAConfiguracaoRepresentanteRoute;
}

const AdminARouteRouteChildren: AdminARouteRouteChildren = {
  AdminAEmpresaRoute: AdminAEmpresaRoute,
  AdminAErroRoute: AdminAErroRoute,
  AdminARepresentanteRoute: AdminARepresentanteRoute,
  AdminASqlRoute: AdminASqlRoute,
  AdminAConfiguracaoRepresentanteRoute: AdminAConfiguracaoRepresentanteRoute,
};

const AdminARouteRouteWithChildren = AdminARouteRoute._addFileChildren(
  AdminARouteRouteChildren,
);

interface AdminAuthRouteChildren {
  AdminAuthLoginRoute: typeof AdminAuthLoginRoute;
}

const AdminAuthRouteChildren: AdminAuthRouteChildren = {
  AdminAuthLoginRoute: AdminAuthLoginRoute,
};

const AdminAuthRouteWithChildren = AdminAuthRoute._addFileChildren(
  AdminAuthRouteChildren,
);

interface AdminRouteChildren {
  AdminARouteRoute: typeof AdminARouteRouteWithChildren;
  AdminAuthRoute: typeof AdminAuthRouteWithChildren;
}

const AdminRouteChildren: AdminRouteChildren = {
  AdminARouteRoute: AdminARouteRouteWithChildren,
  AdminAuthRoute: AdminAuthRouteWithChildren,
};

const AdminRouteWithChildren = AdminRoute._addFileChildren(AdminRouteChildren);

interface PCatalogoSlugRouteRouteChildren {
  PCatalogoSlugCarrinhoRoute: typeof PCatalogoSlugCarrinhoRoute;
  PCatalogoSlugFinalizarRoute: typeof PCatalogoSlugFinalizarRoute;
  PCatalogoSlugListagemRoute: typeof PCatalogoSlugListagemRoute;
  PCatalogoSlugIndexRoute: typeof PCatalogoSlugIndexRoute;
  PCatalogoSlugProdutoIdpkRoute: typeof PCatalogoSlugProdutoIdpkRoute;
}

const PCatalogoSlugRouteRouteChildren: PCatalogoSlugRouteRouteChildren = {
  PCatalogoSlugCarrinhoRoute: PCatalogoSlugCarrinhoRoute,
  PCatalogoSlugFinalizarRoute: PCatalogoSlugFinalizarRoute,
  PCatalogoSlugListagemRoute: PCatalogoSlugListagemRoute,
  PCatalogoSlugIndexRoute: PCatalogoSlugIndexRoute,
  PCatalogoSlugProdutoIdpkRoute: PCatalogoSlugProdutoIdpkRoute,
};

const PCatalogoSlugRouteRouteWithChildren =
  PCatalogoSlugRouteRoute._addFileChildren(PCatalogoSlugRouteRouteChildren);

interface PCompartilhamentoIdRouteChildren {
  PCompartilhamentoIdIndexRoute: typeof PCompartilhamentoIdIndexRoute;
}

const PCompartilhamentoIdRouteChildren: PCompartilhamentoIdRouteChildren = {
  PCompartilhamentoIdIndexRoute: PCompartilhamentoIdIndexRoute,
};

const PCompartilhamentoIdRouteWithChildren =
  PCompartilhamentoIdRoute._addFileChildren(PCompartilhamentoIdRouteChildren);

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute;
  '/a': typeof ARouteRouteWithChildren;
  '/auth': typeof AuthRouteRouteWithChildren;
  '/a/cliente': typeof AClienteRouteRouteWithChildren;
  '/a/cobranca-boleto': typeof ACobrancaBoletoRouteRouteWithChildren;
  '/a/cobranca-cartao': typeof ACobrancaCartaoRouteRouteWithChildren;
  '/a/cobranca-pix': typeof ACobrancaPixRouteRouteWithChildren;
  '/a/comissao': typeof AComissaoRouteRouteWithChildren;
  '/a/compra': typeof ACompraRouteRouteWithChildren;
  '/a/contas-pagar': typeof AContasPagarRouteRouteWithChildren;
  '/a/contas-receber': typeof AContasReceberRouteRouteWithChildren;
  '/a/cupom-fiscal': typeof ACupomFiscalRouteRouteWithChildren;
  '/a/debug': typeof ADebugRouteRouteWithChildren;
  '/a/estoque': typeof AEstoqueRouteRouteWithChildren;
  '/a/extrato-boleto': typeof AExtratoBoletoRouteRouteWithChildren;
  '/a/extrato-pix': typeof AExtratoPixRouteRouteWithChildren;
  '/a/fornecedor': typeof AFornecedorRouteRouteWithChildren;
  '/a/manifesto-carga': typeof AManifestoCargaRouteRouteWithChildren;
  '/a/movimentacoes': typeof AMovimentacoesRouteRouteWithChildren;
  '/a/nota-fiscal': typeof ANotaFiscalRouteRouteWithChildren;
  '/a/notificacao': typeof ANotificacaoRouteRouteWithChildren;
  '/a/pagfor': typeof APagforRouteRouteWithChildren;
  '/a/produto': typeof AProdutoRouteRouteWithChildren;
  '/a/regra-fiscal': typeof ARegraFiscalRouteRouteWithChildren;
  '/a/romaneio': typeof ARomaneioRouteRouteWithChildren;
  '/a/transportador': typeof ATransportadorRouteRouteWithChildren;
  '/a/venda': typeof AVendaRouteRouteWithChildren;
  '/a/venda-externa': typeof AVendaExternaRouteRouteWithChildren;
  '/admin': typeof AdminARouteRouteWithChildren;
  '/a/pdv': typeof APdvRoute;
  '/admin/auth': typeof AdminAuthRouteWithChildren;
  '/auth/acesso-negado': typeof AuthAcessoNegadoRoute;
  '/auth/cadastro': typeof AuthCadastroRoute;
  '/auth/cadastro-fim': typeof AuthCadastroFimRoute;
  '/auth/conta-bloqueada': typeof AuthContaBloqueadaRoute;
  '/auth/login': typeof AuthLoginRoute;
  '/auth/recuperar-senha': typeof AuthRecuperarSenhaRoute;
  '/auth/redefinir-senha': typeof AuthRedefinirSenhaRoute;
  '/auth/solicitar-validacao': typeof AuthSolicitarValidacaoRoute;
  '/p/erro': typeof PErroRoute;
  '/a/configuracao/empresa': typeof AConfiguracaoEmpresaRouteRouteWithChildren;
  '/p/catalogo/$slug': typeof PCatalogoSlugRouteRouteWithChildren;
  '/a/cobranca-boleto/rotinas-bancarias': typeof ACobrancaBoletoRotinasBancariasRoute;
  '/a/configuracao/usuario': typeof AConfiguracaoUsuarioRoute;
  '/a/estoque/conferencia': typeof AEstoqueConferenciaRoute;
  '/a/estoque/movimentacao': typeof AEstoqueMovimentacaoRoute;
  '/a/impressao/$filetype': typeof AImpressaoFiletypeRoute;
  '/a/notificacao/listagem': typeof ANotificacaoListagemRoute;
  '/a/pagfor/importar': typeof APagforImportarRoute;
  '/a/pagfor/listagem': typeof APagforListagemRoute;
  '/a/suporte/instrucoes-de-uso': typeof ASuporteInstrucoesDeUsoRoute;
  '/admin/empresa': typeof AdminAEmpresaRoute;
  '/admin/erro': typeof AdminAErroRoute;
  '/admin/representante': typeof AdminARepresentanteRoute;
  '/admin/sql': typeof AdminASqlRoute;
  '/admin/auth/login': typeof AdminAuthLoginRoute;
  '/p/cliente/$slug': typeof PClienteSlugRoute;
  '/p/compartilhamento/$id': typeof PCompartilhamentoIdRouteWithChildren;
  '/p/contrato/$id': typeof PContratoIdRoute;
  '/p/impressao/$filetype': typeof PImpressaoFiletypeRoute;
  '/a/cliente/': typeof AClienteIndexRoute;
  '/a/cobranca-boleto/': typeof ACobrancaBoletoIndexRoute;
  '/a/cobranca-cartao/': typeof ACobrancaCartaoIndexRoute;
  '/a/cobranca-pix/': typeof ACobrancaPixIndexRoute;
  '/a/comissao/': typeof AComissaoIndexRoute;
  '/a/compra/': typeof ACompraIndexRoute;
  '/a/contas-pagar/': typeof AContasPagarIndexRoute;
  '/a/contas-receber/': typeof AContasReceberIndexRoute;
  '/a/contrato': typeof AContratoIndexRoute;
  '/a/cupom-fiscal/': typeof ACupomFiscalIndexRoute;
  '/a/debug/': typeof ADebugIndexRoute;
  '/a/extrato-boleto/': typeof AExtratoBoletoIndexRoute;
  '/a/extrato-pix/': typeof AExtratoPixIndexRoute;
  '/a/fornecedor/': typeof AFornecedorIndexRoute;
  '/a/manifesto-carga/': typeof AManifestoCargaIndexRoute;
  '/a/movimentacoes/': typeof AMovimentacoesIndexRoute;
  '/a/nota-fiscal/': typeof ANotaFiscalIndexRoute;
  '/a/painel-gerencial': typeof APainelGerencialIndexRoute;
  '/a/produto/': typeof AProdutoIndexRoute;
  '/a/regra-fiscal/': typeof ARegraFiscalIndexRoute;
  '/a/romaneio/': typeof ARomaneioIndexRoute;
  '/a/suporte': typeof ASuporteIndexRoute;
  '/a/transportador/': typeof ATransportadorIndexRoute;
  '/a/venda-externa/': typeof AVendaExternaIndexRoute;
  '/a/cliente/$mode/$idpk': typeof AClienteModeIdpkRoute;
  '/a/cobranca-boleto/$mode/$idpk': typeof ACobrancaBoletoModeIdpkRoute;
  '/a/cobranca-cartao/$mode/$idpk': typeof ACobrancaCartaoModeIdpkRoute;
  '/a/compra/$mode/$idpk': typeof ACompraModeIdpkRoute;
  '/a/contas-pagar/$mode/$idpk': typeof AContasPagarModeIdpkRoute;
  '/a/contas-receber/$mode/$idpk': typeof AContasReceberModeIdpkRoute;
  '/a/contrato/$mode/$idpk': typeof AContratoModeIdpkRoute;
  '/a/estoque/inventario/novo': typeof AEstoqueInventarioNovoRoute;
  '/a/importacao/cadastro/$tipo': typeof AImportacaoCadastroTipoRoute;
  '/a/importacao/xml/$tipo': typeof AImportacaoXmlTipoRoute;
  '/a/manifesto-carga/$mode/$idpk': typeof AManifestoCargaModeIdpkRoute;
  '/a/movimentacoes/extrato/$idpk': typeof AMovimentacoesExtratoIdpkRoute;
  '/a/nota-fiscal/$mode/$idpk': typeof ANotaFiscalModeIdpkRoute;
  '/a/pagfor/$mode/$idpk': typeof APagforModeIdpkRoute;
  '/a/romaneio/$mode/$idpk': typeof ARomaneioModeIdpkRoute;
  '/a/venda-externa/$mode/$idpk': typeof AVendaExternaModeIdpkRoute;
  '/admin/configuracao/representante': typeof AdminAConfiguracaoRepresentanteRoute;
  '/p/catalogo/$slug/carrinho': typeof PCatalogoSlugCarrinhoRoute;
  '/p/catalogo/$slug/finalizar': typeof PCatalogoSlugFinalizarRoute;
  '/p/catalogo/$slug/listagem': typeof PCatalogoSlugListagemRoute;
  '/p/conta-digital/termos-de-uso/$base64': typeof PContaDigitalTermosDeUsoBase64Route;
  '/a/configuracao/empresa/': typeof AConfiguracaoEmpresaIndexRoute;
  '/a/estoque/inventario': typeof AEstoqueInventarioIndexRoute;
  '/a/relatorio/$tipo': typeof ARelatorioTipoIndexRoute;
  '/a/venda/$tipo': typeof AVendaTipoIndexRoute;
  '/p/catalogo/$slug/': typeof PCatalogoSlugIndexRoute;
  '/p/compartilhamento/$id/': typeof PCompartilhamentoIdIndexRoute;
  '/p/pagamento/$id': typeof PPagamentoIdIndexRoute;
  '/a/estoque/inventario/editar/$idpk': typeof AEstoqueInventarioEditarIdpkRoute;
  '/a/movimentacoes/$mode/$contaIdpk/$idpk': typeof AMovimentacoesModeContaIdpkIdpkRoute;
  '/a/venda/$tipo/$mode/$idpk': typeof AVendaTipoModeIdpkRoute;
  '/p/catalogo/$slug/produto/$idpk': typeof PCatalogoSlugProdutoIdpkRoute;
  '/a/nota-fiscal/venda-externa/$tipoVendaExterna/$mode/$veeIdpk': typeof ANotaFiscalVendaExternaTipoVendaExternaModeVeeIdpkRoute;
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute;
  '/a': typeof ARouteRouteWithChildren;
  '/auth': typeof AuthRouteRouteWithChildren;
  '/a/estoque': typeof AEstoqueRouteRouteWithChildren;
  '/a/notificacao': typeof ANotificacaoRouteRouteWithChildren;
  '/a/pagfor': typeof APagforRouteRouteWithChildren;
  '/a/venda': typeof AVendaRouteRouteWithChildren;
  '/admin': typeof AdminARouteRouteWithChildren;
  '/a/pdv': typeof APdvRoute;
  '/admin/auth': typeof AdminAuthRouteWithChildren;
  '/auth/acesso-negado': typeof AuthAcessoNegadoRoute;
  '/auth/cadastro': typeof AuthCadastroRoute;
  '/auth/cadastro-fim': typeof AuthCadastroFimRoute;
  '/auth/conta-bloqueada': typeof AuthContaBloqueadaRoute;
  '/auth/login': typeof AuthLoginRoute;
  '/auth/recuperar-senha': typeof AuthRecuperarSenhaRoute;
  '/auth/redefinir-senha': typeof AuthRedefinirSenhaRoute;
  '/auth/solicitar-validacao': typeof AuthSolicitarValidacaoRoute;
  '/p/erro': typeof PErroRoute;
  '/a/cobranca-boleto/rotinas-bancarias': typeof ACobrancaBoletoRotinasBancariasRoute;
  '/a/configuracao/usuario': typeof AConfiguracaoUsuarioRoute;
  '/a/estoque/conferencia': typeof AEstoqueConferenciaRoute;
  '/a/estoque/movimentacao': typeof AEstoqueMovimentacaoRoute;
  '/a/impressao/$filetype': typeof AImpressaoFiletypeRoute;
  '/a/notificacao/listagem': typeof ANotificacaoListagemRoute;
  '/a/pagfor/importar': typeof APagforImportarRoute;
  '/a/pagfor/listagem': typeof APagforListagemRoute;
  '/a/suporte/instrucoes-de-uso': typeof ASuporteInstrucoesDeUsoRoute;
  '/admin/empresa': typeof AdminAEmpresaRoute;
  '/admin/erro': typeof AdminAErroRoute;
  '/admin/representante': typeof AdminARepresentanteRoute;
  '/admin/sql': typeof AdminASqlRoute;
  '/admin/auth/login': typeof AdminAuthLoginRoute;
  '/p/cliente/$slug': typeof PClienteSlugRoute;
  '/p/contrato/$id': typeof PContratoIdRoute;
  '/p/impressao/$filetype': typeof PImpressaoFiletypeRoute;
  '/a/cliente': typeof AClienteIndexRoute;
  '/a/cobranca-boleto': typeof ACobrancaBoletoIndexRoute;
  '/a/cobranca-cartao': typeof ACobrancaCartaoIndexRoute;
  '/a/cobranca-pix': typeof ACobrancaPixIndexRoute;
  '/a/comissao': typeof AComissaoIndexRoute;
  '/a/compra': typeof ACompraIndexRoute;
  '/a/contas-pagar': typeof AContasPagarIndexRoute;
  '/a/contas-receber': typeof AContasReceberIndexRoute;
  '/a/contrato': typeof AContratoIndexRoute;
  '/a/cupom-fiscal': typeof ACupomFiscalIndexRoute;
  '/a/debug': typeof ADebugIndexRoute;
  '/a/extrato-boleto': typeof AExtratoBoletoIndexRoute;
  '/a/extrato-pix': typeof AExtratoPixIndexRoute;
  '/a/fornecedor': typeof AFornecedorIndexRoute;
  '/a/manifesto-carga': typeof AManifestoCargaIndexRoute;
  '/a/movimentacoes': typeof AMovimentacoesIndexRoute;
  '/a/nota-fiscal': typeof ANotaFiscalIndexRoute;
  '/a/painel-gerencial': typeof APainelGerencialIndexRoute;
  '/a/produto': typeof AProdutoIndexRoute;
  '/a/regra-fiscal': typeof ARegraFiscalIndexRoute;
  '/a/romaneio': typeof ARomaneioIndexRoute;
  '/a/suporte': typeof ASuporteIndexRoute;
  '/a/transportador': typeof ATransportadorIndexRoute;
  '/a/venda-externa': typeof AVendaExternaIndexRoute;
  '/a/cliente/$mode/$idpk': typeof AClienteModeIdpkRoute;
  '/a/cobranca-boleto/$mode/$idpk': typeof ACobrancaBoletoModeIdpkRoute;
  '/a/cobranca-cartao/$mode/$idpk': typeof ACobrancaCartaoModeIdpkRoute;
  '/a/compra/$mode/$idpk': typeof ACompraModeIdpkRoute;
  '/a/contas-pagar/$mode/$idpk': typeof AContasPagarModeIdpkRoute;
  '/a/contas-receber/$mode/$idpk': typeof AContasReceberModeIdpkRoute;
  '/a/contrato/$mode/$idpk': typeof AContratoModeIdpkRoute;
  '/a/estoque/inventario/novo': typeof AEstoqueInventarioNovoRoute;
  '/a/importacao/cadastro/$tipo': typeof AImportacaoCadastroTipoRoute;
  '/a/importacao/xml/$tipo': typeof AImportacaoXmlTipoRoute;
  '/a/manifesto-carga/$mode/$idpk': typeof AManifestoCargaModeIdpkRoute;
  '/a/movimentacoes/extrato/$idpk': typeof AMovimentacoesExtratoIdpkRoute;
  '/a/nota-fiscal/$mode/$idpk': typeof ANotaFiscalModeIdpkRoute;
  '/a/pagfor/$mode/$idpk': typeof APagforModeIdpkRoute;
  '/a/romaneio/$mode/$idpk': typeof ARomaneioModeIdpkRoute;
  '/a/venda-externa/$mode/$idpk': typeof AVendaExternaModeIdpkRoute;
  '/admin/configuracao/representante': typeof AdminAConfiguracaoRepresentanteRoute;
  '/p/catalogo/$slug/carrinho': typeof PCatalogoSlugCarrinhoRoute;
  '/p/catalogo/$slug/finalizar': typeof PCatalogoSlugFinalizarRoute;
  '/p/catalogo/$slug/listagem': typeof PCatalogoSlugListagemRoute;
  '/p/conta-digital/termos-de-uso/$base64': typeof PContaDigitalTermosDeUsoBase64Route;
  '/a/configuracao/empresa': typeof AConfiguracaoEmpresaIndexRoute;
  '/a/estoque/inventario': typeof AEstoqueInventarioIndexRoute;
  '/a/relatorio/$tipo': typeof ARelatorioTipoIndexRoute;
  '/a/venda/$tipo': typeof AVendaTipoIndexRoute;
  '/p/catalogo/$slug': typeof PCatalogoSlugIndexRoute;
  '/p/compartilhamento/$id': typeof PCompartilhamentoIdIndexRoute;
  '/p/pagamento/$id': typeof PPagamentoIdIndexRoute;
  '/a/estoque/inventario/editar/$idpk': typeof AEstoqueInventarioEditarIdpkRoute;
  '/a/movimentacoes/$mode/$contaIdpk/$idpk': typeof AMovimentacoesModeContaIdpkIdpkRoute;
  '/a/venda/$tipo/$mode/$idpk': typeof AVendaTipoModeIdpkRoute;
  '/p/catalogo/$slug/produto/$idpk': typeof PCatalogoSlugProdutoIdpkRoute;
  '/a/nota-fiscal/venda-externa/$tipoVendaExterna/$mode/$veeIdpk': typeof ANotaFiscalVendaExternaTipoVendaExternaModeVeeIdpkRoute;
}

export interface FileRoutesById {
  __root__: typeof rootRoute;
  '/': typeof IndexRoute;
  '/a': typeof ARouteRouteWithChildren;
  '/auth': typeof AuthRouteRouteWithChildren;
  '/a/cliente': typeof AClienteRouteRouteWithChildren;
  '/a/cobranca-boleto': typeof ACobrancaBoletoRouteRouteWithChildren;
  '/a/cobranca-cartao': typeof ACobrancaCartaoRouteRouteWithChildren;
  '/a/cobranca-pix': typeof ACobrancaPixRouteRouteWithChildren;
  '/a/comissao': typeof AComissaoRouteRouteWithChildren;
  '/a/compra': typeof ACompraRouteRouteWithChildren;
  '/a/contas-pagar': typeof AContasPagarRouteRouteWithChildren;
  '/a/contas-receber': typeof AContasReceberRouteRouteWithChildren;
  '/a/cupom-fiscal': typeof ACupomFiscalRouteRouteWithChildren;
  '/a/debug': typeof ADebugRouteRouteWithChildren;
  '/a/estoque': typeof AEstoqueRouteRouteWithChildren;
  '/a/extrato-boleto': typeof AExtratoBoletoRouteRouteWithChildren;
  '/a/extrato-pix': typeof AExtratoPixRouteRouteWithChildren;
  '/a/fornecedor': typeof AFornecedorRouteRouteWithChildren;
  '/a/manifesto-carga': typeof AManifestoCargaRouteRouteWithChildren;
  '/a/movimentacoes': typeof AMovimentacoesRouteRouteWithChildren;
  '/a/nota-fiscal': typeof ANotaFiscalRouteRouteWithChildren;
  '/a/notificacao': typeof ANotificacaoRouteRouteWithChildren;
  '/a/pagfor': typeof APagforRouteRouteWithChildren;
  '/a/produto': typeof AProdutoRouteRouteWithChildren;
  '/a/regra-fiscal': typeof ARegraFiscalRouteRouteWithChildren;
  '/a/romaneio': typeof ARomaneioRouteRouteWithChildren;
  '/a/transportador': typeof ATransportadorRouteRouteWithChildren;
  '/a/venda': typeof AVendaRouteRouteWithChildren;
  '/a/venda-externa': typeof AVendaExternaRouteRouteWithChildren;
  '/admin': typeof AdminRouteWithChildren;
  '/admin/_a': typeof AdminARouteRouteWithChildren;
  '/a/pdv': typeof APdvRoute;
  '/admin/auth': typeof AdminAuthRouteWithChildren;
  '/auth/acesso-negado': typeof AuthAcessoNegadoRoute;
  '/auth/cadastro': typeof AuthCadastroRoute;
  '/auth/cadastro-fim': typeof AuthCadastroFimRoute;
  '/auth/conta-bloqueada': typeof AuthContaBloqueadaRoute;
  '/auth/login': typeof AuthLoginRoute;
  '/auth/recuperar-senha': typeof AuthRecuperarSenhaRoute;
  '/auth/redefinir-senha': typeof AuthRedefinirSenhaRoute;
  '/auth/solicitar-validacao': typeof AuthSolicitarValidacaoRoute;
  '/p/erro': typeof PErroRoute;
  '/a/configuracao/empresa': typeof AConfiguracaoEmpresaRouteRouteWithChildren;
  '/p/catalogo/$slug': typeof PCatalogoSlugRouteRouteWithChildren;
  '/a/cobranca-boleto/rotinas-bancarias': typeof ACobrancaBoletoRotinasBancariasRoute;
  '/a/configuracao/usuario': typeof AConfiguracaoUsuarioRoute;
  '/a/estoque/conferencia': typeof AEstoqueConferenciaRoute;
  '/a/estoque/movimentacao': typeof AEstoqueMovimentacaoRoute;
  '/a/impressao/$filetype': typeof AImpressaoFiletypeRoute;
  '/a/notificacao/listagem': typeof ANotificacaoListagemRoute;
  '/a/pagfor/importar': typeof APagforImportarRoute;
  '/a/pagfor/listagem': typeof APagforListagemRoute;
  '/a/suporte/instrucoes-de-uso': typeof ASuporteInstrucoesDeUsoRoute;
  '/admin/_a/empresa': typeof AdminAEmpresaRoute;
  '/admin/_a/erro': typeof AdminAErroRoute;
  '/admin/_a/representante': typeof AdminARepresentanteRoute;
  '/admin/_a/sql': typeof AdminASqlRoute;
  '/admin/auth/login': typeof AdminAuthLoginRoute;
  '/p/cliente/$slug': typeof PClienteSlugRoute;
  '/p/compartilhamento/$id': typeof PCompartilhamentoIdRouteWithChildren;
  '/p/contrato/$id': typeof PContratoIdRoute;
  '/p/impressao/$filetype': typeof PImpressaoFiletypeRoute;
  '/a/cliente/': typeof AClienteIndexRoute;
  '/a/cobranca-boleto/': typeof ACobrancaBoletoIndexRoute;
  '/a/cobranca-cartao/': typeof ACobrancaCartaoIndexRoute;
  '/a/cobranca-pix/': typeof ACobrancaPixIndexRoute;
  '/a/comissao/': typeof AComissaoIndexRoute;
  '/a/compra/': typeof ACompraIndexRoute;
  '/a/contas-pagar/': typeof AContasPagarIndexRoute;
  '/a/contas-receber/': typeof AContasReceberIndexRoute;
  '/a/contrato/': typeof AContratoIndexRoute;
  '/a/cupom-fiscal/': typeof ACupomFiscalIndexRoute;
  '/a/debug/': typeof ADebugIndexRoute;
  '/a/extrato-boleto/': typeof AExtratoBoletoIndexRoute;
  '/a/extrato-pix/': typeof AExtratoPixIndexRoute;
  '/a/fornecedor/': typeof AFornecedorIndexRoute;
  '/a/manifesto-carga/': typeof AManifestoCargaIndexRoute;
  '/a/movimentacoes/': typeof AMovimentacoesIndexRoute;
  '/a/nota-fiscal/': typeof ANotaFiscalIndexRoute;
  '/a/painel-gerencial/': typeof APainelGerencialIndexRoute;
  '/a/produto/': typeof AProdutoIndexRoute;
  '/a/regra-fiscal/': typeof ARegraFiscalIndexRoute;
  '/a/romaneio/': typeof ARomaneioIndexRoute;
  '/a/suporte/': typeof ASuporteIndexRoute;
  '/a/transportador/': typeof ATransportadorIndexRoute;
  '/a/venda-externa/': typeof AVendaExternaIndexRoute;
  '/a/cliente/$mode/$idpk': typeof AClienteModeIdpkRoute;
  '/a/cobranca-boleto/$mode/$idpk': typeof ACobrancaBoletoModeIdpkRoute;
  '/a/cobranca-cartao/$mode/$idpk': typeof ACobrancaCartaoModeIdpkRoute;
  '/a/compra/$mode/$idpk': typeof ACompraModeIdpkRoute;
  '/a/contas-pagar/$mode/$idpk': typeof AContasPagarModeIdpkRoute;
  '/a/contas-receber/$mode/$idpk': typeof AContasReceberModeIdpkRoute;
  '/a/contrato/$mode/$idpk': typeof AContratoModeIdpkRoute;
  '/a/estoque/inventario/novo': typeof AEstoqueInventarioNovoRoute;
  '/a/importacao/cadastro/$tipo': typeof AImportacaoCadastroTipoRoute;
  '/a/importacao/xml/$tipo': typeof AImportacaoXmlTipoRoute;
  '/a/manifesto-carga/$mode/$idpk': typeof AManifestoCargaModeIdpkRoute;
  '/a/movimentacoes/extrato/$idpk': typeof AMovimentacoesExtratoIdpkRoute;
  '/a/nota-fiscal/$mode/$idpk': typeof ANotaFiscalModeIdpkRoute;
  '/a/pagfor/$mode/$idpk': typeof APagforModeIdpkRoute;
  '/a/romaneio/$mode/$idpk': typeof ARomaneioModeIdpkRoute;
  '/a/venda-externa/$mode/$idpk': typeof AVendaExternaModeIdpkRoute;
  '/admin/_a/configuracao/representante': typeof AdminAConfiguracaoRepresentanteRoute;
  '/p/catalogo/$slug/carrinho': typeof PCatalogoSlugCarrinhoRoute;
  '/p/catalogo/$slug/finalizar': typeof PCatalogoSlugFinalizarRoute;
  '/p/catalogo/$slug/listagem': typeof PCatalogoSlugListagemRoute;
  '/p/conta-digital/termos-de-uso/$base64': typeof PContaDigitalTermosDeUsoBase64Route;
  '/a/configuracao/empresa/': typeof AConfiguracaoEmpresaIndexRoute;
  '/a/estoque/inventario/': typeof AEstoqueInventarioIndexRoute;
  '/a/relatorio/$tipo/': typeof ARelatorioTipoIndexRoute;
  '/a/venda/$tipo/': typeof AVendaTipoIndexRoute;
  '/p/catalogo/$slug/': typeof PCatalogoSlugIndexRoute;
  '/p/compartilhamento/$id/': typeof PCompartilhamentoIdIndexRoute;
  '/p/pagamento/$id/': typeof PPagamentoIdIndexRoute;
  '/a/estoque/inventario/editar/$idpk': typeof AEstoqueInventarioEditarIdpkRoute;
  '/a/movimentacoes/$mode/$contaIdpk/$idpk': typeof AMovimentacoesModeContaIdpkIdpkRoute;
  '/a/venda/$tipo/$mode/$idpk': typeof AVendaTipoModeIdpkRoute;
  '/p/catalogo/$slug/produto/$idpk': typeof PCatalogoSlugProdutoIdpkRoute;
  '/a/nota-fiscal/venda-externa/$tipoVendaExterna/$mode/$veeIdpk': typeof ANotaFiscalVendaExternaTipoVendaExternaModeVeeIdpkRoute;
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath;
  fullPaths:
    | '/'
    | '/a'
    | '/auth'
    | '/a/cliente'
    | '/a/cobranca-boleto'
    | '/a/cobranca-cartao'
    | '/a/cobranca-pix'
    | '/a/comissao'
    | '/a/compra'
    | '/a/contas-pagar'
    | '/a/contas-receber'
    | '/a/cupom-fiscal'
    | '/a/debug'
    | '/a/estoque'
    | '/a/extrato-boleto'
    | '/a/extrato-pix'
    | '/a/fornecedor'
    | '/a/manifesto-carga'
    | '/a/movimentacoes'
    | '/a/nota-fiscal'
    | '/a/notificacao'
    | '/a/pagfor'
    | '/a/produto'
    | '/a/regra-fiscal'
    | '/a/romaneio'
    | '/a/transportador'
    | '/a/venda'
    | '/a/venda-externa'
    | '/admin'
    | '/a/pdv'
    | '/admin/auth'
    | '/auth/acesso-negado'
    | '/auth/cadastro'
    | '/auth/cadastro-fim'
    | '/auth/conta-bloqueada'
    | '/auth/login'
    | '/auth/recuperar-senha'
    | '/auth/redefinir-senha'
    | '/auth/solicitar-validacao'
    | '/p/erro'
    | '/a/configuracao/empresa'
    | '/p/catalogo/$slug'
    | '/a/cobranca-boleto/rotinas-bancarias'
    | '/a/configuracao/usuario'
    | '/a/estoque/conferencia'
    | '/a/estoque/movimentacao'
    | '/a/impressao/$filetype'
    | '/a/notificacao/listagem'
    | '/a/pagfor/importar'
    | '/a/pagfor/listagem'
    | '/a/suporte/instrucoes-de-uso'
    | '/admin/empresa'
    | '/admin/erro'
    | '/admin/representante'
    | '/admin/sql'
    | '/admin/auth/login'
    | '/p/cliente/$slug'
    | '/p/compartilhamento/$id'
    | '/p/contrato/$id'
    | '/p/impressao/$filetype'
    | '/a/cliente/'
    | '/a/cobranca-boleto/'
    | '/a/cobranca-cartao/'
    | '/a/cobranca-pix/'
    | '/a/comissao/'
    | '/a/compra/'
    | '/a/contas-pagar/'
    | '/a/contas-receber/'
    | '/a/contrato'
    | '/a/cupom-fiscal/'
    | '/a/debug/'
    | '/a/extrato-boleto/'
    | '/a/extrato-pix/'
    | '/a/fornecedor/'
    | '/a/manifesto-carga/'
    | '/a/movimentacoes/'
    | '/a/nota-fiscal/'
    | '/a/painel-gerencial'
    | '/a/produto/'
    | '/a/regra-fiscal/'
    | '/a/romaneio/'
    | '/a/suporte'
    | '/a/transportador/'
    | '/a/venda-externa/'
    | '/a/cliente/$mode/$idpk'
    | '/a/cobranca-boleto/$mode/$idpk'
    | '/a/cobranca-cartao/$mode/$idpk'
    | '/a/compra/$mode/$idpk'
    | '/a/contas-pagar/$mode/$idpk'
    | '/a/contas-receber/$mode/$idpk'
    | '/a/contrato/$mode/$idpk'
    | '/a/estoque/inventario/novo'
    | '/a/importacao/cadastro/$tipo'
    | '/a/importacao/xml/$tipo'
    | '/a/manifesto-carga/$mode/$idpk'
    | '/a/movimentacoes/extrato/$idpk'
    | '/a/nota-fiscal/$mode/$idpk'
    | '/a/pagfor/$mode/$idpk'
    | '/a/romaneio/$mode/$idpk'
    | '/a/venda-externa/$mode/$idpk'
    | '/admin/configuracao/representante'
    | '/p/catalogo/$slug/carrinho'
    | '/p/catalogo/$slug/finalizar'
    | '/p/catalogo/$slug/listagem'
    | '/p/conta-digital/termos-de-uso/$base64'
    | '/a/configuracao/empresa/'
    | '/a/estoque/inventario'
    | '/a/relatorio/$tipo'
    | '/a/venda/$tipo'
    | '/p/catalogo/$slug/'
    | '/p/compartilhamento/$id/'
    | '/p/pagamento/$id'
    | '/a/estoque/inventario/editar/$idpk'
    | '/a/movimentacoes/$mode/$contaIdpk/$idpk'
    | '/a/venda/$tipo/$mode/$idpk'
    | '/p/catalogo/$slug/produto/$idpk'
    | '/a/nota-fiscal/venda-externa/$tipoVendaExterna/$mode/$veeIdpk';
  fileRoutesByTo: FileRoutesByTo;
  to:
    | '/'
    | '/a'
    | '/auth'
    | '/a/estoque'
    | '/a/notificacao'
    | '/a/pagfor'
    | '/a/venda'
    | '/admin'
    | '/a/pdv'
    | '/admin/auth'
    | '/auth/acesso-negado'
    | '/auth/cadastro'
    | '/auth/cadastro-fim'
    | '/auth/conta-bloqueada'
    | '/auth/login'
    | '/auth/recuperar-senha'
    | '/auth/redefinir-senha'
    | '/auth/solicitar-validacao'
    | '/p/erro'
    | '/a/cobranca-boleto/rotinas-bancarias'
    | '/a/configuracao/usuario'
    | '/a/estoque/conferencia'
    | '/a/estoque/movimentacao'
    | '/a/impressao/$filetype'
    | '/a/notificacao/listagem'
    | '/a/pagfor/importar'
    | '/a/pagfor/listagem'
    | '/a/suporte/instrucoes-de-uso'
    | '/admin/empresa'
    | '/admin/erro'
    | '/admin/representante'
    | '/admin/sql'
    | '/admin/auth/login'
    | '/p/cliente/$slug'
    | '/p/contrato/$id'
    | '/p/impressao/$filetype'
    | '/a/cliente'
    | '/a/cobranca-boleto'
    | '/a/cobranca-cartao'
    | '/a/cobranca-pix'
    | '/a/comissao'
    | '/a/compra'
    | '/a/contas-pagar'
    | '/a/contas-receber'
    | '/a/contrato'
    | '/a/cupom-fiscal'
    | '/a/debug'
    | '/a/extrato-boleto'
    | '/a/extrato-pix'
    | '/a/fornecedor'
    | '/a/manifesto-carga'
    | '/a/movimentacoes'
    | '/a/nota-fiscal'
    | '/a/painel-gerencial'
    | '/a/produto'
    | '/a/regra-fiscal'
    | '/a/romaneio'
    | '/a/suporte'
    | '/a/transportador'
    | '/a/venda-externa'
    | '/a/cliente/$mode/$idpk'
    | '/a/cobranca-boleto/$mode/$idpk'
    | '/a/cobranca-cartao/$mode/$idpk'
    | '/a/compra/$mode/$idpk'
    | '/a/contas-pagar/$mode/$idpk'
    | '/a/contas-receber/$mode/$idpk'
    | '/a/contrato/$mode/$idpk'
    | '/a/estoque/inventario/novo'
    | '/a/importacao/cadastro/$tipo'
    | '/a/importacao/xml/$tipo'
    | '/a/manifesto-carga/$mode/$idpk'
    | '/a/movimentacoes/extrato/$idpk'
    | '/a/nota-fiscal/$mode/$idpk'
    | '/a/pagfor/$mode/$idpk'
    | '/a/romaneio/$mode/$idpk'
    | '/a/venda-externa/$mode/$idpk'
    | '/admin/configuracao/representante'
    | '/p/catalogo/$slug/carrinho'
    | '/p/catalogo/$slug/finalizar'
    | '/p/catalogo/$slug/listagem'
    | '/p/conta-digital/termos-de-uso/$base64'
    | '/a/configuracao/empresa'
    | '/a/estoque/inventario'
    | '/a/relatorio/$tipo'
    | '/a/venda/$tipo'
    | '/p/catalogo/$slug'
    | '/p/compartilhamento/$id'
    | '/p/pagamento/$id'
    | '/a/estoque/inventario/editar/$idpk'
    | '/a/movimentacoes/$mode/$contaIdpk/$idpk'
    | '/a/venda/$tipo/$mode/$idpk'
    | '/p/catalogo/$slug/produto/$idpk'
    | '/a/nota-fiscal/venda-externa/$tipoVendaExterna/$mode/$veeIdpk';
  id:
    | '__root__'
    | '/'
    | '/a'
    | '/auth'
    | '/a/cliente'
    | '/a/cobranca-boleto'
    | '/a/cobranca-cartao'
    | '/a/cobranca-pix'
    | '/a/comissao'
    | '/a/compra'
    | '/a/contas-pagar'
    | '/a/contas-receber'
    | '/a/cupom-fiscal'
    | '/a/debug'
    | '/a/estoque'
    | '/a/extrato-boleto'
    | '/a/extrato-pix'
    | '/a/fornecedor'
    | '/a/manifesto-carga'
    | '/a/movimentacoes'
    | '/a/nota-fiscal'
    | '/a/notificacao'
    | '/a/pagfor'
    | '/a/produto'
    | '/a/regra-fiscal'
    | '/a/romaneio'
    | '/a/transportador'
    | '/a/venda'
    | '/a/venda-externa'
    | '/admin'
    | '/admin/_a'
    | '/a/pdv'
    | '/admin/auth'
    | '/auth/acesso-negado'
    | '/auth/cadastro'
    | '/auth/cadastro-fim'
    | '/auth/conta-bloqueada'
    | '/auth/login'
    | '/auth/recuperar-senha'
    | '/auth/redefinir-senha'
    | '/auth/solicitar-validacao'
    | '/p/erro'
    | '/a/configuracao/empresa'
    | '/p/catalogo/$slug'
    | '/a/cobranca-boleto/rotinas-bancarias'
    | '/a/configuracao/usuario'
    | '/a/estoque/conferencia'
    | '/a/estoque/movimentacao'
    | '/a/impressao/$filetype'
    | '/a/notificacao/listagem'
    | '/a/pagfor/importar'
    | '/a/pagfor/listagem'
    | '/a/suporte/instrucoes-de-uso'
    | '/admin/_a/empresa'
    | '/admin/_a/erro'
    | '/admin/_a/representante'
    | '/admin/_a/sql'
    | '/admin/auth/login'
    | '/p/cliente/$slug'
    | '/p/compartilhamento/$id'
    | '/p/contrato/$id'
    | '/p/impressao/$filetype'
    | '/a/cliente/'
    | '/a/cobranca-boleto/'
    | '/a/cobranca-cartao/'
    | '/a/cobranca-pix/'
    | '/a/comissao/'
    | '/a/compra/'
    | '/a/contas-pagar/'
    | '/a/contas-receber/'
    | '/a/contrato/'
    | '/a/cupom-fiscal/'
    | '/a/debug/'
    | '/a/extrato-boleto/'
    | '/a/extrato-pix/'
    | '/a/fornecedor/'
    | '/a/manifesto-carga/'
    | '/a/movimentacoes/'
    | '/a/nota-fiscal/'
    | '/a/painel-gerencial/'
    | '/a/produto/'
    | '/a/regra-fiscal/'
    | '/a/romaneio/'
    | '/a/suporte/'
    | '/a/transportador/'
    | '/a/venda-externa/'
    | '/a/cliente/$mode/$idpk'
    | '/a/cobranca-boleto/$mode/$idpk'
    | '/a/cobranca-cartao/$mode/$idpk'
    | '/a/compra/$mode/$idpk'
    | '/a/contas-pagar/$mode/$idpk'
    | '/a/contas-receber/$mode/$idpk'
    | '/a/contrato/$mode/$idpk'
    | '/a/estoque/inventario/novo'
    | '/a/importacao/cadastro/$tipo'
    | '/a/importacao/xml/$tipo'
    | '/a/manifesto-carga/$mode/$idpk'
    | '/a/movimentacoes/extrato/$idpk'
    | '/a/nota-fiscal/$mode/$idpk'
    | '/a/pagfor/$mode/$idpk'
    | '/a/romaneio/$mode/$idpk'
    | '/a/venda-externa/$mode/$idpk'
    | '/admin/_a/configuracao/representante'
    | '/p/catalogo/$slug/carrinho'
    | '/p/catalogo/$slug/finalizar'
    | '/p/catalogo/$slug/listagem'
    | '/p/conta-digital/termos-de-uso/$base64'
    | '/a/configuracao/empresa/'
    | '/a/estoque/inventario/'
    | '/a/relatorio/$tipo/'
    | '/a/venda/$tipo/'
    | '/p/catalogo/$slug/'
    | '/p/compartilhamento/$id/'
    | '/p/pagamento/$id/'
    | '/a/estoque/inventario/editar/$idpk'
    | '/a/movimentacoes/$mode/$contaIdpk/$idpk'
    | '/a/venda/$tipo/$mode/$idpk'
    | '/p/catalogo/$slug/produto/$idpk'
    | '/a/nota-fiscal/venda-externa/$tipoVendaExterna/$mode/$veeIdpk';
  fileRoutesById: FileRoutesById;
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute;
  ARouteRoute: typeof ARouteRouteWithChildren;
  AuthRouteRoute: typeof AuthRouteRouteWithChildren;
  AdminRoute: typeof AdminRouteWithChildren;
  PErroRoute: typeof PErroRoute;
  PCatalogoSlugRouteRoute: typeof PCatalogoSlugRouteRouteWithChildren;
  PClienteSlugRoute: typeof PClienteSlugRoute;
  PCompartilhamentoIdRoute: typeof PCompartilhamentoIdRouteWithChildren;
  PContratoIdRoute: typeof PContratoIdRoute;
  PImpressaoFiletypeRoute: typeof PImpressaoFiletypeRoute;
  PContaDigitalTermosDeUsoBase64Route: typeof PContaDigitalTermosDeUsoBase64Route;
  PPagamentoIdIndexRoute: typeof PPagamentoIdIndexRoute;
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  ARouteRoute: ARouteRouteWithChildren,
  AuthRouteRoute: AuthRouteRouteWithChildren,
  AdminRoute: AdminRouteWithChildren,
  PErroRoute: PErroRoute,
  PCatalogoSlugRouteRoute: PCatalogoSlugRouteRouteWithChildren,
  PClienteSlugRoute: PClienteSlugRoute,
  PCompartilhamentoIdRoute: PCompartilhamentoIdRouteWithChildren,
  PContratoIdRoute: PContratoIdRoute,
  PImpressaoFiletypeRoute: PImpressaoFiletypeRoute,
  PContaDigitalTermosDeUsoBase64Route: PContaDigitalTermosDeUsoBase64Route,
  PPagamentoIdIndexRoute: PPagamentoIdIndexRoute,
};

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>();

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/a",
        "/auth",
        "/admin",
        "/p/erro",
        "/p/catalogo/$slug",
        "/p/cliente/$slug",
        "/p/compartilhamento/$id",
        "/p/contrato/$id",
        "/p/impressao/$filetype",
        "/p/conta-digital/termos-de-uso/$base64",
        "/p/pagamento/$id/"
      ]
    },
    "/": {
      "filePath": "index.ts"
    },
    "/a": {
      "filePath": "a/route.tsx",
      "children": [
        "/a/cliente",
        "/a/cobranca-boleto",
        "/a/cobranca-cartao",
        "/a/cobranca-pix",
        "/a/comissao",
        "/a/compra",
        "/a/contas-pagar",
        "/a/contas-receber",
        "/a/cupom-fiscal",
        "/a/debug",
        "/a/estoque",
        "/a/extrato-boleto",
        "/a/extrato-pix",
        "/a/fornecedor",
        "/a/manifesto-carga",
        "/a/movimentacoes",
        "/a/nota-fiscal",
        "/a/notificacao",
        "/a/pagfor",
        "/a/produto",
        "/a/regra-fiscal",
        "/a/romaneio",
        "/a/transportador",
        "/a/venda",
        "/a/venda-externa",
        "/a/pdv",
        "/a/configuracao/empresa",
        "/a/configuracao/usuario",
        "/a/impressao/$filetype",
        "/a/suporte/instrucoes-de-uso",
        "/a/contrato/",
        "/a/painel-gerencial/",
        "/a/suporte/",
        "/a/contrato/$mode/$idpk",
        "/a/importacao/cadastro/$tipo",
        "/a/importacao/xml/$tipo",
        "/a/relatorio/$tipo/"
      ]
    },
    "/auth": {
      "filePath": "auth/route.tsx",
      "children": [
        "/auth/acesso-negado",
        "/auth/cadastro",
        "/auth/cadastro-fim",
        "/auth/conta-bloqueada",
        "/auth/login",
        "/auth/recuperar-senha",
        "/auth/redefinir-senha",
        "/auth/solicitar-validacao"
      ]
    },
    "/a/cliente": {
      "filePath": "a/cliente/route.ts",
      "parent": "/a",
      "children": [
        "/a/cliente/",
        "/a/cliente/$mode/$idpk"
      ]
    },
    "/a/cobranca-boleto": {
      "filePath": "a/cobranca-boleto/route.ts",
      "parent": "/a",
      "children": [
        "/a/cobranca-boleto/rotinas-bancarias",
        "/a/cobranca-boleto/",
        "/a/cobranca-boleto/$mode/$idpk"
      ]
    },
    "/a/cobranca-cartao": {
      "filePath": "a/cobranca-cartao/route.ts",
      "parent": "/a",
      "children": [
        "/a/cobranca-cartao/",
        "/a/cobranca-cartao/$mode/$idpk"
      ]
    },
    "/a/cobranca-pix": {
      "filePath": "a/cobranca-pix/route.ts",
      "parent": "/a",
      "children": [
        "/a/cobranca-pix/"
      ]
    },
    "/a/comissao": {
      "filePath": "a/comissao/route.ts",
      "parent": "/a",
      "children": [
        "/a/comissao/"
      ]
    },
    "/a/compra": {
      "filePath": "a/compra/route.ts",
      "parent": "/a",
      "children": [
        "/a/compra/",
        "/a/compra/$mode/$idpk"
      ]
    },
    "/a/contas-pagar": {
      "filePath": "a/contas-pagar/route.ts",
      "parent": "/a",
      "children": [
        "/a/contas-pagar/",
        "/a/contas-pagar/$mode/$idpk"
      ]
    },
    "/a/contas-receber": {
      "filePath": "a/contas-receber/route.ts",
      "parent": "/a",
      "children": [
        "/a/contas-receber/",
        "/a/contas-receber/$mode/$idpk"
      ]
    },
    "/a/cupom-fiscal": {
      "filePath": "a/cupom-fiscal/route.ts",
      "parent": "/a",
      "children": [
        "/a/cupom-fiscal/"
      ]
    },
    "/a/debug": {
      "filePath": "a/debug/route.ts",
      "parent": "/a",
      "children": [
        "/a/debug/"
      ]
    },
    "/a/estoque": {
      "filePath": "a/estoque/route.ts",
      "parent": "/a",
      "children": [
        "/a/estoque/conferencia",
        "/a/estoque/movimentacao",
        "/a/estoque/inventario/novo",
        "/a/estoque/inventario/",
        "/a/estoque/inventario/editar/$idpk"
      ]
    },
    "/a/extrato-boleto": {
      "filePath": "a/extrato-boleto/route.ts",
      "parent": "/a",
      "children": [
        "/a/extrato-boleto/"
      ]
    },
    "/a/extrato-pix": {
      "filePath": "a/extrato-pix/route.ts",
      "parent": "/a",
      "children": [
        "/a/extrato-pix/"
      ]
    },
    "/a/fornecedor": {
      "filePath": "a/fornecedor/route.ts",
      "parent": "/a",
      "children": [
        "/a/fornecedor/"
      ]
    },
    "/a/manifesto-carga": {
      "filePath": "a/manifesto-carga/route.ts",
      "parent": "/a",
      "children": [
        "/a/manifesto-carga/",
        "/a/manifesto-carga/$mode/$idpk"
      ]
    },
    "/a/movimentacoes": {
      "filePath": "a/movimentacoes/route.ts",
      "parent": "/a",
      "children": [
        "/a/movimentacoes/",
        "/a/movimentacoes/extrato/$idpk",
        "/a/movimentacoes/$mode/$contaIdpk/$idpk"
      ]
    },
    "/a/nota-fiscal": {
      "filePath": "a/nota-fiscal/route.ts",
      "parent": "/a",
      "children": [
        "/a/nota-fiscal/",
        "/a/nota-fiscal/$mode/$idpk",
        "/a/nota-fiscal/venda-externa/$tipoVendaExterna/$mode/$veeIdpk"
      ]
    },
    "/a/notificacao": {
      "filePath": "a/notificacao/route.ts",
      "parent": "/a",
      "children": [
        "/a/notificacao/listagem"
      ]
    },
    "/a/pagfor": {
      "filePath": "a/pagfor/route.ts",
      "parent": "/a",
      "children": [
        "/a/pagfor/importar",
        "/a/pagfor/listagem",
        "/a/pagfor/$mode/$idpk"
      ]
    },
    "/a/produto": {
      "filePath": "a/produto/route.ts",
      "parent": "/a",
      "children": [
        "/a/produto/"
      ]
    },
    "/a/regra-fiscal": {
      "filePath": "a/regra-fiscal/route.ts",
      "parent": "/a",
      "children": [
        "/a/regra-fiscal/"
      ]
    },
    "/a/romaneio": {
      "filePath": "a/romaneio/route.ts",
      "parent": "/a",
      "children": [
        "/a/romaneio/",
        "/a/romaneio/$mode/$idpk"
      ]
    },
    "/a/transportador": {
      "filePath": "a/transportador/route.ts",
      "parent": "/a",
      "children": [
        "/a/transportador/"
      ]
    },
    "/a/venda": {
      "filePath": "a/venda/route.ts",
      "parent": "/a",
      "children": [
        "/a/venda/$tipo/",
        "/a/venda/$tipo/$mode/$idpk"
      ]
    },
    "/a/venda-externa": {
      "filePath": "a/venda-externa/route.ts",
      "parent": "/a",
      "children": [
        "/a/venda-externa/",
        "/a/venda-externa/$mode/$idpk"
      ]
    },
    "/admin": {
      "filePath": "admin/_a",
      "children": [
        "/admin/_a",
        "/admin/auth"
      ]
    },
    "/admin/_a": {
      "filePath": "admin/_a/route.tsx",
      "parent": "/admin",
      "children": [
        "/admin/_a/empresa",
        "/admin/_a/erro",
        "/admin/_a/representante",
        "/admin/_a/sql",
        "/admin/_a/configuracao/representante"
      ]
    },
    "/a/pdv": {
      "filePath": "a/pdv.ts",
      "parent": "/a"
    },
    "/admin/auth": {
      "filePath": "admin/auth.tsx",
      "parent": "/admin",
      "children": [
        "/admin/auth/login"
      ]
    },
    "/auth/acesso-negado": {
      "filePath": "auth/acesso-negado.ts",
      "parent": "/auth"
    },
    "/auth/cadastro": {
      "filePath": "auth/cadastro.ts",
      "parent": "/auth"
    },
    "/auth/cadastro-fim": {
      "filePath": "auth/cadastro-fim.ts",
      "parent": "/auth"
    },
    "/auth/conta-bloqueada": {
      "filePath": "auth/conta-bloqueada.ts",
      "parent": "/auth"
    },
    "/auth/login": {
      "filePath": "auth/login.ts",
      "parent": "/auth"
    },
    "/auth/recuperar-senha": {
      "filePath": "auth/recuperar-senha.ts",
      "parent": "/auth"
    },
    "/auth/redefinir-senha": {
      "filePath": "auth/redefinir-senha.ts",
      "parent": "/auth"
    },
    "/auth/solicitar-validacao": {
      "filePath": "auth/solicitar-validacao.ts",
      "parent": "/auth"
    },
    "/p/erro": {
      "filePath": "p/erro.ts"
    },
    "/a/configuracao/empresa": {
      "filePath": "a/configuracao/empresa/route.ts",
      "parent": "/a",
      "children": [
        "/a/configuracao/empresa/"
      ]
    },
    "/p/catalogo/$slug": {
      "filePath": "p/catalogo.$slug/route.ts",
      "children": [
        "/p/catalogo/$slug/carrinho",
        "/p/catalogo/$slug/finalizar",
        "/p/catalogo/$slug/listagem",
        "/p/catalogo/$slug/",
        "/p/catalogo/$slug/produto/$idpk"
      ]
    },
    "/a/cobranca-boleto/rotinas-bancarias": {
      "filePath": "a/cobranca-boleto/rotinas-bancarias.ts",
      "parent": "/a/cobranca-boleto"
    },
    "/a/configuracao/usuario": {
      "filePath": "a/configuracao/usuario.ts",
      "parent": "/a"
    },
    "/a/estoque/conferencia": {
      "filePath": "a/estoque/conferencia.ts",
      "parent": "/a/estoque"
    },
    "/a/estoque/movimentacao": {
      "filePath": "a/estoque/movimentacao.ts",
      "parent": "/a/estoque"
    },
    "/a/impressao/$filetype": {
      "filePath": "a/impressao/$filetype.ts",
      "parent": "/a"
    },
    "/a/notificacao/listagem": {
      "filePath": "a/notificacao/listagem.ts",
      "parent": "/a/notificacao"
    },
    "/a/pagfor/importar": {
      "filePath": "a/pagfor/importar.ts",
      "parent": "/a/pagfor"
    },
    "/a/pagfor/listagem": {
      "filePath": "a/pagfor/listagem.ts",
      "parent": "/a/pagfor"
    },
    "/a/suporte/instrucoes-de-uso": {
      "filePath": "a/suporte/instrucoes-de-uso.ts",
      "parent": "/a"
    },
    "/admin/_a/empresa": {
      "filePath": "admin/_a/empresa.ts",
      "parent": "/admin/_a"
    },
    "/admin/_a/erro": {
      "filePath": "admin/_a/erro.ts",
      "parent": "/admin/_a"
    },
    "/admin/_a/representante": {
      "filePath": "admin/_a/representante.ts",
      "parent": "/admin/_a"
    },
    "/admin/_a/sql": {
      "filePath": "admin/_a/sql.ts",
      "parent": "/admin/_a"
    },
    "/admin/auth/login": {
      "filePath": "admin/auth/login.ts",
      "parent": "/admin/auth"
    },
    "/p/cliente/$slug": {
      "filePath": "p/cliente/$slug.ts"
    },
    "/p/compartilhamento/$id": {
      "filePath": "p/compartilhamento/$id.ts",
      "children": [
        "/p/compartilhamento/$id/"
      ]
    },
    "/p/contrato/$id": {
      "filePath": "p/contrato.$id.ts"
    },
    "/p/impressao/$filetype": {
      "filePath": "p/impressao.$filetype.ts"
    },
    "/a/cliente/": {
      "filePath": "a/cliente/index.ts",
      "parent": "/a/cliente"
    },
    "/a/cobranca-boleto/": {
      "filePath": "a/cobranca-boleto/index.ts",
      "parent": "/a/cobranca-boleto"
    },
    "/a/cobranca-cartao/": {
      "filePath": "a/cobranca-cartao/index.ts",
      "parent": "/a/cobranca-cartao"
    },
    "/a/cobranca-pix/": {
      "filePath": "a/cobranca-pix/index.ts",
      "parent": "/a/cobranca-pix"
    },
    "/a/comissao/": {
      "filePath": "a/comissao/index.ts",
      "parent": "/a/comissao"
    },
    "/a/compra/": {
      "filePath": "a/compra/index.ts",
      "parent": "/a/compra"
    },
    "/a/contas-pagar/": {
      "filePath": "a/contas-pagar/index.ts",
      "parent": "/a/contas-pagar"
    },
    "/a/contas-receber/": {
      "filePath": "a/contas-receber/index.ts",
      "parent": "/a/contas-receber"
    },
    "/a/contrato/": {
      "filePath": "a/contrato/index.ts",
      "parent": "/a"
    },
    "/a/cupom-fiscal/": {
      "filePath": "a/cupom-fiscal/index.ts",
      "parent": "/a/cupom-fiscal"
    },
    "/a/debug/": {
      "filePath": "a/debug/index.ts",
      "parent": "/a/debug"
    },
    "/a/extrato-boleto/": {
      "filePath": "a/extrato-boleto/index.ts",
      "parent": "/a/extrato-boleto"
    },
    "/a/extrato-pix/": {
      "filePath": "a/extrato-pix/index.ts",
      "parent": "/a/extrato-pix"
    },
    "/a/fornecedor/": {
      "filePath": "a/fornecedor/index.ts",
      "parent": "/a/fornecedor"
    },
    "/a/manifesto-carga/": {
      "filePath": "a/manifesto-carga/index.ts",
      "parent": "/a/manifesto-carga"
    },
    "/a/movimentacoes/": {
      "filePath": "a/movimentacoes/index.ts",
      "parent": "/a/movimentacoes"
    },
    "/a/nota-fiscal/": {
      "filePath": "a/nota-fiscal/index.ts",
      "parent": "/a/nota-fiscal"
    },
    "/a/painel-gerencial/": {
      "filePath": "a/painel-gerencial/index.ts",
      "parent": "/a"
    },
    "/a/produto/": {
      "filePath": "a/produto/index.ts",
      "parent": "/a/produto"
    },
    "/a/regra-fiscal/": {
      "filePath": "a/regra-fiscal/index.ts",
      "parent": "/a/regra-fiscal"
    },
    "/a/romaneio/": {
      "filePath": "a/romaneio/index.ts",
      "parent": "/a/romaneio"
    },
    "/a/suporte/": {
      "filePath": "a/suporte/index.ts",
      "parent": "/a"
    },
    "/a/transportador/": {
      "filePath": "a/transportador/index.ts",
      "parent": "/a/transportador"
    },
    "/a/venda-externa/": {
      "filePath": "a/venda-externa/index.ts",
      "parent": "/a/venda-externa"
    },
    "/a/cliente/$mode/$idpk": {
      "filePath": "a/cliente/$mode.$idpk.ts",
      "parent": "/a/cliente"
    },
    "/a/cobranca-boleto/$mode/$idpk": {
      "filePath": "a/cobranca-boleto/$mode.$idpk.ts",
      "parent": "/a/cobranca-boleto"
    },
    "/a/cobranca-cartao/$mode/$idpk": {
      "filePath": "a/cobranca-cartao/$mode.$idpk.ts",
      "parent": "/a/cobranca-cartao"
    },
    "/a/compra/$mode/$idpk": {
      "filePath": "a/compra/$mode.$idpk.ts",
      "parent": "/a/compra"
    },
    "/a/contas-pagar/$mode/$idpk": {
      "filePath": "a/contas-pagar/$mode.$idpk.ts",
      "parent": "/a/contas-pagar"
    },
    "/a/contas-receber/$mode/$idpk": {
      "filePath": "a/contas-receber/$mode.$idpk.ts",
      "parent": "/a/contas-receber"
    },
    "/a/contrato/$mode/$idpk": {
      "filePath": "a/contrato/$mode.$idpk.ts",
      "parent": "/a"
    },
    "/a/estoque/inventario/novo": {
      "filePath": "a/estoque/inventario/novo.ts",
      "parent": "/a/estoque"
    },
    "/a/importacao/cadastro/$tipo": {
      "filePath": "a/importacao/cadastro.$tipo.ts",
      "parent": "/a"
    },
    "/a/importacao/xml/$tipo": {
      "filePath": "a/importacao/xml.$tipo.ts",
      "parent": "/a"
    },
    "/a/manifesto-carga/$mode/$idpk": {
      "filePath": "a/manifesto-carga/$mode.$idpk.ts",
      "parent": "/a/manifesto-carga"
    },
    "/a/movimentacoes/extrato/$idpk": {
      "filePath": "a/movimentacoes/extrato.$idpk.ts",
      "parent": "/a/movimentacoes"
    },
    "/a/nota-fiscal/$mode/$idpk": {
      "filePath": "a/nota-fiscal/$mode.$idpk.ts",
      "parent": "/a/nota-fiscal"
    },
    "/a/pagfor/$mode/$idpk": {
      "filePath": "a/pagfor/$mode.$idpk.ts",
      "parent": "/a/pagfor"
    },
    "/a/romaneio/$mode/$idpk": {
      "filePath": "a/romaneio/$mode.$idpk.ts",
      "parent": "/a/romaneio"
    },
    "/a/venda-externa/$mode/$idpk": {
      "filePath": "a/venda-externa/$mode.$idpk.ts",
      "parent": "/a/venda-externa"
    },
    "/admin/_a/configuracao/representante": {
      "filePath": "admin/_a/configuracao.representante.ts",
      "parent": "/admin/_a"
    },
    "/p/catalogo/$slug/carrinho": {
      "filePath": "p/catalogo.$slug/carrinho.ts",
      "parent": "/p/catalogo/$slug"
    },
    "/p/catalogo/$slug/finalizar": {
      "filePath": "p/catalogo.$slug/finalizar.ts",
      "parent": "/p/catalogo/$slug"
    },
    "/p/catalogo/$slug/listagem": {
      "filePath": "p/catalogo.$slug/listagem.ts",
      "parent": "/p/catalogo/$slug"
    },
    "/p/conta-digital/termos-de-uso/$base64": {
      "filePath": "p/conta-digital/termos-de-uso.$base64.ts"
    },
    "/a/configuracao/empresa/": {
      "filePath": "a/configuracao/empresa/index.ts",
      "parent": "/a/configuracao/empresa"
    },
    "/a/estoque/inventario/": {
      "filePath": "a/estoque/inventario/index.ts",
      "parent": "/a/estoque"
    },
    "/a/relatorio/$tipo/": {
      "filePath": "a/relatorio/$tipo/index.ts",
      "parent": "/a"
    },
    "/a/venda/$tipo/": {
      "filePath": "a/venda/$tipo/index.ts",
      "parent": "/a/venda"
    },
    "/p/catalogo/$slug/": {
      "filePath": "p/catalogo.$slug/index.ts",
      "parent": "/p/catalogo/$slug"
    },
    "/p/compartilhamento/$id/": {
      "filePath": "p/compartilhamento/$id.index.ts",
      "parent": "/p/compartilhamento/$id"
    },
    "/p/pagamento/$id/": {
      "filePath": "p/pagamento.$id/index.ts"
    },
    "/a/estoque/inventario/editar/$idpk": {
      "filePath": "a/estoque/inventario/editar.$idpk.ts",
      "parent": "/a/estoque"
    },
    "/a/movimentacoes/$mode/$contaIdpk/$idpk": {
      "filePath": "a/movimentacoes/$mode.$contaIdpk.$idpk.ts",
      "parent": "/a/movimentacoes"
    },
    "/a/venda/$tipo/$mode/$idpk": {
      "filePath": "a/venda/$tipo/$mode.$idpk.ts",
      "parent": "/a/venda"
    },
    "/p/catalogo/$slug/produto/$idpk": {
      "filePath": "p/catalogo.$slug/produto.$idpk.ts",
      "parent": "/p/catalogo/$slug"
    },
    "/a/nota-fiscal/venda-externa/$tipoVendaExterna/$mode/$veeIdpk": {
      "filePath": "a/nota-fiscal/venda-externa/$tipoVendaExterna.$mode.$veeIdpk.ts",
      "parent": "/a/nota-fiscal"
    }
  }
}
ROUTE_MANIFEST_END */
