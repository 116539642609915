import { LoadingOutlined, UserOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { Avatar, Dropdown, Modal, Space, Spin } from 'antd';
import { apiLoginLogof } from 'features/login/api/apiLoginLogof';
import { useLoginStore } from 'features/login/store';
import { memo, useCallback } from 'react';
import styles from './style.module.scss';
import { useUserMenuItems } from './useUserMenuItems';

function BaseUserMenu() {
    const navigate = useNavigate();
    const usu_imagem_perfil = useLoginStore((s) => s.login?.usu_imagem_perfil);

    const mutationLogout = useMutation({
        mutationFn: apiLoginLogof,
    });

    const logout = useCallback(async (): Promise<void> => {
        await mutationLogout.mutateAsync();
        navigate({ to: '/' });
    }, [mutationLogout.mutateAsync, navigate]);

    const items = useUserMenuItems({ logout });

    return (
        <>
            <Dropdown menu={items} trigger={['click']}>
                <div className={styles.dropdown}>
                    <Avatar
                        icon={<UserOutlined />}
                        className={styles.avatar}
                        shape='square'
                        size='large'
                        src={
                            typeof usu_imagem_perfil === 'string' && usu_imagem_perfil.length > 0
                                ? usu_imagem_perfil
                                : undefined
                        }
                    />
                </div>
            </Dropdown>

            <Modal
                open={mutationLogout.isPending}
                footer={null}
                width={200}
                closable={false}
                styles={{ body: { display: 'flex', justifyContent: 'center' } }}
            >
                <Space direction='vertical' align='center'>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin={true} />} />
                    <span style={{ fontSize: 24, fontWeight: 'bold' }}>Saindo...</span>
                </Space>
            </Modal>
        </>
    );
}

export const UserMenu = memo(BaseUserMenu);
