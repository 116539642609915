import { createFileRoute } from '@tanstack/react-router';
import { type } from 'arktype';
import { Settings } from 'features/painel-administrativo/settings/Settings';

export const Route = createFileRoute('/admin/_a/configuracao/representante')({
    validateSearch: type({
        'tab?': 'string',
        'menu?': 'string',
    }),
    component: Settings,
});
