import type { VendaFilterTipo } from 'features/venda/page/vendaStorePagination';
import { fetchApi } from 'std/api/fetchApi';
import type { ApiPagination } from 'std/pagination/types';
import { createRegistrosType } from 'std/schema/schema';
import type { TipoConsulta } from 'types/enum';
import { type VendaOrigem, VendaSchema } from '../types/Venda';

export enum VendaGetParamsDataTipo {
    DataGeracao = 1,
    DataOrcamento = 2,
    DataPedido = 3,
    DataVenda = 4,
    DataBonificacao = 5,
}

export type VendaGetParams = Partial<
    ApiPagination & {
        data_tipo: VendaGetParamsDataTipo;
        data_inicio: string;
        data_fim: string;
        pesquisar: string;
        tipo: VendaFilterTipo;
        numero: number;
        origem: VendaOrigem;
        acao: string;
        venda_externa_idpk: number;
        contrato_idpk: number;
        transportador_idpk: number;
        vendedor_idpk: number;
        cliente_idpk: number;
        cliente_cidade: string;
        marcadores: string;
        sistema_integrado_idpk: number;
        dispositivo_idpk: number;
        tipo_consulta: TipoConsulta;
    }
>;

const schema = createRegistrosType(VendaSchema);

export function apiVendaGet({
    idpk,
    params,
}: {
    idpk?: number;
    params?: VendaGetParams;
}) {
    return fetchApi({
        endpoint: 'Venda/Venda',
        method: 'get',
        idpk,
        params,
        schema,
    });
}
