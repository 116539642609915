import { useLoginStore } from 'features/login/store';
import { fetchApi } from 'std/api/fetchApi';
import { ErroOrigem } from '../../painel-administrativo/erro/types';

type InternoErroBody =
    | {
          type: 'EXCEPTION';
          message: string;
          source: string | undefined;
          lineno: number | undefined;
          colno: number | undefined;
          stack: string[] | undefined;
      }
    | {
          type: 'ARKTYPE';
          message: string;
      };

export function apiInternoErro(body: InternoErroBody) {
    return fetchApi({
        token: false,
        endpoint: 'Interno/Erro',
        method: 'post',
        params: {
            origem: ErroOrigem.Frontend,
        },
        body: {
            APP_VERSION,
            url: window.location.href,
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            ...body,
        },
        schema: null,
    });
}
