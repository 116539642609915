import { type } from 'arktype';

export enum ErroOrigem {
    Todos = 0,
    Backend = 1,
    Frontend = 2,
    WebLocal = 3,
    Lacto = 4,
    Outros = 99,
}

export const ErroSchema = type({
    err_idpk: 'number',
    err_origem: type.valueOf(ErroOrigem),
    err_conteudo: 'string',
    err_ultima_alteracao: 'string|null',
});

export type Erro = typeof ErroSchema.infer;
