import { RouterProvider, createRouter } from '@tanstack/react-router';
import { Suspense } from 'react';
import { DefaultErrorComponent } from './DefaultErrorComponent';
import { NotFoundPage } from './NotFoundPage';
import { Splash } from './Splash';
import { routeTree } from './routeTree.gen';

export const router = createRouter({
    routeTree,
    defaultPreload: 'render',
    defaultPreloadStaleTime: 0,
});

declare module '@tanstack/react-router' {
    interface Register {
        router: typeof router;
    }
}

export function Router() {
    return (
        <Suspense fallback={<Splash />}>
            <RouterProvider
                router={router}
                defaultErrorComponent={(e) => <DefaultErrorComponent error={e} />}
                defaultNotFoundComponent={NotFoundPage}
            />
        </Suspense>
    );
}
