import { type } from 'arktype';
import {
    TypeNumberOrNullToNumber,
    TypeSimNao,
    TypeSimNaoToNao,
    TypeSimNaoToSim,
    TypeStringOrNullToString,
} from 'std/schema/defaultTypes';

export enum EmpresaSlugCatalogoTipoUso {
    /** 1 = Somente visualização dos produtos */
    SomenteVisualizacao = 1,
    /** 2 = Visualização dos produtos, realização de pedidos e compartilhamento no whatsapp */
    VisualizacaoPedidoCompartilhamento = 2,
    /** 3 = Visualização dos produtos, realização de pedidos e checkout de vendas */
    VisualizacaoPedidoCheckout = 3,
}

enum EmpresaSlugCatalogoExibirPreco {
    /** 1 - Não exibir preço */
    NaoExibir = 1,
    /** 2 - Exibir preço do cadastro do produto */
    ExibirPrecoProduto = 2,
    /** 3 - Exibir preço a partir de uma tabela de preço */
    ExibirPrecoTabela = 3,
}

export const EmpresaSlugSchema = type({
    ems_empresa_idpk: 'number',
    ems_sistema_idpk: 'number',
    ems_catalogo_tipo_uso: type.valueOf(EmpresaSlugCatalogoTipoUso),
    ems_cliente_cobranca_ativa: TypeSimNao,
    ems_cor_padrao: TypeStringOrNullToString,
    ems_slug: TypeStringOrNullToString,
    ems_descricao_titulo: TypeStringOrNullToString,
    ems_descricao_texto: TypeStringOrNullToString,
    ems_catalogo_ativo: TypeSimNaoToNao,
    ems_catalogo_exibir_preco: type
        .valueOf(EmpresaSlugCatalogoExibirPreco)
        .pipe((value) => value ?? EmpresaSlugCatalogoExibirPreco.ExibirPrecoProduto),
    ems_tabela_preco_idpk: TypeNumberOrNullToNumber,
    ems_consulta_cliente_ativa: TypeSimNaoToSim,
    ems_whatsapp: TypeStringOrNullToString,
    ems_instagram: TypeStringOrNullToString,
    ems_facebook: TypeStringOrNullToString,
    ems_youtube: TypeStringOrNullToString,
    ems_twitter: TypeStringOrNullToString,
    ems_linkedin: TypeStringOrNullToString,
});

export type EmpresaSlug = typeof EmpresaSlugSchema.infer;
