import { type } from 'arktype';
import { devtools, persist } from 'zustand/middleware';
import { createWithEqualityFn as create } from 'zustand/traditional';

const AutoLoginRedirectOptionsSchema = type({
    'tipo_pix?': type.enumerated('instantaneo', 'cobranca'),
    'data_inicio?': 'string|null',
    'data_fim?': 'string|null',
});

export const AutoLoginRedirectSchema = type({
    email: 'string',
    senha: 'string',
    'empresa_idpk?': 'number|null',
    redirect: 'string',
    'redirect_idpk?': 'number|null',
    'redirect_options?': AutoLoginRedirectOptionsSchema,
});

export type AutoLoginRedirect = typeof AutoLoginRedirectSchema.infer;

type AutoLoginRedirectStore = Partial<AutoLoginRedirect> & {
    clear: () => void;
};

export const useAutoLoginRedirectStore = create(
    persist(
        devtools<AutoLoginRedirectStore>(
            (set) => ({
                clear: () => {
                    set({});
                },
            }),
            { name: 'auto-login-redirect' },
        ),
        {
            name: 'auto-login-redirect',
        },
    ),
);
