import { LoadingOutlined } from '@ant-design/icons';
import { Modal, Spin } from 'antd';
import { useLoginStore } from 'features/login/store';
import { memo } from 'react';

function BaseModalRenovandoToken() {
    const renovandoToken = useLoginStore((s) => s.renovandoToken);

    if (!renovandoToken) {
        return null;
    }

    return (
        <Modal open={renovandoToken} closable={false} footer={null}>
            <Spin
                spinning={renovandoToken}
                indicator={<LoadingOutlined style={{ fontSize: 48 }} spin={true} />}
            />
            <div
                style={{
                    fontSize: 24,
                    fontWeight: 'bold',
                    display: 'inline',
                    marginLeft: 20,
                }}
            >
                Renovando sessão do usuário...
            </div>
        </Modal>
    );
}

export const ModalRenovandoToken = memo(BaseModalRenovandoToken);
