import { Outlet, createFileRoute } from '@tanstack/react-router';
import { Grid, Layout } from 'antd';
import { BannerInstrucoesDeUso } from 'components/revisar/banners/BannerInstrucoesDeUso';
import { useConfiguracaoStore } from 'features/configuracao/store';
import { HeaderPendente } from 'features/empresa/components/HeaderPendente';
import { ModalEmpresaSituacao } from 'features/empresa/components/ModalEmpresaSituacao';
import { Breadcrumbs } from 'features/layout/components/Breadcrumbs';
import { Footer } from 'features/layout/components/Footer';
import { HeaderVersao } from 'features/layout/components/HeaderVersao';
import { ModalRenovandoToken } from 'features/layout/components/ModalRenovandoToken';
import { LayoutHeader } from 'features/layout/components/header/LayoutHeader';
import { LayoutMenu } from 'features/layout/components/menu/LayoutMenu';
import { useLayoutStore } from 'features/layout/store';
import { memo } from 'react';

export const Route = createFileRoute('/a')({
    component: () => <LayoutPrivado admin={false} />,
});

const { useBreakpoint } = Grid;

function BaseLayoutPrivado({ admin }: { admin: boolean }) {
    const screens = useBreakpoint();

    const showBanner = useConfiguracaoStore((s) => s.showBannerInstrucoesUso);
    const { theme } = useLayoutStore();

    return (
        <div className={theme === 'light' ? 'bg-gray-100' : 'bg-slate-700'}>
            <ModalRenovandoToken />
            {!admin && <ModalEmpresaSituacao />}
            {!admin && <HeaderPendente />}
            <HeaderVersao />

            <Layout className='h-screen'>
                <LayoutMenu admin={admin} />

                <Layout>
                    <LayoutHeader admin={admin} />

                    <div className='overflow-y-auto h-[calc(100vh-80px)]'>
                        <Breadcrumbs />

                        {showBanner && !admin && (
                            <div
                                className={`w-full  mt-0 mr-auto ${screens.md ? 'pt-2 pl-4 pr-4' : 'pt-2 pl-2 pr-2'}`}
                            >
                                <BannerInstrucoesDeUso />
                            </div>
                        )}

                        <Layout.Content
                            className={`w-full mt-0 mr-auto ${screens.md ? 'pt-2 pl-4 pr-4' : 'pt-2 pl-2 pr-2'}`}
                        >
                            <Outlet />
                        </Layout.Content>

                        <Footer />
                    </div>
                </Layout>
            </Layout>
        </div>
    );
}

export const LayoutPrivado = memo(BaseLayoutPrivado);
