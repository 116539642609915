import { Link } from '@tanstack/react-router';
import { Button, Row } from 'antd';
import { useLayoutStore } from 'features/layout/store';
import { memo } from 'react';
import { isSandbox } from 'std/url';
import sandboxIcon from './sandbox_icon.png';

function BaseButtonSandbox() {
    const { menuCollapsed } = useLayoutStore();

    if (!isSandbox()) {
        return null;
    }

    return (
        <Row justify='center'>
            <Link to='/a/debug'>
                <Button
                    danger={true}
                    icon={<img src={sandboxIcon} alt='Sandbox' className='h-6' />}
                >
                    {!menuCollapsed && 'SANDBOX'}
                </Button>
            </Link>
        </Row>
    );
}

export const ButtonSandbox = memo(BaseButtonSandbox);
