import { createFileRoute } from '@tanstack/react-router';
import { queryClient } from 'App/queryClient';
import { apiFinanceiroContaGet } from 'features/financeiro-conta/api/apiFinanceiroContaGet';
import { TipoPermissao } from 'features/usuario/types/UsuarioTipoPermissao';
import { validateRoute } from 'router/util';

export const Route = createFileRoute('/a/pagfor')({
    beforeLoad: async () => {
        const data = await queryClient.fetchQuery({
            queryKey: ['apiFinanceiroContaGet'],
            queryFn: () => apiFinanceiroContaGet(),
        });

        const isPagFor =
            Array.isArray(data) &&
            data.some((item) => item?.banco?.fcb_financeiro_banco_pagfor === 1);

        validateRoute({
            nivel: TipoPermissao.Completo,
            podeExibirFintech: true,
            condicao: isPagFor,
        });
    },
});
