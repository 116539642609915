import type { Type } from 'arktype';
import { useLoginAdminStore } from 'features/login-admin/store';
import { useLoginStore } from 'features/login/store';
import { router } from 'router/Router';
import { parseSchema } from 'std/schema/schema';
import { isTokenAdminValid, isTokenValid } from 'std/token';
import { type BuildUrlParams, buildUrl } from './buildUrl';
import { API_URL } from './url';
import { isAdmin } from './util';

type Method = 'get' | 'post' | 'put' | 'delete';

type ApiResponseOk = {
    status: 'sucesso';
    registros?: unknown[];
    total_registros?: number;
};

type ApiResponseError = {
    status: 'erro';
    mensagem?: string;
    mensagem_original?: string;
    personalizado?: unknown;
};

type ApiResponse = ApiResponseOk | ApiResponseError;

export async function fetchApi<
    Params extends BuildUrlParams,
    Body extends Record<string, unknown> | Record<string, unknown>[],
    T extends Type,
>({
    token = true,
    empresaIdpk = true,
    endpoint,
    method,
    idpk,
    params,
    body,
    timeout,
    schema,
}: {
    token?: boolean;
    empresaIdpk?: boolean | number;
    endpoint: string;
    method: Method;
    idpk?: number | string;
    params?: Params;
    body?: Body;
    timeout?: number;
    schema: T | null;
}): Promise<T extends null ? ApiResponseOk : T['inferOut']> {
    const renovandoToken = useLoginStore.getState().renovandoToken;

    if (renovandoToken) {
        throw new Error('Renovando token');
    }

    const init: RequestInit = {
        headers: { 'Content-Type': 'application/json' },
        method,
        body: body ? JSON.stringify(body) : undefined,
        signal: timeout ? AbortSignal.timeout(timeout) : undefined,
    };

    // dentro de um teste o pathname vai ser /
    if (token && window.location.pathname !== '/') {
        // Precisa checar se é admin primeiro (baseado na url já que qualquer requisição deve usar o token do admin se estiver logado como admin)
        if (isAdmin()) {
            adminToken(init);
        } else {
            userToken(init);
        }
    }

    const innerParams = { ...params };

    if (typeof empresaIdpk === 'boolean' && empresaIdpk) {
        innerParams.empresa_idpk = useLoginStore.getState().empresaIdpk;
    }

    if (typeof empresaIdpk === 'number') {
        innerParams.empresa_idpk = empresaIdpk;
    }

    const url = `${API_URL}/${buildUrl(endpoint, innerParams, idpk)}`;
    const response = await fetch(url, init);
    const data = await response.json();
    throwIfDataInvalid(data, method);

    if (schema) {
        // @ts-expect-error
        return parseSchema(schema, data);
    }

    return data;
}

function adminToken(init: RequestInit): void {
    if (isTokenAdminValid()) {
        init.headers = {
            ...init.headers,
            Authorization: `Bearer ${useLoginAdminStore.getState()?.loginAdmin?.token}`,
        };
    } else {
        useLoginAdminStore.getState().clearLogin();
        router.navigate({ to: '/admin/auth/login' });
        throw new Error('Token inválido');
    }
}

function userToken(init: RequestInit): void {
    if (isTokenValid()) {
        init.headers = {
            ...init.headers,
            Authorization: `Bearer ${useLoginStore.getState()?.login?.token}`,
        };
    } else {
        useLoginStore.getState().clearLogin();
        router.navigate({ to: '/auth/login' });
        throw new Error('Token inválido');
    }
}

function throwIfDataInvalid(data: ApiResponse, method: Method): void {
    if (!data) {
        throw new Error('Conexão falhou ou servidor está indisponível.');
    }

    if (data.status === 'erro') {
        const error = data.mensagem || data.mensagem_original || JSON.stringify(data);
        throw new Error(error.replaceAll("'", ''));
    }

    if (method !== 'post') {
        return;
    }

    if (Array.isArray(data.registros)) {
        for (const registro of data.registros) {
            if (
                typeof registro === 'object' &&
                registro &&
                'status' in registro &&
                registro.status === 'erro' &&
                'message' in registro &&
                typeof registro.message === 'string'
            ) {
                throw new Error(registro.message);
            }
        }
    }
}
