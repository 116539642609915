import { type } from 'arktype';

export const UsuarioSchema = type({
    usu_idpk: 'number',
    usu_sistema_idpk: 'number',
    usu_nome: 'string',
    /** 
        Campo utilizado para informar se a senha será renova e então validar o link com a data de expiração;
        0 = NÃO
        1 = SIM 
    */
    usu_renovar_senha: '0|1',
    usu_representante_idpk: 'number|null',
    usu_email: 'string|null',
    usu_fone: 'string|null',
    usu_imagem_perfil: 'string|null',
    usu_observacao: 'string|null',
    usu_renovar_senha_expiracao: 'string|null',
    usu_renovar_senha_token: 'string|null',
});

export type Usuario = typeof UsuarioSchema.infer;
