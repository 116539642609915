import { DollarOutlined, LinkOutlined, ShoppingOutlined, YoutubeOutlined } from '@ant-design/icons';
import { Link } from '@tanstack/react-router';
import { Button, Dropdown } from 'antd';
import { useEmpresaQuery } from 'features/empresa/hooks/useEmpresaQuery';
import { useSistemaStore } from 'features/sistema/store';
import { memo } from 'react';

function BaseLayoutHeaderDropdownLinks({ admin }: { admin: boolean }) {
    const youtube = useSistemaStore((s) => s.sistema?.sis_youtube);
    const exibirBotaoYoutube = typeof youtube === 'string' && youtube.length > 0;

    const { empresa } = useEmpresaQuery();
    const exibirBotaoCatalogo = !admin && empresa?.empresa_slug?.ems_catalogo_ativo === 'S';
    const exibirSegundaVia = !admin && empresa?.empresa_slug?.ems_consulta_cliente_ativa === 'S';
    const slug = empresa?.empresa_slug?.ems_slug || '';

    return (
        <Dropdown
            menu={{
                items: [
                    exibirBotaoYoutube
                        ? {
                              key: 'youtube',
                              icon: <YoutubeOutlined className='text-youtube' />,
                              label: (
                                  <a href={youtube} target='_blank' rel='noreferrer'>
                                      Vídeos
                                  </a>
                              ),
                          }
                        : null,
                    exibirBotaoCatalogo
                        ? {
                              key: 'catalogo',
                              icon: <ShoppingOutlined />,
                              label: (
                                  <Link
                                      to='/p/catalogo/$slug/listagem'
                                      params={{ slug }}
                                      target='_blank'
                                  >
                                      Catálogo
                                  </Link>
                              ),
                          }
                        : null,

                    exibirSegundaVia
                        ? {
                              key: 'segunda-via',
                              icon: <DollarOutlined />,
                              label: (
                                  <Link to='/p/cliente/$slug' params={{ slug }} target='_blank'>
                                      Segunda via
                                  </Link>
                              ),
                          }
                        : null,
                ],
            }}
        >
            <Button icon={<LinkOutlined />} />
        </Dropdown>
    );
}

export const LayoutHeaderDropdownLinks = memo(BaseLayoutHeaderDropdownLinks);
