import { createModel } from '@rematch/core';
import type { RootModel } from 'models';
import { initReduxFn, setStateReducer } from 'std/redux';
import { DataAte, DataDe } from 'types/enum';
import { effects } from './effects';
import type { CobrancaPixInstantaneoState } from './types';

const initialState: CobrancaPixInstantaneoState = {
    get: initReduxFn,
    totalizador: initReduxFn,
    remove: initReduxFn,
    estornar: initReduxFn,
    consultarSituacaoPixInstantaneo: initReduxFn,

    getTable: {
        dateSearchType: {
            de: DataDe.Insercao,
            ate: DataAte.Insercao,
        },

        // TOTAIS REGISTROS (PARA PAGINAÇÃO)
        totalRegistrosTable: 0,
        registroInitial: 0,
        qtdRegistros: 10,

        // ORDENAÇÃO
        sortParams: {
            shouldSort: false,
            fieldName: '',
            orderDirection: 'asc',
        },

        // PAGINAÇÃO
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0,
            showTotal: () => '',
            showSizeChanger: false,
        },

        // LINHA SELECIONADAS
        selectedRows: [],

        // UPDATE TABLE ON CHANGES
        updateTable: false,

        // LOADING NA TABELA PARA MOSTRAR ALGUMA AÇÃO EXECUTANDO
        loadingTable: false,
    },

    // MOSTRAR DRAWERS
    showDrawerGetDetalhes: false,
    showDrawerCompartilhar: false,
    showModalVisualizarPix: false,

    // VALORES PARA DRAWERS
    dadosCompartilhar: {
        link: '',
        assunto: '',
        mensagem: '',
        idpk: 0,
    },
};

export const CobrancaPixInstantaneoModel = createModel<RootModel>()({
    state: initialState,
    reducers: setStateReducer<CobrancaPixInstantaneoState>(),
    effects,
});
