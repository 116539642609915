import { useEffect } from 'react';

let touchStartPrev = 0;
let touchStartLocked = false;

export function useMenuTouch(toggleMobileMenu: () => void) {
    useEffect(() => {
        // mobile menu touch slide opener
        const unify = (e: TouchEvent) => (e.changedTouches ? e.changedTouches[0] : undefined);

        document.addEventListener(
            'touchstart',
            (e) => {
                const event = unify(e) || e;
                const x = 'clientX' in event ? event.clientX : 0;
                touchStartPrev = x;
                touchStartLocked = x > 70;
            },
            { passive: false },
        );

        document.addEventListener(
            'touchmove',
            (e) => {
                const event = unify(e) || e;
                const x = 'clientX' in event ? event.clientX : 0;
                const prev = touchStartPrev;
                if (x - prev > 50 && !touchStartLocked) {
                    toggleMobileMenu();
                    touchStartLocked = true;
                }
            },
            { passive: false },
        );
    }, [toggleMobileMenu]);
}
