import { createFileRoute, redirect } from '@tanstack/react-router';
import { type } from 'arktype';
import { FormContainerVendas } from 'features/venda/page/edicao/FormContainerVendas';
import { VENDA_TIPO } from 'features/venda/types/Venda';
import { formEditMode } from 'types/enum';

const ParamsSchema = type({
    tipo: type.valueOf(VENDA_TIPO),
    mode: type.valueOf(formEditMode),
    idpk: 'string',
});

export const Route = createFileRoute('/a/venda/$tipo/$mode/$idpk')({
    validateSearch: type({
        'venda_externa?': 'boolean',
    }),
    beforeLoad({ params }) {
        const result = ParamsSchema(params);

        if (result instanceof type.errors) {
            throw redirect({ to: '/' });
        }
    },
    component: FormContainerVendas,
});
