import { Image, Typography } from 'antd';
import { useLoginAdminStore } from 'features/login-admin/store';
import { memo } from 'react';
import { formatDocument } from 'std/format';
import { useScreenAliases } from 'std/hooks/useScreenAliases';

const { Title } = Typography;

function BaseHeaderRepresentante() {
    const { isSmallestMobile } = useScreenAliases();

    const loginAdmin = useLoginAdminStore((s) => s.loginAdmin);

    return (
        <div
            className='flex items-center text-left border border-gray-100 border-solid'
            style={{ minHeight: '48px', padding: '0px 12px' }}
        >
            {!isSmallestMobile && (
                <Image
                    src='resources/images/empresa_fallback.png'
                    style={{
                        width: '2.5rem',
                        height: '2.5rem',
                    }}
                    className='mt-1 ml-1 mr-2'
                    rootClassName='flex justify-center'
                    preview={false}
                />
            )}

            <div
                className='flex flex-col'
                style={{
                    maxWidth: 'calc(100% - 2.6rem)',
                }}
            >
                <Title
                    level={3}
                    className='p-0 m-0 mr-1'
                    style={{
                        fontSize: isSmallestMobile ? 12 : 16,
                        maxWidth: '100%',
                        lineHeight: '1.4rem',
                    }}
                    ellipsis={{ tooltip: loginAdmin?.representante?.rep_nome || '' }}
                >
                    {loginAdmin?.representante?.rep_nome || ''}
                </Title>

                <Title
                    level={5}
                    className='p-0 m-0 font-weight-normal font-italic text-slate-500'
                    style={{
                        fontSize: isSmallestMobile ? '11px' : '14px',
                        lineHeight: isSmallestMobile ? '11px' : '14px',
                    }}
                >
                    {formatDocument(loginAdmin?.representante?.rep_documento || '')}
                </Title>
            </div>
        </div>
    );
}

export const HeaderRepresentante = memo(BaseHeaderRepresentante);
