import { BugOutlined, ConsoleSqlOutlined, ShopOutlined, SolutionOutlined } from '@ant-design/icons';
import { Link } from '@tanstack/react-router';
import type { MenuProps } from 'antd';
import { useLoginAdminStore } from 'features/login-admin/store';
import { useMemo } from 'react';

export function useMenuItemsAdmin(): MenuProps['items'] {
    const isPrincipal = useLoginAdminStore(
        (s) => s.loginAdmin?.representante?.tipo === 'Principal',
    );

    const isAlfa = useLoginAdminStore((s) => s.loginAdmin?.representante?.rep_idpk === 1);

    const items = useMemo(
        () => [
            isPrincipal
                ? {
                      key: '/admin/representante',
                      icon: <SolutionOutlined />,
                      label: <Link to='/admin/representante'>Representantes</Link>,
                  }
                : null,
            {
                key: '/admin/empresa',
                icon: <ShopOutlined />,
                label: <Link to='/admin/empresa'>Empresas</Link>,
            },
            isAlfa
                ? {
                      key: '/admin/erro',
                      icon: <BugOutlined />,
                      label: <Link to='/admin/erro'>Erros</Link>,
                  }
                : null,
            {
                key: '/admin/sql',
                icon: <ConsoleSqlOutlined />,
                label: <Link to='/admin/sql'>Comandos SQL</Link>,
            },
        ],
        [isPrincipal, isAlfa],
    );

    return items;
}
