import { green } from '@ant-design/colors';
import { Button } from 'antd';
import { memo } from 'react';
import { useLayoutStore } from '../store';

function BaseHeaderVersao() {
    const { newVersion } = useLayoutStore();

    const version = `${APP_VERSION}-${CURRENT_COMMIT}`;

    if (!newVersion || newVersion === version) {
        return null;
    }

    return (
        <div
            style={{
                position: 'sticky',
                top: 0,
                background: green[6],
                zIndex: 999,
                padding: 6,
                color: 'white',
            }}
        >
            <div
                style={{
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: 30,
                }}
            >
                <div style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                    <b>Versão nova disponível!</b>

                    <Button
                        style={{ marginLeft: 10 }}
                        onClick={() => {
                            //@ts-ignore - true ignora cache
                            window.location.reload(true);
                        }}
                    >
                        Atualizar agora
                    </Button>
                </div>

                <div style={{ position: 'absolute', right: 0 }}>
                    {version} {'>'} {newVersion}
                </div>
            </div>
        </div>
    );
}

export const HeaderVersao = memo(BaseHeaderVersao);
