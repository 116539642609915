import { Outlet, createRootRoute, redirect } from '@tanstack/react-router';
import { useSistemaStore } from 'features/sistema/store';
import { Splash } from 'router/Splash';
import { oldPublicRoutesBackwardsCompatibility } from 'router/backwards-compatibility/old-public-routes-backwards-compatibility';
import { goToLogin, goToLoginAdmin } from 'router/util';

export const Route = createRootRoute({
    beforeLoad: ({ location }) => {
        // Em caso de rota com # (rota antiga) corrigir para rota correta
        if (location.href.startsWith('/#/')) {
            oldPublicRoutesBackwardsCompatibility(location.hash);
        }

        // Rota pública, não fazer nada
        if (location.pathname.startsWith('/p/')) {
            return;
        }

        // Rota Administrativa
        if (location.pathname.startsWith('/admin')) {
            if (goToLoginAdmin(location.pathname)) {
                throw redirect({ to: '/admin/auth/login' });
            }

            if (location.pathname === '/admin') {
                throw redirect({ to: '/admin/empresa' });
            }

            return;
        }

        if (goToLogin(location.pathname)) {
            throw redirect({ to: '/auth/login' });
        }

        if (location.pathname === '/') {
            throw redirect({ to: '/a/painel-gerencial' });
        }
    },
    component: () => {
        const sistema = useSistemaStore((state) => state.sistema);

        if (!sistema) {
            return <Splash />;
        }

        return <Outlet />;
    },
});
