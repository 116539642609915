import type { RoutePath } from 'router/util';
import { proxyPersist } from 'std/util';
import { useSnapshot } from 'valtio';

type LayoutStore = {
    theme: 'dark' | 'light';
    switchTheme: () => void;

    breadcrumb: {
        path: string[];
        voltarTo?: RoutePath;
    };

    menuCollapsed: boolean;
    toggleMenuCollapsed: () => void;
    menuMobile: boolean;
    toggleMenuMobile: () => void;

    isFullscreen: boolean;
    toggleFullscreen: () => void;

    newVersion?: string;
};

export const $layoutStore = proxyPersist<LayoutStore>(
    {
        theme: 'light',
        switchTheme() {
            $layoutStore.theme = $layoutStore.theme === 'light' ? 'dark' : 'light';
        },

        breadcrumb: {
            path: [],
        },

        menuCollapsed: false,
        toggleMenuCollapsed() {
            $layoutStore.menuCollapsed = !$layoutStore.menuCollapsed;
        },

        menuMobile: false,
        toggleMenuMobile() {
            $layoutStore.menuMobile = !$layoutStore.menuMobile;
        },

        isFullscreen: false,
        async toggleFullscreen() {
            // Safari doesn't support requestFullscreen
            // https://developer.mozilla.org/en-US/docs/Web/API/Element/requestFullscreen#browser_compatibility
            if (!document.documentElement.requestFullscreen) {
                return;
            }

            if ($layoutStore.isFullscreen) {
                await document.exitFullscreen();
            } else {
                await document.documentElement.requestFullscreen();
            }
        },

        newVersion: undefined,
    },
    {
        key: 'layout',
        partialize: (state) => ({ theme: state.theme }),
    },
);

export const useLayoutStore = () => useSnapshot($layoutStore);
