import { notification } from 'antd';
import { useLoginStore } from 'features/login/store';
import type { FormNotaFiscalValues } from 'pages/nota-fiscal/forms/type/types';
import type { RootDispatch, RootState } from 'state/store';
import { type BuildUrlParams, buildUrl } from 'std/api/buildUrl';
import { comTokenGet, comTokenPost, comTokenPut, comTokenRemove } from 'std/api/comToken';
import { throwIfResponseIsErr } from 'std/api/util';
import { endReduxFnError, endReduxFnOk, startReduxFn } from 'std/redux';
import { Endpoint } from 'types/enum/endpoint';
import type { SortParams } from 'types/interfaces';

export const effects = (dispatch: RootDispatch) => ({
    async get(
        payload: {
            descricao?: string;
            total_registros?: string;
            registro_inicial?: number;
            qtde_registros?: number;
            sort?: SortParams;
        },
        state: RootState,
    ): Promise<void> {
        const { notaFiscalPreenchimento } = state;
        const { getTable, get } = notaFiscalPreenchimento;

        dispatch.notaFiscalPreenchimento.setState({
            get: startReduxFn(get.data),
            getTable: {
                ...getTable,
                updateTable: false,
            },
        });

        const { registroInitial, qtdRegistros, sortParams, pagination } = getTable;

        const {
            descricao,
            total_registros,
            registro_inicial,
            qtde_registros,
            sort,
            ...restPayload
        } = payload;

        // MONTANDO OS PARAMETROS OBRIGATÓRIOS
        const params: BuildUrlParams = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            registro_inicial:
                registro_inicial !== null && registro_inicial !== undefined
                    ? registro_inicial
                    : registroInitial,
            qtde_registros: qtde_registros || qtdRegistros,
            orderby: 'nfr_idpk desc',
            ...restPayload,
        };

        // CASO TIVER O FILTRO DE NOME/TEXTO (*OPCIONAL)
        if (descricao) {
            params.descricao = descricao;
        }
        // CASO TER QUE FILTRAR (SORTEAR) OS DADOS (*OPCIONAL)
        if (sort?.shouldSort || sortParams?.shouldSort) {
            params.orderby = `${sort?.fieldName || sortParams?.fieldName}${
                sort?.orderDirection || sortParams?.orderDirection
            }`;
        }

        // CASO TIVER ENVIADO PARA MOSTRAR TODOS REGISTROS
        if (total_registros) {
            params.total_registros = total_registros;
        }

        const url = buildUrl(Endpoint.NotaFiscalRegraPreenchimentoConsultar, params);

        try {
            const response = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const {
                data: { registros = [], total_registros: totalRegistroResponse = null } = {},
            } = response;

            const { notaFiscalPreenchimento: newNotaFiscalPreenchimento } = state;
            const { getTable: newGetTable } = newNotaFiscalPreenchimento;

            dispatch.notaFiscalPreenchimento.setState({
                get: endReduxFnOk(registros),
                getTable: {
                    ...newGetTable,
                    updateTable: false,
                    registroInitial:
                        registro_inicial !== null && registro_inicial !== undefined
                            ? registro_inicial
                            : registroInitial,
                    qtdRegistros: qtde_registros || qtdRegistros,
                    pagination: {
                        ...pagination,
                        // SE PRECISAR ATUALIZAR A PÁGINA É FEITO AQUI
                        ...(registro_inicial !== null &&
                            registro_inicial !== undefined && {
                                current: registro_inicial / (qtde_registros || qtdRegistros) + 1,
                            }),
                        // SE PRECISAR ATUALIZAR OS TOTAIS É FEITO AQUI
                        ...((totalRegistroResponse || totalRegistroResponse === 0) && {
                            total: totalRegistroResponse,
                            showTotal: () => `Total de Registros: ${totalRegistroResponse}`,
                            showSizeChanger: totalRegistroResponse > 10,
                        }),
                    },
                },
            });
        } catch (error) {
            dispatch.notaFiscalPreenchimento.setState({
                get: endReduxFnError(error),
            });
        }
    },

    async getOne(payload: { nfr_idpk: number }, state: RootState): Promise<void> {
        dispatch.notaFiscalPreenchimento.setState({
            getOne: startReduxFn(state.notaFiscalPreenchimento.getOne.data),
        });

        const { nfr_idpk } = payload;
        const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };

        const url = buildUrl(Endpoint.NotaFiscalRegraPreenchimentoConsultar, params, nfr_idpk);

        try {
            const response = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const {
                data: { registros = [] } = {},
            } = response;

            dispatch.notaFiscalPreenchimento.setState({
                getOne: endReduxFnOk(registros && registros.length > 0 ? registros[0] : null),
            });
        } catch (error) {
            dispatch.notaFiscalPreenchimento.setState({
                getOne: endReduxFnError(error),
            });
        }
    },

    async post(
        payload: { body: Partial<FormNotaFiscalValues>; updateTable: boolean },
        state: RootState,
    ): Promise<void> {
        dispatch.notaFiscalPreenchimento.setState({
            post: startReduxFn(state.notaFiscalPreenchimento.post.data),
        });

        const { body, updateTable } = payload;

        try {
            const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };
            const url = buildUrl(Endpoint.NotaFiscalRegraPreenchimentoInserir, params);
            const response = await comTokenPost(url, body);
            throwIfResponseIsErr(response);

            const nfr_idpk: number = response.data.registros?.[0]
                ? response.data.registros[0].nfr_idpk
                : 0;

            dispatch.notaFiscalPreenchimento.setState({
                post: endReduxFnOk({ nfr_idpk }),
                showModalNfRegraPreenchimento: false,
            });

            if (updateTable) {
                dispatch.notaFiscalPreenchimento.get({});
            }

            notification.success({
                message: 'Feito!',
                description: 'Informação Adicional da Nota Fiscal cadastrada',
            });
        } catch (error) {
            dispatch.notaFiscalPreenchimento.setState({
                post: endReduxFnError(error),
            });

            notification.error({
                message: 'Não foi possível cadastrar a informação adicional da nota fiscal!',
                description: error.message,
            });
        }
    },

    async put(
        payload: {
            nfr_idpk: number;
            body: Partial<FormNotaFiscalValues>;
            updateTable: boolean;
        },
        state: RootState,
    ): Promise<void> {
        dispatch.notaFiscalPreenchimento.setState({
            put: startReduxFn(state.notaFiscalPreenchimento.put.data),
        });

        const { nfr_idpk = 0, body, updateTable } = payload;

        try {
            const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };
            const url = buildUrl(Endpoint.NotaFiscalRegraPreenchimentoAlterar, params, nfr_idpk);
            const response = await comTokenPut(url, body);
            throwIfResponseIsErr(response);

            dispatch.notaFiscalPreenchimento.setState({
                put: endReduxFnOk('Success'),
                showModalNfRegraPreenchimento: false,
            });

            if (updateTable) {
                dispatch.notaFiscalPreenchimento.get({});
            }

            notification.success({
                message: 'Feito!',
                description: 'Informação Adicional da Nota Fiscal atualizada',
            });
        } catch (error) {
            dispatch.notaFiscalPreenchimento.setState({
                put: endReduxFnError(error),
            });

            notification.error({
                message: 'Não foi possível atualizar a informação adicional da nota fiscal!',
                description: error.message,
            });
        }
    },

    async remove(
        payload: { nfr_idpk: number; updateTable?: boolean },
        state: RootState,
    ): Promise<void> {
        dispatch.notaFiscalPreenchimento.setState({
            remove: startReduxFn(state.notaFiscalPreenchimento.remove.data),
        });

        try {
            const { nfr_idpk = 0, updateTable } = payload;
            const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };
            const url = buildUrl(Endpoint.NotaFiscalRegraPreenchimentoRemover, params, nfr_idpk);
            const response = await comTokenRemove(url);
            throwIfResponseIsErr(response);

            dispatch.notaFiscalPreenchimento.setState({
                remove: endReduxFnOk('Success'),
            });

            if (updateTable) {
                const { notaFiscalPreenchimento } = state;
                const { getTable } = notaFiscalPreenchimento;
                const {
                    pagination,
                    pagination: { total = 0 },
                    registroInitial = 0,
                } = getTable;

                let isLastPageOnlyOneRegister = false;

                // VERIFICA SE É A ÚLTIMA PÁGINA E TEM APENAS UM ITEM PARA PODER MUDAR DE PÁGINA APÓS DELETAR
                if (total && registroInitial && total - 1 === registroInitial) {
                    isLastPageOnlyOneRegister = true;
                    dispatch.notaFiscalPreenchimento.get({
                        total_registros: 'S',
                        registro_inicial: registroInitial - (pagination?.pageSize || 0),
                    });
                }

                if (!isLastPageOnlyOneRegister) {
                    dispatch.notaFiscalPreenchimento.get({ total_registros: 'S' });
                }
            }

            notification.success({
                message: 'Feito!',
                description: 'Informação Adicional da Nota Fiscal excluída.',
            });
        } catch (error) {
            dispatch.notaFiscalPreenchimento.setState({
                remove: endReduxFnError(error),
            });

            notification.error({
                message: 'Falhou!',
                description: String(error),
            });
        }
    },
});
