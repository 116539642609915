import { PrinterOutlined } from '@ant-design/icons';
import type { SubMenuType } from 'antd/es/menu/interface';
import { useUserPermission } from 'features/login/hooks/useUserPermission';
import { useLoginStore } from 'features/login/store';
import { TipoPermissao } from 'features/usuario/types/UsuarioTipoPermissao';
import { useMemo } from 'react';
import { CustomLink } from './CustomLink';

export function useMenuItemsRelatorios() {
    const isFintech = useLoginStore((s) => s.isFintech);

    const permissions = useUserPermission({
        upp_relatorio_venda: TipoPermissao.Parcial,
        upp_relatorio_fiscal: TipoPermissao.Parcial,
        upp_relatorio_financeiro: TipoPermissao.Completo,
        upp_relatorio_estoque: TipoPermissao.Completo,
        upp_relatorio_cadastro: TipoPermissao.Parcial,
        upp_relatorio_compra: TipoPermissao.Completo,
    });

    const group = useMemo(() => {
        const g: SubMenuType = {
            key: 'categoria-relatorio',
            icon: <PrinterOutlined />,
            label: 'Relatórios',
            children: [],
        };

        if (permissions.upp_relatorio_venda) {
            g.children.push({
                key: '/a/relatorio/venda',
                label: (
                    <CustomLink to='/a/relatorio/$tipo' params={{ tipo: 'venda' }}>
                        Relatórios de vendas
                    </CustomLink>
                ),
            });
        }

        if (!isFintech && permissions.upp_relatorio_fiscal) {
            g.children.push({
                key: '/a/relatorio/fiscal',
                label: (
                    <CustomLink to='/a/relatorio/$tipo' params={{ tipo: 'fiscal' }}>
                        Relatórios fiscais
                    </CustomLink>
                ),
            });
        }

        if (permissions.upp_relatorio_financeiro) {
            g.children.push({
                key: '/a/relatorio/financeiro',
                label: (
                    <CustomLink to='/a/relatorio/$tipo' params={{ tipo: 'financeiro' }}>
                        Relatórios financeiros
                    </CustomLink>
                ),
            });
        }

        if (!isFintech && permissions.upp_relatorio_estoque) {
            g.children.push({
                key: '/a/relatorio/estoque',
                label: (
                    <CustomLink to='/a/relatorio/$tipo' params={{ tipo: 'estoque' }}>
                        Relatórios de estoque
                    </CustomLink>
                ),
            });
        }

        if (permissions.upp_relatorio_cadastro) {
            g.children.push({
                key: '/a/relatorio/cadastro',
                label: (
                    <CustomLink to='/a/relatorio/$tipo' params={{ tipo: 'cadastro' }}>
                        Relatórios dos cadastros
                    </CustomLink>
                ),
            });
        }

        if (!isFintech && permissions.upp_relatorio_compra) {
            g.children.push({
                key: '/a/relatorio/compra',
                label: (
                    <CustomLink to='/a/relatorio/$tipo' params={{ tipo: 'compra' }}>
                        Relatórios de compras
                    </CustomLink>
                ),
            });
        }

        return g;
    }, [isFintech, permissions]);

    return group.children.length === 0 ? null : group;
}
