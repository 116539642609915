import { notification } from 'antd';
import { useLoginStore } from 'features/login/store';
import type { RootDispatch, RootState } from 'state/store';
import { type BuildUrlParams, buildUrl } from 'std/api/buildUrl';
import { comTokenGet, comTokenPost, comTokenPut, comTokenRemove } from 'std/api/comToken';
import type { ApiResponse } from 'std/api/types';
import { throwIfResponseIsErr } from 'std/api/util';
import { endReduxFnError, endReduxFnOk, startReduxFn } from 'std/redux';
import { Endpoint } from 'types/enum/endpoint';
import type { Fornecedor } from 'types/interfaces';
import { GetTypes, type GetTypesProps } from 'types/interfaces/GetTypes';

export const effects = (dispatch: RootDispatch) => ({
    async get(
        payload: {
            type: GetTypesProps;
            customUrl?: string;
            for_idpk?: number;
        },
        state: RootState,
    ): Promise<void> {
        dispatch.fornecedores.setState({
            get: startReduxFn(state.fornecedores.get.data),
        });

        try {
            const { type, customUrl, for_idpk } = payload;
            const { fornecedores } = state;
            const { pagination, sortParams, shouldSearch, filterNome, filterMarcadores } =
                fornecedores;

            if (
                type === GetTypes.recordFiltering ||
                type === GetTypes.addRecord ||
                type === GetTypes.singleRecord
            ) {
                dispatch.fornecedores.setState({
                    pagination: {
                        current: 1,
                        pageSize: 10,
                        total: 0,
                    },
                });
            }

            const params: BuildUrlParams = {
                empresa_idpk: useLoginStore.getState().empresaIdpk,
                total_registros: type === GetTypes.paginationChange ? 'N' : 'S',
                registro_inicial: ((pagination.current ?? 0) - 1) * (pagination.pageSize || 0),
                qtde_registros: pagination?.pageSize || 10,
                marcadores: filterMarcadores,
                ...(sortParams.shouldSort
                    ? { orderby: `${sortParams.fieldName} ${sortParams.orderDirection}` }
                    : { orderby: 'pes_nome_razaosocial asc' }),
                ...(shouldSearch && { pesquisar: filterNome }),
            };

            let url = '';
            if (type === GetTypes.customUrl && customUrl) {
                url = customUrl;
            } else if (type === GetTypes.singleRecord && for_idpk) {
                url = buildUrl(Endpoint.Fornecedor, params, for_idpk);
            } else {
                url = buildUrl(Endpoint.Fornecedor, params);
            }

            const response: ApiResponse = await comTokenGet(url);
            throwIfResponseIsErr(response);

            // paginação e filtros devem ser zerados após um get que alteraria a qtde de paginas
            if (type === GetTypes.firstGet || type === GetTypes.addRecord) {
                dispatch.fornecedores.setState({
                    pagination: {
                        current: 1,
                        pageSize: 10,
                        total: response.data.total_registros
                            ? Number(response.data.total_registros)
                            : 0,
                        showTotal: () => `Total de Registros: ${response.data.total_registros}`,
                        showSizeChanger: Number(response.data.total_registros) > 10,
                    },
                    filterNome: '',
                    shouldSearch: false,
                });
            }

            // a paginação que já existe no state deve ser mantida, mas o total/showTotal deve mudar
            if (type === GetTypes.recordFiltering || type === GetTypes.deleteRecord) {
                dispatch.fornecedores.setState({
                    pagination: {
                        ...pagination,
                        total: response.data.total_registros
                            ? Number(response.data.total_registros)
                            : 0,
                        showTotal: () => `Total de Registros: ${response.data.total_registros}`,
                        showSizeChanger: Number(response.data.total_registros) > 10,
                    },
                });
            }

            dispatch.fornecedores.setState({
                get: endReduxFnOk(response.data.registros || []),
            });
        } catch (error) {
            dispatch.fornecedores.setState({
                get: endReduxFnError(error),
            });
        }
    },

    async getOne(payload: { for_idpk: number }, state: RootState): Promise<Fornecedor | null> {
        dispatch.fornecedores.setState({
            getOne: startReduxFn(state.fornecedores.getOne.data),
        });

        try {
            const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };
            const url = buildUrl(Endpoint.Fornecedor, params, payload.for_idpk);
            const response: ApiResponse = await comTokenGet(url);
            throwIfResponseIsErr(response);

            const fornecedor = response.data.registros?.[0] ? response.data.registros[0] : null;

            dispatch.fornecedores.setState({
                getOne: endReduxFnOk(fornecedor),
            });

            return fornecedor;
        } catch (error) {
            dispatch.fornecedores.setState({
                getOne: endReduxFnError(error),
            });

            return null;
        }
    },

    async post(payload: { body: Record<string, any> }, state: RootState): Promise<number | null> {
        dispatch.fornecedores.setState({
            post: startReduxFn(state.fornecedores.post.data),
        });

        try {
            const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };
            const url = buildUrl(Endpoint.Fornecedor, params);
            const response: ApiResponse = await comTokenPost(url, payload.body);
            throwIfResponseIsErr(response);

            const for_idpk = response.data.registros?.[0]
                ? response.data.registros[0].for_idpk
                : null;

            dispatch.fornecedores.setState({
                showModal: false,
                post: endReduxFnOk(for_idpk),
            });

            dispatch.fornecedores.get({
                type: GetTypes.addRecord,
            });

            notification.success({
                message: 'Feito!',
                description: 'Fornecedor cadastrado',
            });

            return for_idpk;
        } catch (error) {
            dispatch.fornecedores.setState({
                post: endReduxFnError(error),
            });

            notification.error({
                message: 'Não foi possível cadastrar o fornecedor',
                description: error.message,
            });

            return null;
        }
    },

    async put(
        payload: {
            for_idpk: number;
            body: Record<string, any>;
        },
        state: RootState,
    ): Promise<void> {
        dispatch.fornecedores.setState({
            put: startReduxFn(state.fornecedores.put.data),
        });

        try {
            const { for_idpk, body } = payload;
            const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };
            const url = buildUrl(Endpoint.Fornecedor, params, for_idpk);
            const response: ApiResponse = await comTokenPut(url, body);
            throwIfResponseIsErr(response);

            dispatch.fornecedores.setState({
                showModal: false,
                put: endReduxFnOk(null),
            });

            dispatch.fornecedores.get({
                type: GetTypes.editRecord,
            });

            notification.success({
                message: 'Feito!',
                description: 'Fornecedor atualizado',
            });
        } catch (error) {
            dispatch.fornecedores.setState({
                put: endReduxFnError(error),
            });

            notification.error({
                message: 'Não foi possível atualizar o fornecedor',
                description: error.message,
            });
        }
    },

    async remove(payload: { for_idpk: number }, state: RootState): Promise<void> {
        dispatch.fornecedores.setState({
            remove: startReduxFn(state.fornecedores.remove.data),
        });

        try {
            const { for_idpk } = payload;
            const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };
            const url = buildUrl(Endpoint.Fornecedor, params, for_idpk);
            const response: ApiResponse = await comTokenRemove(url);
            throwIfResponseIsErr(response);

            dispatch.fornecedores.setState({
                remove: endReduxFnOk(null),
            });

            dispatch.fornecedores.get({
                type: GetTypes.deleteRecord,
            });

            notification.success({
                message: 'Feito!',
                description: 'Fornecedor excluído',
            });
        } catch (error) {
            dispatch.fornecedores.setState({
                remove: endReduxFnError(error),
            });

            notification.error({
                message: 'Falhou!',
                description: String(error),
            });
        }
    },
});
