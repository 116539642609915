import { Link } from '@tanstack/react-router';
import type { MenuProps } from 'antd';
import { useEmpresaQuery } from 'features/empresa/hooks/useEmpresaQuery';
import { useUserPermission } from 'features/login/hooks/useUserPermission';
import { useLoginStore } from 'features/login/store';
import { TipoPermissao } from 'features/usuario/types/UsuarioTipoPermissao';
import { useMemo } from 'react';
import styles from './style.module.scss';

export function useUserMenuItems({ logout }: { logout: () => void }): MenuProps {
    const usuarioEmpresa = useLoginStore((s) => s.getUsuarioEmpresa());
    const usu_nome = useLoginStore((s) => s.login?.usu_nome);
    const usu_email = useLoginStore((s) => s.login?.usu_email);
    const permission = useUserPermission({ upp_configuracao_configuracao: TipoPermissao.Completo });
    const { empresa } = useEmpresaQuery();

    const hasSupportInfo: boolean = useMemo(() => {
        const representante = empresa?.representante;

        return Boolean(
            representante?.rep_suporte_email ||
                representante?.rep_suporte_telefone ||
                representante?.rep_suporte_link_acesso_remoto ||
                representante?.rep_suporte_whatsapp,
        );
    }, [empresa?.representante]);

    return useMemo(() => {
        const menu: MenuProps = {
            items: [
                {
                    label: (
                        <div className='flex flex-col'>
                            <strong>{usu_nome}</strong>
                            <div>
                                <strong>{'Função: '}</strong>
                                {usuarioEmpresa?.usuario_perfil_tipo || '—'}
                            </div>
                            <div>
                                <strong>{'Email: '}</strong>
                                {usu_email}
                            </div>
                        </div>
                    ),
                    key: '1',
                },
                {
                    type: 'divider',
                },
                {
                    label: (
                        <Link to='/a/configuracao/usuario'>
                            <i className='mr-2 fe fe-user' />
                            Meu Perfil de Usuário
                        </Link>
                    ),
                    key: '2',
                },
            ],
        };
        if (permission.upp_configuracao_configuracao) {
            menu.items?.push({
                label: (
                    <Link to='/a/configuracao/empresa' search={{ tab: 'negocio' }}>
                        <i className={`${styles.icon} fe fe-settings`} />
                        Configurações
                    </Link>
                ),
                key: '4',
            });
        }

        menu.items?.push(
            { type: 'divider' },
            {
                label: (
                    <Link to='/a/suporte/instrucoes-de-uso'>
                        <i className={`${styles.icon} fe fe-book`} />
                        Instruções de uso
                    </Link>
                ),
                key: '5',
            },
        );

        if (hasSupportInfo) {
            menu.items?.push(
                { type: 'divider' },
                {
                    label: (
                        <Link to='/a/suporte'>
                            <i className='mr-2 fe fe-message-circle' />
                            Suporte
                        </Link>
                    ),
                    key: '6',
                },
            );
        }

        menu.items?.push(
            {
                type: 'divider',
            },
            {
                label: (
                    <>
                        <i className='mr-2 fe fe-log-out' />
                        Logout
                    </>
                ),
                key: '7',
                onClick: logout,
            },
        );

        return menu;
    }, [
        usu_nome,
        usu_email,
        permission.upp_configuracao_configuracao,
        hasSupportInfo,
        logout,
        usuarioEmpresa?.usuario_perfil_tipo,
    ]);
}
