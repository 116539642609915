import { redirect } from '@tanstack/router-core';
import { type Type, type } from 'arktype';
import { apiInternoErro } from 'features/erro/api/apiInternoErroPost';

export function parseSchema<T extends Type>(schema: T, data: unknown): T['inferOut'] {
    const result = schema(data);

    if (result instanceof type.errors) {
        // biome-ignore lint/suspicious/noConsole:
        console.error('parseSchema', result.summary);

        apiInternoErro({
            type: 'ARKTYPE',
            message: result.summary,
        });

        throw new Error(result.summary);
    }

    return result;
}

export function createRegistrosType<T extends Type>(
    schema: T,
): Type<{
    registros: T['inferOut'][];
    total_registros?: number;
    status: 'sucesso';
}> {
    return type({
        registros: schema.array(),
        'total_registros?': 'number',
        status: "'sucesso'",
    });
}

const TypeModeIdpk = type({
    mode: "'cadastrar' | 'editar' | 'visualizar' | 'clonar'",
    idpk: 'string',
});

export function validateParamsModeIdpk(params: unknown) {
    const out = TypeModeIdpk(params);

    if (out instanceof type.errors) {
        throw redirect({ to: '/' });
    }
}
