import { theme as AntTheme, type ThemeConfig } from 'antd';
import { useSistemaStore } from 'features/sistema/store';
import { useMemo } from 'react';
import { useLayoutStore } from '../store';

const LIGHT_THEME: ThemeConfig = {
    algorithm: AntTheme.defaultAlgorithm,
    components: {
        Table: {
            headerBg: '#f0f2f4',
        },
        Layout: {
            siderBg: '#fff',
            triggerBg: '#fff',
            triggerColor: '#000',
        },
        Menu: {
            subMenuItemBg: '#e4e9f0',
        },
    },
};

const DARK_THEME: ThemeConfig = {
    algorithm: AntTheme.darkAlgorithm,
    components: {
        Layout: {
            siderBg: '#001628',
            triggerBg: '#001628',
            triggerColor: '#fff',
        },
        Menu: {
            subMenuItemBg: '#001628',
        },
    },
};

export function useTheme(): ThemeConfig {
    const { theme } = useLayoutStore();
    const selectedTheme = theme === 'light' ? LIGHT_THEME : DARK_THEME;

    const sistema = useSistemaStore((s) => s.sistema);

    return useMemo(
        () => ({
            token: {
                colorPrimary: sistema?.layout.sic_cor_primaria,
                fontFamily: 'mukta',
            },
            components: {
                Breadcrumb: {
                    colorText: sistema?.layout.sic_menu_titulo ?? undefined,
                },
                Button: {
                    colorTextLightSolid: sistema?.layout.sic_botao_texto ?? undefined,
                },
                Switch: {
                    colorTextLightSolid: sistema?.layout.sic_botao_texto ?? undefined,
                },
                Splitter: {
                    splitBarSize: 4,
                },
                ...selectedTheme.components,
            },

            ...selectedTheme,
        }),
        [sistema, selectedTheme],
    );
}
