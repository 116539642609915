import type { ApiResponseStatus } from 'std/api/types';
import { DefaultColors } from 'types/enum';

export type UploadedFile = {
    file: File;
    status: 'aguardando' | ApiResponseStatus;
    msg: string;
};

export const StatusColors = {
    aguardando: DefaultColors.DarkOrange,
    sucesso: DefaultColors.Green,
    erro: DefaultColors.Red,
};
