import { Outlet, createFileRoute } from '@tanstack/react-router';
import { Link, useRouterState } from '@tanstack/react-router';
import { Layout } from 'antd';
import { Copyright } from 'features/layout/components/Copyright';
import { useSistemaStore } from 'features/sistema/store';
import style from '../auth/style.module.scss';

export const Route = createFileRoute('/admin/auth')({
    component: AuthAdminLayout,
});

const topBarInfo = {
    '/auth/login': {
        t: 'Ainda não tem conta?',
        d: 'Criar agora',
        l: '/auth/cadastro',
    },
    '/auth/cadastro': {
        t: 'Já possui uma conta?',
        d: 'Entre',
        l: '/auth/login',
    },
};

export function AuthAdminLayout() {
    const route = useRouterState().location.pathname;
    const sistema = useSistemaStore((s) => s.sistema);

    return (
        <Layout>
            <Layout.Content>
                <div
                    className='w-full min-h-[100vh] flex flex-col items-center bg-no-repeat bg-cover bg-center shrink-0 shadow-md'
                    style={{
                        backgroundColor: sistema?.layout.sic_cor_primaria,
                        color: 'white',
                    }}
                >
                    <div className='h-16 border-b-2 border-solid border-gray-100 bg-white mb-[7vh] flex flex-row items-center justify-between p-7 w-full'>
                        <div className={style.logoContainer}>
                            <div className={style.logo}>
                                <Link to='/admin/auth/login'>
                                    <img
                                        src={
                                            sistema?.sis_logotipo
                                                ? sistema.sis_logotipo
                                                : 'resources/images/logo.svg'
                                        }
                                        className='mr-2'
                                        alt={sistema?.sis_nome_sistema}
                                    />
                                </Link>
                                <div
                                    style={{ color: sistema?.layout.sic_cor_sistema_nome }}
                                    className={style.name}
                                >
                                    {sistema?.sis_nome_sistema}
                                </div>
                            </div>
                        </div>

                        <div className='d-none d-sm-block'>
                            <span className='mr-2'>{topBarInfo[route]?.t}</span>
                            <Link to={topBarInfo[route]?.l} className='text-base kit__utils__link'>
                                {topBarInfo[route]?.d}
                            </Link>
                        </div>
                    </div>

                    <div className={style.containerInner}>
                        <Outlet />
                    </div>

                    <Copyright isAdmin={true} />
                </div>
            </Layout.Content>
        </Layout>
    );
}
