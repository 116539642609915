import { type } from 'arktype';

export enum TipoPermissao {
    Bloqueado = 0,
    Completo = 1,
    Parcial = 2,
}

export type UsuarioPermissao = keyof Omit<
    UsuarioTipoPermissao,
    'upp_idpk' | 'upp_empresa_idpk' | 'upp_usuario_tipo_idpk'
>;

/** Indica se o usuário possui permissão de autenticar no backend: */
export enum UsuarioPermissaoSistemaAcesso {
    Todos = 0,
    PainelWeb = 1,
    SistemasIntegrados = 2,
    Gestok = 77477,
    MercurioWebApp = 514871,
    MercurioLocal = 517341123,
    SmartPOS = 7458751,
}

export const UsuarioTipoPermissaoSchema = type({
    upp_idpk: 'number',
    upp_empresa_idpk: 'number',
    upp_usuario_tipo_idpk: 'number',
    upp_cadastro_cliente: type.valueOf(TipoPermissao),
    upp_cliente_forma_pagamento: type.valueOf(TipoPermissao),
    upp_cliente_condicao_pagamento: type.valueOf(TipoPermissao),
    upp_cadastro_produto: type.valueOf(TipoPermissao),
    upp_cadastro_fornecedor: type.valueOf(TipoPermissao),
    upp_cadastro_transportadora: type.valueOf(TipoPermissao),
    upp_estoque_estoque: type.valueOf(TipoPermissao),
    upp_venda_venda: type.valueOf(TipoPermissao),
    upp_venda_romaneio: type.valueOf(TipoPermissao),
    upp_venda_contrato: type.valueOf(TipoPermissao),
    upp_venda_venda_externa: type.valueOf(TipoPermissao),
    upp_venda_atendimento: type.valueOf(TipoPermissao),
    upp_venda_comissao: type.valueOf(TipoPermissao),
    upp_fiscal_nfe: type.valueOf(TipoPermissao),
    upp_fiscal_nfce: type.valueOf(TipoPermissao),
    upp_fiscal_mdfe: type.valueOf(TipoPermissao),
    upp_fiscal_regras: type.valueOf(TipoPermissao),
    upp_fiscal_buscador_notas: type.valueOf(TipoPermissao),
    upp_compra_compra: type.valueOf(TipoPermissao),
    upp_notificacao_notificacao: type.valueOf(TipoPermissao),
    upp_financeiro_movimentacao: type.valueOf(TipoPermissao),
    upp_financeiro_conta_receber: type.valueOf(TipoPermissao),
    upp_financeiro_conta_pagar: type.valueOf(TipoPermissao),
    upp_financeiro_boleto: type.valueOf(TipoPermissao),
    upp_financeiro_pix: type.valueOf(TipoPermissao),
    upp_financeiro_cartao: type.valueOf(TipoPermissao),
    upp_financeiro_estorno: type.valueOf(TipoPermissao),
    upp_fintech_administrativo: type.valueOf(TipoPermissao),
    upp_relatorio_venda: type.valueOf(TipoPermissao),
    upp_relatorio_fiscal: type.valueOf(TipoPermissao),
    upp_relatorio_financeiro: type.valueOf(TipoPermissao),
    upp_relatorio_estoque: type.valueOf(TipoPermissao),
    upp_relatorio_cadastro: type.valueOf(TipoPermissao),
    upp_relatorio_compra: type.valueOf(TipoPermissao),
    upp_configuracao_configuracao: type.valueOf(TipoPermissao),
    upp_sistema_acesso: type.valueOf(UsuarioPermissaoSistemaAcesso),
    upp_sistema_personalizado: type.valueOf(TipoPermissao),
});

export type UsuarioTipoPermissao = typeof UsuarioTipoPermissaoSchema.infer;
