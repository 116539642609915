import { useLocation } from '@tanstack/react-router';
import { Menu } from 'antd';
import { ButtonSandbox } from 'features/debug/components/ButtonSandbox';
import { useLayoutStore } from 'features/layout/store';
import { memo } from 'react';
import { MenuLogo } from './MenuLogo';
import { useMenuItems } from './items/useMenuItems';
import { useMenuItemsAdmin } from './items/useMenuItemsAdmin';

function BaseLayoutMenuInner({ admin }: { admin: boolean }) {
    const location = useLocation();

    const { theme } = useLayoutStore();
    const items = useMenuItems();
    const itemsAdm = useMenuItemsAdmin();

    return (
        <>
            <MenuLogo admin={admin} />
            <ButtonSandbox />

            <div className='h-[calc(100vh-140px)] overflow-y-auto'>
                <Menu
                    mode='inline'
                    theme={theme}
                    items={admin ? itemsAdm : items}
                    selectedKeys={[location.pathname]}
                />
            </div>
        </>
    );
}

export const LayoutMenuInner = memo(BaseLayoutMenuInner);
