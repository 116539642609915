import { createFileRoute } from '@tanstack/react-router';
import { type } from 'arktype';
import { CobrancaPix } from 'pages/cobranca-pix';

export const Route = createFileRoute('/a/cobranca-pix/')({
    validateSearch: type({
        tipo: "'instantaneo'|'cobranca'",
        'idpk?': 'number',
    }),
    component: CobrancaPix,
});
