import { Link } from '@tanstack/react-router';
import { Button, Result } from 'antd';
import { Helmet } from 'components/revisar/Helmet';

export function NotFoundPage() {
    return (
        <div className='flex items-center justify-center bg-gray-100'>
            <Helmet title='Página não encontrada' />

            <Result
                status='404'
                title='404'
                subTitle='Desculpe, a página que você procura não existe.'
                extra={
                    <Link to='/' className='w-full btn btn-outline-primary'>
                        <Button type='primary'>Voltar para a página inicial</Button>
                    </Link>
                }
                className='p-10 bg-white rounded-lg shadow-lg'
            />
        </div>
    );
}
