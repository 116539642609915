import type { AxiosRequestConfig } from 'axios';
import { useLoginStore } from 'features/login/store';
import { axiosWithToken } from 'std/api/axios';

/** @deprecated */
export async function comTokenGet(url: string, config?: AxiosRequestConfig): Promise<any> {
    const renovandoToken = useLoginStore.getState().renovandoToken;

    if (renovandoToken) {
        return null;
    }

    return axiosWithToken
        .get(url, config)
        .then((response) => {
            if (response) {
                return response;
            }
            return false;
        })
        .catch((err) => err.response);
}

/** @deprecated */
export async function comTokenPost(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
): Promise<any> {
    const renovandoToken = useLoginStore.getState().renovandoToken;

    if (renovandoToken) {
        return null;
    }

    return axiosWithToken
        .post(url, data, config)
        .then((response) => {
            if (response) {
                return response;
            }
            return false;
        })
        .catch((err) => err.response);
}

/** @deprecated */
export async function comTokenPut(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
): Promise<any> {
    const renovandoToken = useLoginStore.getState().renovandoToken;

    if (renovandoToken) {
        return null;
    }

    return axiosWithToken
        .put(url, data, config)
        .then((response) => {
            if (response) {
                return response;
            }
            return false;
        })
        .catch((err) => err.response);
}

/** @deprecated */
export async function comTokenRemove(url: string, config?: AxiosRequestConfig): Promise<any> {
    const renovandoToken = useLoginStore.getState().renovandoToken;

    if (renovandoToken) {
        return null;
    }

    return axiosWithToken
        .delete(url, config)
        .then((response) => {
            if (response) {
                return response;
            }
            return false;
        })
        .catch((err) => err.response);
}
