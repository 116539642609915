import { Drawer, Layout } from 'antd';
import { useLayoutStore } from 'features/layout/store';
import { memo } from 'react';
import { LayoutMenuInner } from './LayoutMenuInner';
import { useMenuTouch } from './useMenuTouch';

function BaseLayoutMenu({ admin }: { admin: boolean }) {
    const { menuCollapsed, menuMobile, toggleMenuCollapsed } = useLayoutStore();

    useMenuTouch(toggleMenuCollapsed);

    return (
        <>
            {menuMobile ? (
                <Drawer
                    open={!menuCollapsed}
                    onClose={toggleMenuCollapsed}
                    placement='left'
                    width={256}
                    styles={{
                        header: {
                            display: 'none',
                        },
                        body: {
                            padding: 0,
                        },
                    }}
                >
                    <LayoutMenuInner admin={admin} />
                </Drawer>
            ) : (
                <Layout.Sider
                    className='flex flex-col overflow-hidden shadow-lg h-100vh'
                    width={256}
                    collapsible={true}
                    collapsed={menuCollapsed}
                    onCollapse={toggleMenuCollapsed}
                >
                    <LayoutMenuInner admin={admin} />
                </Layout.Sider>
            )}
        </>
    );
}

export const LayoutMenu = memo(BaseLayoutMenu);
