import { type } from 'arktype';

enum UsuarioEmpresaStatus {
    Pendente = 'P',
    Ativo = 'A',
    Bloqueado = 'B',
}

enum UsuarioPerfilTipoNum {
    Administrador = 1,
    Gerente = 2,
    Comprador = 3,
    Financeiro = 4,
    FinanceiroAvancado = 5,
    Vendedor = 6,
    VendedorAvancado = 7,
    AplicativoIntegrado = 8,
}

export const EmpresaUsuarioSchema = type({
    'key?': 'string|null',
    usu_nome: 'string',
    usu_email: 'string',
    'usu_fone?': 'string|null',
    usu_senha: 'string',
    'usu_observacao?': 'string|null',

    usuario_empresa: type({
        'use_idpk?': 'number|null',
        'use_usuario_idpk?': 'number|null',
        'use_empresa_idpk?': 'number|null',
        'use_status?': type.valueOf(UsuarioEmpresaStatus).or('null'),
        'use_autenticar?': "'S'|'N'|null",
        'use_vendedor?': "'S'|'N'|null",
        'use_entregador?': "'S'|'N'|null",
        use_usuario_tipo_idpk: type.valueOf(UsuarioPerfilTipoNum).or('null'),
        use_tabela_preco_idpk: 'number|null',
        use_tabela_comissao_idpk: 'number|null',
        use_entregador_tabela_comissao_idpk: 'number|null',
        use_ultima_alteracao: 'string|null',
    }),

    'usuario_perfil_tipo?': 'string|null',
    'excluir?': 'string|null',
});

export type EmpresaUsuario = typeof EmpresaUsuarioSchema.infer;
