import { type } from 'arktype';
import { TypeNumberOrNullToNumber } from 'std/schema/defaultTypes';
import type { PartialUndefinedOrNull } from 'types/partial';

export enum FormaPagamentoStatus {
    Ativo = 1,
    Inativo = 2,
}

export enum FormaPagamentoClassificacao {
    Desconhecido = 0,
    Dinheiro = 1,
    Cheque = 2,
    CartaoCredito = 3,
    CartaoDebito = 4,
    ContaReceber = 5,
    ContaPagar = 6,
    PixContaBancaria = 7,
    Vale = 10,
    Boleto = 15,
    Deposito = 16,
    PixContaDigital = 17,
    Transferencia = 18,
    Cashback = 19,
    PixCobranca = 66,
    CartaoCreditoCobrancaOnline = 77,
    SemPagamento = 90,
    Outros = 99,
}

export const FormaPagamentoSchema = type({
    fop_idpk: 'number',
    fop_empresa_idpk: 'number',
    fop_status: type.valueOf(FormaPagamentoStatus),
    fop_descricao: 'string',
    fop_classificacao: type.valueOf(FormaPagamentoClassificacao),
    fop_numero_maximo_parcelas: TypeNumberOrNullToNumber,
    fop_dias_intervalo: 'number|null',
    fop_financeiro_conta_idpk: 'number|null',
    fop_ultima_alteracao: 'string|null',
});

export type FormaPagamento = typeof FormaPagamentoSchema.infer;

export type FormaPagamentoBody = PartialUndefinedOrNull<
    Omit<FormaPagamento, 'fop_idpk' | 'fop_empresa_idpk' | 'fop_ultima_alteracao'>
>;
