import { FileDoneOutlined } from '@ant-design/icons';
import type { ItemType, SubMenuType } from 'antd/es/menu/interface';
import { useUserPermission } from 'features/login/hooks/useUserPermission';
import { useLoginStore } from 'features/login/store';
import { TipoPermissao } from 'features/usuario/types/UsuarioTipoPermissao';
import { useMemo } from 'react';
import { CustomLink } from './CustomLink';

export function useMenuItemsFiscal(): ItemType {
    const isFintech = useLoginStore((s) => s.isFintech);

    const permissions = useUserPermission({
        upp_fiscal_nfe: TipoPermissao.Parcial,
        upp_fiscal_nfce: TipoPermissao.Parcial,
        upp_fiscal_mdfe: TipoPermissao.Completo,
        upp_fiscal_buscador_notas: TipoPermissao.Completo,
        upp_fiscal_regras: TipoPermissao.Completo,
    });

    const group = useMemo(() => {
        const g: SubMenuType = {
            key: 'categoria-fiscal',
            icon: <FileDoneOutlined />,
            label: 'Fiscal',
            children: [],
        };

        if (permissions.upp_fiscal_nfe) {
            g.children.push({
                key: '/a/nota-fiscal',
                label: <CustomLink to='/a/nota-fiscal'>Notas fiscais (NF-e)</CustomLink>,
            });
        }

        if (permissions.upp_fiscal_nfce) {
            g.children.push({
                key: '/a/cupom-fiscal',
                label: <CustomLink to='/a/cupom-fiscal'>Cupons fiscais (NFC-e)</CustomLink>,
            });
        }

        if (permissions.upp_fiscal_mdfe) {
            g.children.push({
                key: '/a/manifesto-carga',
                label: <CustomLink to='/a/manifesto-carga'>Manifesto de carga (MDF-e)</CustomLink>,
            });
        }

        if (permissions.upp_fiscal_buscador_notas) {
            g.children.push({
                key: '/a/compra/buscador-notas',
                label: (
                    <CustomLink to='/a/compra' search={{ tab: 'buscador-notas' }}>
                        Buscador de notas
                    </CustomLink>
                ),
            });
        }

        if (permissions.upp_fiscal_regras) {
            g.children.push({
                key: '/a/regra-fiscal',
                label: <CustomLink to='/a/regra-fiscal'>Grupos de regras fiscais</CustomLink>,
            });
        }

        return g;
    }, [permissions]);

    return isFintech || group.children.length === 0 ? null : group;
}
