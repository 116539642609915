import { TipoPermissao, type UsuarioPermissao } from 'features/usuario/types/UsuarioTipoPermissao';
import { useMemo } from 'react';
import { useLoginStore } from '../store';

export function useUserPermission(
    permissoes: Partial<Record<UsuarioPermissao, TipoPermissao>>,
): Partial<Record<UsuarioPermissao, boolean>> {
    const usuarioPermissoes = useLoginStore((s) => s.getUsuarioEmpresa()?.usuario_tipo_permissao);

    return useMemo(() => {
        if (!usuarioPermissoes) {
            // If no permissions are available, return all provided permissions as `false`
            return Object.keys(permissoes).reduce(
                (acc, permissao) => {
                    acc[permissao as UsuarioPermissao] = false;
                    return acc;
                },
                {} as Partial<Record<UsuarioPermissao, boolean>>,
            );
        }

        return Object.entries(permissoes).reduce(
            (acc, [permissao, nivel]) => {
                const userPermission = usuarioPermissoes[permissao as UsuarioPermissao];

                if (nivel === TipoPermissao.Completo) {
                    acc[permissao as UsuarioPermissao] = userPermission === TipoPermissao.Completo;
                } else {
                    acc[permissao as UsuarioPermissao] =
                        userPermission === TipoPermissao.Completo ||
                        userPermission === TipoPermissao.Parcial;
                }

                return acc;
            },
            {} as Partial<Record<UsuarioPermissao, boolean>>,
        );
    }, [permissoes, usuarioPermissoes]);
}
